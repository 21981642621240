.delay {
  border-right: none;
}

.delayElement {
  max-width: 4.5rem;
}

/* Shrink only the input size down, not the wrapper that holds the label and help text */
.timeOfDaySelect {
  & :global(.react-datepicker-wrapper) {
    width: 100px;
  }
}
