.action {
  display: flex;
  margin-right: var(--spacing-2);
  margin-top: var(--spacing-2);
}

.button {
  display: flex;
  justify-content: flex-end;
}

.container {
  display: flex;
}

.preview {
  flex: 2 1 70%;
  margin-right: var(--spacing-2);
  width: 100%;
}

.previewContainer {
  width: 100%;
}

.previewImage {
  background-position: center;
  background-size: cover;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  height: 600px;
  width: 100%;
}

.previewSkeleton {
  height: 600px;
  width: 100%;
}

.url {
  flex: 1;
  margin-right: var(--spacing-1);
}

.urlActions {
  align-items: flex-start;
  display: flex;
  max-width: var(--content-extra-width);

  & > span {
    line-height: var(--leading-3xl);
  }
}

.urlContainer {
  margin-bottom: var(--spacing-4);
}

.urlWrapper {
  flex: 1 1 50%;
}
