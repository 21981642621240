.tooltip {
  align-items: flex-start;
  background: var(--color-tooltip-background);
  border-radius: var(--rounded-md);
  color: var(--color-tooltip-text);
  display: flex;
  flex-direction: column;
  padding: var(--spacing-2);

  & .title {
    color: var(--color-text-body-inverted);
    font-size: var(--text-sm);
    font-weight: var(--font-normal);
    line-height: var(--leading-sm);
  }
}
