.label {
  color: var(--color-text-body);
  font-weight: var(--font-medium);
}

.value {
  color: var(--color-text-heading);
  font-weight: var(--font-semibold);
}

.statistic {
  display: flex;
  flex-direction: column;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}
