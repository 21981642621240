.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
}

.section {
  --l-section-width: 192px;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  /* Make all elements in container uniform size. */
  width: var(--l-section-width);
}
