.root {
  overflow-x: hidden;
}

.footer {
  --negative-spacing-2: calc(-1 * var(--spacing-2));

  background: var(--color-bg-secondary);
  border: 1px solid var(--color-decorative-secondary);
  border-radius: 0 0 var(--rounded-lg) var(--rounded-lg);
  bottom: var(--negative-spacing-2);
  margin: 0 var(--negative-spacing-2) var(--negative-spacing-2);
  padding: var(--spacing-2);
  position: sticky;
}
