.content {
  display: flex;
  flex-direction: column;
}

.contentOverflow {
  overflow: visible;
}

.actionBar {
  background-color: var(--color-slate-50);
  bottom: 0;
  position: -webkit-sticky;
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  position: sticky;
}

.header {
  align-items: flex-start;
  background: var(--color-bg-primary);
  border-bottom: 1px solid var(--color-decorative-secondary);
  /* Override Mantine min-height that we don't want */
  min-height: unset;
  padding: var(--spacing-4);

  /* Remove action bar's border if there's no modal content */
  & + .actionBar {
    border-top: none;
  }
}

.title {
  color: var(--color-text-heading);
  font-weight: var(--font-semibold);
  line-height: var(--leading-base);
}

.description {
  font-size: var(--text-sm);
  font-weight: var(--font-normal);
  line-height: var(--leading-sm);
}

.body {
  padding: var(--spacing-4) var(--spacing-4) var(--spacing-6) var(--spacing-4);

  /* Never have extra margin on the last <p> tag */
  & p:last-child {
    margin-bottom: 0;
  }
}
