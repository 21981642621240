.root {
  display: inline-block;
  height: var(--l-icon-size, var(--icon-size-5));
  text-align: center;
  width: var(--l-icon-size, var(--icon-size-5));

  & svg,
  /* Needed to override FontAwesomeIcon component styles */
  & .svg-inline--fa {
    font-size: var(--l-icon-font-size, var(--text-base));
    /* Account for difference between font-size and bounding box height in order for vertical-height from font awesome to still apply */
    /* (icon-size - font-size) /  2 */
    margin-top: calc(
      (var(--l-icon-size, var(--icon-size-5)) - var(--l-icon-font-size, var(--text-base))) / 2
    );
  }
}
