.name {
  display: flex;

  & > :first-child {
    line-height: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.readonly {
  margin-left: 1ex;
  vertical-align: middle;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}
