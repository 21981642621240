.page {
  display: flex;
  justify-content: center;
}

/* Prevent chip from flexing the full width */
.chip {
  max-width: fit-content;

  /* TODO: This style should be removed when the chip truncation is fixed */
  /* Extra specificity required to override chip style */
  &.chip > span {
    max-width: none;
  }
}

.alert {
  margin-bottom: 0;
}

.logo {
  align-items: center;
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-full);
  display: flex;
  height: var(--icon-size-lg);
  justify-content: center;
  width: var(--icon-size-lg);

  & img {
    width: var(--icon-size);
  }
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
