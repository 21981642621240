/* stylelint-disable no-descending-specificity */

:root {
  --account-switcher-font-size: var(--text-sm);
  --account-switcher-icon-size: var(--icon-size-5);
  /* icon height + vertical padding + border */
  --account-switcher-button-height: calc(
    var(--account-switcher-icon-size) + (var(--spacing-1-half) * 2) + 2px
  );
}

.root {
  max-width: 100%;
  width: 360px;
}

.button {
  border: none;
  font-size: var(--account-switcher-font-size);
  font-weight: var(--font-medium);
  height: var(--account-switcher-button-height);
  line-height: var(--account-switcher-icon-size);
  padding: var(--spacing-3) var(--spacing-2-half);
  width: 100%;

  & > * {
    flex: 0 0 var(--account-switcher-icon-size);
    height: var(--account-switcher-icon-size);
    line-height: var(--account-switcher-icon-size);
  }

  & > :nth-child(1) {
    /* Apply radius and overflow to span to avoid cutting off spinner */
    & > span {
      align-items: center;
      border-radius: var(--rounded-full);
      display: inline-flex;
      overflow: hidden;

      & > img {
        height: var(--account-switcher-icon-size);
        object-fit: contain;
        width: var(--account-switcher-icon-size);
      }
    }

    /* Icon is not wrapped in extra span */
    & > svg {
      font-size: var(--text-base);
    }
  }

  & > :nth-child(2) {
    display: inline-block;
    flex: 1 1 auto;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
  }

  & > :nth-child(3) {
    text-align: right;

    & > svg {
      font-size: var(--text-2xs);
      /* Adjust icon to line up with others in nav due to font-size change */
      padding: 3px;
    }
  }
}

.buttonGroup {
  margin-left: var(--spacing-2);
}

.content {
  display: flex;
  flex-direction: column;
  /* view height - nav padding - button height - popver margin */
  max-height: calc(
    100vh - (2 * var(--spacing-3)) - var(--account-switcher-button-height) - var(--spacing-1)
  );
  min-height: 400px;
  padding: var(--spacing-6);

  & > * {
    flex: 0 0 auto;
  }

  & ul {
    flex: 1 1 auto;
    overflow-y: scroll;
    padding-left: 0;
  }

  &.single {
    min-height: 194px;

    & .summary {
      border-bottom: unset;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &.compact {
    min-height: 300px;
  }
}

.item {
  --l-logo-size: 34px;

  & > a {
    & > img,
    & > svg {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: var(--rounded);
      display: inline-block;
      height: var(--l-logo-size);
      line-height: var(--l-logo-size);
      margin-right: 5px;
      min-height: var(--l-logo-size);
      min-width: var(--l-logo-size);
      text-align: center;
      vertical-align: middle;
      width: var(--l-logo-size);
    }
  }
}

.results {
  margin: var(--spacing-4) 0;
  padding: 0;
}

.spinner {
  display: inline-block;
  position: relative;
  top: calc(var(--spacing-half) * -1);
  vertical-align: middle;
}

.summary {
  border-bottom: 1px solid var(--color-decorative-secondary);
  font-size: var(--text-lg);
  font-weight: var(--font-semibold);
  margin-bottom: var(--spacing-3);
  padding-bottom: var(--spacing-2);
  text-align: center;

  & img {
    border-radius: var(--rounded-sm);
    margin-bottom: var(--spacing-3);
    max-height: 100px;
    max-width: 100%;
  }
}
