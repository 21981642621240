.root {
  border: 2px solid var(--color-text-body-inverted);
  border-radius: var(--rounded-full);
  display: inline-block;
  height: calc(var(--avatar-size-sm) + 2px);
  overflow: hidden;
  width: calc(var(--avatar-size-sm) + 2px);

  & img {
    height: var(--avatar-size-sm);
    vertical-align: unset;
    width: var(--avatar-size-sm);
  }
}

.medium {
  height: calc(var(--avatar-size-md) + 2px);
  width: calc(var(--avatar-size-md) + 2px);

  & img {
    height: var(--avatar-size-md);
    width: var(--avatar-size-md);
  }
}

.large {
  height: calc(var(--avatar-size-lg) + 2px);
  width: calc(var(--avatar-size-lg) + 2px);

  & img {
    height: var(--avatar-size-lg);
    width: var(--avatar-size-lg);
  }
}
