/* stylelint-disable no-descending-specificity */
.closeButton {
  display: block;
}

.collapseButton {
  /* Override button behavior to make it flow */
  display: flex;
  height: var(--icon-size-5);
  margin-right: auto;
}

.collapseButton,
.closeButton {
  cursor: pointer;
  font-weight: var(--font-medium);

  &:not(.disabled):focus,
  &:not(.disabled):hover {
    color: var(--color-sky-700);
  }
}

.description {
  display: block;
  font-weight: var(--font-normal);
  padding-top: var(--spacing-2);
}

.root {
  background-color: var(--color-bg-primary);
  padding: var(--spacing-4);

  & :global(.ReactCollapse--collapse) {
    /* Icon width + gap */
    padding-left: calc(var(--icon-size-5) + var(--spacing-3));
    transition: height 500ms;
  }

  /* Icon */
  /* Narrow the gap between icon on title */
  & span:has(> :global(.notification-icon)) {
    margin-right: calc(var(--spacing-1) * -1);
  }

  & :global(.notification-icon) {
    font-size: var(--text-xl);
  }

  & header {
    align-items: center;
    display: flex;
    gap: var(--spacing-4);
    justify-content: space-between;
    width: 100%;

    & > * {
      flex: 0 0 auto;
      /* Set content to height of icon to ensure it aligns */
      line-height: var(--leading-sm);
      min-height: var(--icon-size-5);
    }

    /* Title */
    & h3 {
      display: inline-block;
      font-size: var(--text-sm);
      font-weight: var(--font-medium);
      line-height: var(--leading-sm);
      margin: 0;
      word-wrap: break-word;
    }

    /* Time ago */
    & > .time {
      flex-grow: 1;
      text-align: right;
    }
  }

  /* TODO: Reuse actions code from AlertV2 */
  & .actions {
    margin-top: var(--spacing-4);

    & a,
    button {
      border-bottom: 1px solid transparent;
      border-radius: unset;
      cursor: pointer;
      font-weight: var(--font-medium);
      margin-right: var(--spacing-6);
      padding: 0;
      text-decoration: none;

      &::after {
        content: '➔';
        margin-left: var(--spacing-1);
      }
    }

    & button {
      background-color: transparent;
      border: unset;
    }
  }

  /* Recommended */
  &.level3 {
    background-color: var(--color-violet-50);
    border-bottom: 1px solid var(--color-violet-600);
    color: var(--color-violet-700);

    & .actions a,
    & .actions button {
      color: var(--color-violet-800);

      &:hover,
      &:focus {
        border-bottom-color: var(--color-violet-800);
      }
    }

    & .collapseButton,
    & .closeButton {
      color: var(--color-violet-600);
    }

    & .collapseButton:not(.disabled):focus,
    & .collapseButton:not(.disabled):hover,
    & .closeButton:not(.disabled):focus,
    & .closeButton:not(.disabled):hover {
      color: var(--color-violet-800);
    }

    & header h3 {
      color: var(--color-violet-800);
    }
  }

  /* Critical */
  &.level2 {
    background-color: var(--color-red-50);
    border-bottom: 1px solid var(--color-red-600);
    color: var(--color-red-700);

    & .actions a,
    & .actions button {
      color: var(--color-red-800);

      &:hover,
      &:focus {
        border-bottom-color: var(--color-red-800);
      }
    }

    & .collapseButton,
    & .closeButton {
      color: var(--color-red-600);
    }

    & .collapseButton:not(.disabled):focus,
    & .collapseButton:not(.disabled):hover,
    & .closeButton:not(.disabled):focus,
    & .closeButton:not(.disabled):hover {
      color: var(--color-red-800);
    }

    & header h3 {
      color: var(--color-red-800);
    }
  }

  /* Warning */
  &.level1 {
    background-color: var(--color-yellow-50);
    border-bottom: 1px solid var(--color-yellow-600);
    color: var(--color-yellow-700);

    & .actions a,
    & .actions button {
      color: var(--color-yellow-800);

      &:hover,
      &:focus {
        border-bottom-color: var(--color-yellow-800);
      }
    }

    & .collapseButton,
    & .closeButton {
      color: var(--color-yellow-600);
    }

    & .collapseButton:not(.disabled):focus,
    & .collapseButton:not(.disabled):hover,
    & .closeButton:not(.disabled):focus,
    & .closeButton:not(.disabled):hover {
      color: var(--color-yellow-800);
    }

    & header h3 {
      color: var(--color-yellow-800);
    }
  }

  /* Info */
  &.level0 {
    background-color: var(--color-sky-50);
    border-bottom: 1px solid var(--color-sky-600);
    color: var(--color-sky-700);

    & .actions a,
    & .actions button {
      color: var(--color-sky-800);

      &:hover,
      &:focus {
        border-bottom-color: var(--color-sky-800);
      }
    }

    & .collapseButton,
    & .closeButton {
      color: var(--color-sky-600);
    }

    & .collapseButton:not(.disabled):focus,
    & .collapseButton:not(.disabled):hover,
    & .closeButton:not(.disabled):focus,
    & .closeButton:not(.disabled):hover {
      color: var(--color-sky-800);
    }

    & header h3 {
      color: var(--color-sky-800);
    }
  }

  & .description {
    max-width: var(--content-extra-width);
    overflow: hidden;
  }
}
