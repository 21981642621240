.root {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100vh;
}

.content {
  display: grid;
  justify-items: center;
}

.logo {
  margin-left: var(--spacing-8);
  margin-top: var(--spacing-8);
  position: absolute;
}
