.root {
  margin: var(--spacing-4) auto 0;
  max-width: var(--content-max-width-v2);
}

.hours {
  & p {
    margin: 0;

    &:last-child {
      margin-top: var(--spacing-1);
    }
  }
}
