.clear {
  cursor: pointer;

  /* Add specificity to override previously set value. */
  &.clear {
    color: var(--color-decorative-primary);

    &:hover {
      color: var(--color-slate-900);
    }
  }
}
