.root {
  display: grid;
  flex: 1 1 auto;
  grid-template-areas:
    'header header'
    'sidebar main'
    'sidebar actionBar';
  grid-template-columns: 240px minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  height: 100vh;
  justify-content: space-between;
  width: 100vw;
}

.globalNotifications {
  grid-area: header;
  /* Prevent popovers from displaying on top */
  z-index: 1;
}

.spinner {
  height: 100vh;
  /* centering in parent div part 1 */
  left: 50%;
  max-width: var(--breakpoint-xl);
  position: absolute;
  top: 0;
  /* centering in parent div part 2 */
  transform: translate(-50%, 0);
  /* Subtract --spacing-3 from padding-left and --spacing-2 from padding-right from the width of the spinner to account for padding of page main */
  width: calc(100% - (var(--spacing-3) + var(--spacing-2)));
}

.content {
  --l-dropdown-space: 200px;

  background-color: var(--color-bg-secondary);
  border-left: 1px solid var(--color-decorative-secondary);
  overflow: auto;
  /* Dropdowns need space at the bottom of the page - "drop-up" too complex to be worth implementing */
  padding-bottom: var(--l-dropdown-space);
  /* Important for tooltips to scroll with the content */
  position: relative;
}

.sidebar {
  grid-area: sidebar;
  overflow-y: auto;
  padding: var(--spacing-3);
}

.main {
  margin: 0 auto;
  padding: var(--spacing-6);

  /* Remove the pesky top padding on this heading */
  & > div > h3:first-child {
    margin-top: 0;
  }

  & > * {
    margin-left: auto;
    margin-right: auto;
  }

  /* Legacy pages only */
  &.narrow,
  &.wide,
  &.full {
    padding: 0;

    & > div {
      padding: var(--spacing-6);
    }
  }

  &.narrow {
    & > div {
      max-width: var(--content-max-width-v2);
    }
  }

  /* Previously default width */
  &.wide {
    & > div {
      max-width: var(--breakpoint-xl);
    }
  }

  &.full {
    & > div {
      max-width: 100%;
    }
  }
}

.breadcrumbs {
  margin-bottom: var(--spacing-4);
}

.pageAlerts {
  flex: 0 0 100%;
}

@media print {
  .root {
    display: block;
    height: unset;
  }

  .globalNotifications {
    display: none;
  }

  .sidebar {
    display: none;
  }

  .content {
    overflow: visible;
  }
}
