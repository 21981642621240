.root {
  & input[type='radio'],
  & input[type='radio']:hover {
    accent-color: var(--l-theme-border-color);
  }

  &.block {
    align-items: flex-start;
    border: 1px solid var(--color-decorative-secondary);
    border-radius: var(--rounded-lg);
    color: var(--color-text-body);
    display: flex;
    flex: 1;
    padding: var(--spacing-3);

    & input[type='radio'] {
      margin-top: var(--spacing-1);
    }

    /* Make the whole label clickable */
    & label {
      color: var(--color-text-heading);
      cursor: pointer;
      flex-grow: 1;
      font-weight: var(--font-medium);
      height: 100%;

      & span,
      & svg {
        color: var(--color-text-body);
      }
    }

    & .description {
      color: var(--color-text-body);
      display: block;
      font-weight: var(--font-normal);
    }

    &.disabled {
      background-color: var(--color-bg-disabled);
      cursor: not-allowed;

      & > label {
        color: var(--color-text-disabled);
        cursor: not-allowed;

        & .description {
          color: var(--color-text-disabled);
        }
      }
    }

    &.checked {
      background-color: var(--l-theme-bg-color);
      border-color: var(--l-theme-border-color);
      color: var(--l-theme-content-color);

      & label {
        color: var(--l-theme-label-color);

        & .description {
          color: var(--l-theme-content-color);
        }

        & svg {
          color: var(--l-theme-content-color);
        }

        & span {
          color: var(--l-theme-label-color);
        }
      }
    }
  }
}
