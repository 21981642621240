.verification {
  background-color: var(--color-red-50);
  color: var(--color-danger);
  font-weight: var(--font-semibold);
  height: var(--button-with-icon-size);
  padding-left: var(--spacing-3);

  & > svg {
    margin-left: var(--spacing-1);
  }

  &.verified {
    background-color: var(--color-green-50);
    color: var(--color-success);
  }
}
