.tag {
  background-color: var(--color-slate-300);
  border-radius: var(--rounded-sm);
  color: var(--color-text-body);
  font-size: 85%;
  margin: var(--spacing-half);
  padding: 3px var(--spacing-1-half);
  text-overflow: ellipsis;
  white-space: nowrap;
}
