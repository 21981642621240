.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  height: 100%;
  justify-content: center;

  & span {
    margin-bottom: 0;
    padding-left: 0;
    padding-top: 0;
  }
}
