.grid {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-6);
  overflow: visible;
}

.itemWrapper {
  margin: 0;
}
