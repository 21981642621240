.elements {
  align-items: center;
  display: flex;
  gap: var(--spacing-8);
  justify-items: flex-start;

  & > *:first-child {
    align-items: center;
    display: flex;
    gap: var(--spacing-4);
  }

  & > *:not(:first-child) {
    flex-grow: 0;
  }

  & > *:last-child {
    margin-left: auto;
  }
}
