.thumbnail {
  align-items: center;
  height: 130px;
  padding: var(--spacing-3) var(--spacing-2) !important;

  & > a {
    display: inline;
  }

  & > div,
  & > a > div {
    display: flex;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
  }

  & img {
    background-color: var(--color-bg-primary);
    border: 1px solid var(--color-decorative-secondary);
    border-radius: var(--rounded);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: inline-block;
    height: auto;
    margin: auto;
    max-height: 100% !important;
    max-width: 100% !important;
    transition: all 0.2s ease-in-out;
  }
}
