.header {
  display: flex;
}

.headerText {
  flex: 1;
  margin: 0;
}

.preview {
  border: none;
  height: 80%;
  width: 100%;
}

.previewWrapper {
  flex: 1;
  margin: 10;
}

.config {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: var(--spacing-2-half) var(--spacing-2-half) 0 0;
}

.content {
  display: flex;
}

@media (max-width: 767px) {
  .content {
    flex-direction: column;
  }
}
