.root {
  justify-content: flex-start;

  & > * {
    &:first-child {
      width: 100%;
    }

    &:not(:first-child) {
      flex: 0 0 auto;

      &:not(:last-child) {
        margin-bottom: 0;
        margin-right: var(--spacing-2);
      }
    }
  }

  /* Legend */
  & :global(.legend) {
    font-size: var(--text-sm);
    line-height: var(--leading-sm);
    margin: 0 0 var(--spacing-2) 0;
  }

  /* Validation errors. */
  & > p {
    width: 100%;
  }
}

.button {
  /* Override button height to match input height */
  height: var(--button-with-icon-size);
}

.sideBySide {
  align-items: flex-end;
  display: flex;
  gap: var(--spacing-4);
  justify-content: space-between;
  margin-top: var(--spacing-4);
  width: 100%;

  & > :not(:last-child) {
    flex-grow: 1;
  }
}
