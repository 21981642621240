.container {
  display: flex;
}

.iconContainer {
  align-items: center;
  display: flex;
  margin-right: var(--spacing-4);
}

.nameContainer {
  align-items: center;
  display: flex;
}

.select {
  min-width: 200px;
}
