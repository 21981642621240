.description {
  font-style: italic;
  max-width: var(--content-max-width);
}

.filters {
  margin-bottom: var(--spacing-6);
}

.metadata {
  margin-bottom: var(--spacing-3);
  margin-top: calc(var(--spacing-3) * -1);
}

.usageContainer {
  display: flex;
  gap: var(--spacing-4);
  justify-content: space-between;
  width: 100%;
}

.heading {
  margin-top: 0;
}
