.root {
  align-items: center;
  border: 1px solid transparent;
  border-radius: var(--rounded);
  display: flex;
  font-size: var(--text-base);
  font-weight: var(--font-semibold);
  line-height: var(--leading-base);
  padding: 0;
  position: relative;
  width: 100%;

  & > div:not(.iconContainer) {
    width: 100%;
    /* z-index ensures hover styles are applied when cursor is on edit icon */
    z-index: 1;
  }

  & input {
    background: inherit;
    border: 1px solid transparent;
    color: var(--color-text-heading);
    cursor: pointer;
    font-size: inherit;
    /* Remove default browser outline on input */
    outline: none;
    padding: 0 var(--spacing-8) 0 var(--spacing-2);

    &:focus-visible {
      padding-right: 0;
    }
  }

  & .icon {
    color: var(--color-text-body);
  }
}

.root:has(input:active:not(:disabled)),
.root:has(input:focus:not(:disabled)),
.root:has(input:focus-within:not(:disabled)),
.root:has(input:focus-visible:not(:disabled)),
.root:has(input:active:hover:not(:disabled)),
.root:has(input:focus:hover:not(:disabled)) {
  border: 1px solid var(--color-action);

  & > div:not(.iconContainer) {
    width: 100%;
  }
}

.root:has(input:hover:not(:disabled)),
.root:has(input:focus-visible:not(:disabled)),
.root:has(input:active:not(:disabled)) {
  background: var(--color-bg-secondary);
  border-radius: var(--rounded);
}

.root:has(input:hover:not(:disabled, :focus-visible, :focus-within)) {
  &:not(.inputHasErrors) {
    border: 1px solid var(--color-decorative-secondary);
  }

  & .icon {
    color: var(--color-text-heading);
  }
}

.root.inputHasErrors {
  border-color: var(--color-danger);
}

.iconContainer {
  position: absolute;
  width: auto;
}

.validationError {
  color: var(--color-danger);
  margin-top: var(--spacing-2);
}
