.root {
  /* Should refactor these styles into reusable component or use TagsField */
  & :global(.react-select__multi-value) {
    background-color: var(--color-slate-200);
    border: 1px solid var(--color-slate-500);
    border-radius: var(--rounded-md);
    color: var(--color-slate-600);
    font-size: var(--text-xs);
    line-height: var(--text-sm);
    /* Calculation needed here to subtract border from padding */
    padding: calc(var(--spacing-half) - var(--spacing-px))
      calc(var(--spacing-1-half) - var(--spacing-px));
  }

  & :global(.react-select__multi-value__label) {
    padding: 0;
  }

  & :global(.react-select__multi-value__remove) {
    padding: 0;

    &:hover {
      background-color: var(--color-slate-300);
      border-radius: var(--rounded-sm);
      color: var(--color-slate-600);
    }
  }
}
