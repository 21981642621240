.actions {
  justify-content: space-between;

  & .rightButtonGroup {
    margin-left: auto;
  }
}

.columns {
  column-count: 2;
}

.content {
  & > * + section {
    margin-top: var(--spacing-4);
  }
}

.search {
  border-bottom: 1px solid var(--color-slate-200);
}

.noResults {
  column-count: 1;
  word-wrap: break-word;
}
