.authorize {
  display: flex;
  margin-top: var(--spacing-4);

  & > p:last-child {
    margin-left: var(--spacing-2);
  }
}

.card {
  margin-bottom: var(--spacing-4);
}

.expiration {
  flex: 1 0 auto;
}

.inline {
  display: flex;
  justify-content: flex-start;
  margin-bottom: var(--spacing-4);

  & > *:not(:first-child) {
    margin-left: var(--spacing-3);
  }
}

.postalCode {
  & > div {
    width: 128px;
  }
}

.securityCode {
  & > div {
    width: 96px;
  }
}
