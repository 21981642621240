.spinnerContainer {
  display: flex;
  padding: var(--spacing-6);
  place-content: center;
  width: 100%;
}

.childContent {
  gap: 0;
}
