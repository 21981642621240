.logo {
  height: var(--icon-size-lg);
}

.logoWrapper {
  align-items: center;
  border-radius: var(--rounded-full);
  display: flex;
  height: 56px;
  justify-content: center;
  width: 56px;
}

.colorPickers {
  display: flex;
  margin-bottom: var(--spacing-3);

  & > * {
    flex: 1;
  }
}

.imagePickerWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: var(--spacing-1);
}

.row {
  margin-bottom: var(--spacing-3);
}
