.root {
  align-self: stretch;
  display: flex;
  flex-direction: column;

  & > h4 {
    color: var(--color-text-heading);
    font-size: var(--text-lg);
    font-weight: var(--font-semibold);
    margin-top: 0;
  }
}

.container {
  align-self: stretch;
  display: grid;
  gap: var(--spacing-2);
  grid-auto-flow: column;
  justify-content: start;
  white-space: nowrap;
}

.label {
  color: var(--color-text-body);
  font-weight: var(--font-semibold);
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header {
  margin-bottom: 0;
}
