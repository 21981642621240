.search {
  color: var(--color-text-body);
}

.placeholder {
  display: flex;
  gap: var(--spacing-2);

  /* Don't apply to search icon */
  & span:not(:first-of-type) {
    color: var(--color-decorative-primary);
  }
}
