.logo {
  background-image: var(--l-background-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: var(--rounded);
  height: 70px;
  margin-right: var(--spacing-1);
  width: 100px;
}

.rank {
  font-size: var(--text-3xl);
  line-height: var(--leading-3xl);
}

.primary {
  color: var(--color-brand-teal);
}

.gold {
  color: var(--color-gold);
}

.silver {
  color: var(--color-silver);
}

.bronze {
  color: var(--color-bronze);
}

.link {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;

  &:hover {
    color: var(--color-primary-3);
  }
}

.nameColumn {
  display: flex;
}

.tooltip {
  align-self: center;
  cursor: pointer;
  padding: 0 var(--spacing-3);

  &:hover {
    color: var(--color-secondary-3);
  }
}
