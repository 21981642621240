.contentWrapper {
  align-items: center;
  display: flex;
  margin-right: 15px;
}

.spaced {
  margin-right: var(--spacing-2);
}

.description {
  color: var(--slate-600);
  display: block;
  font-size: var(--text-xs);
  margin-top: var(--spacing-1);
}

.optionGrid {
  align-items: center;
  display: grid;
  gap: var(--spacing-3);
  grid-template-columns: 100px minmax(150px, 2fr) minmax(80px, 1fr) minmax(100px, 1fr);
  padding: var(--spacing-1) var(--spacing-2);
  width: 100%;
}

.optionGridCell {
  align-items: center;
  display: flex;
  min-width: 0;
  overflow: hidden;

  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
