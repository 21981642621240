.root {
  border: none;
  border-radius: var(--rounded-2xl);
  cursor: pointer;
  display: inline-block;
  font-size: var(--text-xs);
  font-weight: var(--font-semibold);
  line-height: var(--leading-xs);
  padding: var(--spacing-2);
  transition:
    background-color var(--timing-300) ease-in,
    color var(--timing-300) ease-in;

  &:not(:last-child) {
    margin-right: var(--spacing-1);
  }
}

.count {
  border-radius: var(--rounded-2xl);
  margin-left: var(--spacing-1);
  padding: var(--spacing-px) var(--spacing-2);
  transition:
    background-color var(--timing-300) ease-in,
    color var(--timing-300) ease-in;
}

& .active {
  /* `var(--l-theme-accent-color)` is defined in SegmentedControlItem.tsx */
  background-color: var(--l-theme-accent-color);
  color: var(--color-white);

  & > .count {
    background-color: var(--color-white);
    color: var(--l-theme-accent-color);
  }
}

/* We must provide an inactive style to circumvent specificity errors */
& .inactive {
  background-color: var(--color-white);
  color: var(--color-slate-600);

  & > .count {
    background-color: var(--color-slate-200);
    color: var(--color-slate-600);
  }

  &:focus,
  &:hover {
    background-color: var(--color-slate-200);
    color: var(--color-slate-900);

    & > .count {
      background-color: var(--color-white);
      color: var(--color-slate-900);
    }
  }
}
