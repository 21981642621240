.root {
  background-color: var(--l-icon-background-color);
  border: 2px solid var(--color-text-body-inverted);
  border-radius: var(--rounded-full);
  display: inline-block;
  height: calc(var(--l-icon-size) * 1.8);
  line-height: calc((var(--l-icon-size) * 1.8) - var(--spacing-1));
  margin-bottom: var(--spacing-3);
  margin-top: var(--spacing-2);
  text-align: center;
  width: calc(var(--l-icon-size) * 1.8);

  & :global .svg-inline--fa {
    color: var(--color-text-body-inverted);
    vertical-align: middle;
  }
}

.hoverable {
  &:hover {
    border: 3px solid var(--color-text-body-inverted);
  }
}

.draft {
  background-color: var(--color-slate-400);
}
