.chartTooltip {
  background-color: var(--color-bg-primary);
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  padding: var(--spacing-2);
}

.chartsBox {
  margin: auto;

  & text {
    fill: var(--color-text-heading);
    font-size: var(--text-sm);
    font-weight: var(--font-semibold);
    line-height: var(--leading-sm);
  }
}
