.root {
  display: flex;
  flex-wrap: wrap;
}

.icon {
  margin: 0 var(--spacing-1) var(--spacing-1) 0;

  &:last-child {
    margin-right: 0;
  }
}
