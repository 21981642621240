.popover {
  background-color: var(--color-bg-primary);
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  max-width: 600px;
  overflow-x: hidden;
  padding: 0;
}

.input {
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  line-height: var(--leading-3xl);
  padding: 0 var(--spacing-2-half);
}

input[disabled] {
  background-color: var(--color-bg-disabled);
  color: var(--color-text-disabled);
  cursor: not-allowed;
}
