.root {
  display: flex;
  gap: var(--spacing-8);

  & .container {
    flex: 1 0 0;
  }
}

/* Desktop, Tablet landscape, should be --breakpoint-lg */
@media (max-width: 960px) {
  .root {
    flex-direction: column;
  }
}
