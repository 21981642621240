.root {
  align-items: center;
  display: flex;
}

.mode {
  flex-grow: 0 !important;
  margin: 0 var(--spacing-1);
  width: 84px !important;
}

.disabled {
  color: var(--color-text-heading);
  font-weight: var(--font-semibold);
  margin: var(--spacing-1);
}
