.preview {
  margin-bottom: var(--spacing-4);
  margin-top: var(--spacing-4);
  width: 100%;

  & > div {
    color: var(--color-disabled);
    font-style: italic;
    position: relative;
    text-align: center;

    & iframe {
      border: 1px solid var(--color-decorative-secondary);
      border-radius: var(--rounded);
      height: 300px;
      position: relative;
      width: 100%;
    }

    & p {
      left: 0;
      padding: 0 var(--spacing-3);
      position: absolute;
      top: calc(50% - 20px);
      width: 100%;
    }
  }
}

.select {
  display: flex;
  margin-top: var(--spacing-4);

  & > div {
    width: var(--content-max-width);
  }
}

.helpText {
  width: var(--content-max-width);
}
