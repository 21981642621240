.root {
  margin: 0 auto;
}

.extra {
  width: var(--content-extra-width);
}

.max {
  width: var(--content-max-width-v2);
}
