.root {
  font-size: var(--text-sm);
  width: auto;
}

.item {
  padding: var(--spacing-1) var(--spacing-2);

  &:hover {
    background: var(--color-slate-300);
  }

  & > a,
  & > button {
    color: var(--color-text-body);
    display: block;
    width: 100%;
  }
}
