.root {
  margin-top: var(--spacing-6);
}

.selectedRow,
.selectedRow.errorRow {
  /* Important needed to override striped styling when rows are selected */
  background: var(--color-sky-50) !important;
}

.errorRow {
  /* Important needed to override background color on row when there is an error */
  background: var(--color-red-50) !important;
}

.description {
  margin-bottom: var(--spacing-3);
  max-width: var(--content-max-width);

  & p {
    font-style: italic;
  }
}
