.verified {
  color: var(--color-success);
  font-size: var(--icon-size-md) !important;
  margin-left: var(--spacing-2);
  vertical-align: middle;
}

.details {
  color: var(--color-slate-900);
  display: flex;
  flex-direction: column;
  font-size: var(--text-sm);
  font-weight: var(--font-normal);
}

.icon {
  height: 36px;
  width: 36px;
}
