.root {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-1);
}

.label {
  margin-bottom: 0;

  &::after {
    content: ':';
  }
}
