.root {
  & > ol,
  & > ul {
    margin: 0;
    padding: 0;
  }
}

.horizontal {
  & > ol,
  & > ul {
    display: flex;

    & > li {
      flex: 1 0 auto;
    }

    & > li:not(:first-child) {
      margin-left: var(--spacing-2);
    }
  }
}

.vertical {
  & > ol,
  & > ul {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
  }

  &.indented {
    & > ol,
    & > ul {
      /* Need extra padding on top level nav */
      padding-left: var(--spacing-6-half);
    }

    & .indented {
      & > ol,
      & > ul {
        /* Reduced padding on secondary and tertiary nav */
        padding-left: var(--spacing-6);
      }
    }
  }
}
