.root {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  & > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: var(--content-max-width);
  }
}

.linkButton {
  background-color: inherit;
  border: none;
  color: var(--color-action);
  cursor: pointer;
  display: inline-block;
  font: inherit;
  height: auto;
  line-height: inherit;
  padding: 0;
  text-align: inherit;

  &:global(.active),
  &:active,
  &:focus,
  &:hover {
    background-color: inherit;
    color: var(--color-action-hover);
  }
}

.errorBody {
  text-align: center;
}

.headline {
  font-weight: var(--font-bold);
}
