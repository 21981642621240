.root {
  &form,
  &[role='form'] {
    max-width: none;

    & > fieldset {
      max-width: var(--content-max-width);
    }
  }
}

@media (max-width: 1409px) {
  .bannersnackEditor {
    margin: 0 calc((var(--spacing-3) * -1));
    width: calc(100% + ((var(--spacing-3) * 2) + var(--spacing-half)));
  }
}
