/* stylelint-disable no-descending-specificity */
.cards {
  & > div {
    flex-flow: wrap;

    & > * {
      flex-grow: 0 !important;
      margin-bottom: var(--spacing-3);

      &:not(:last-child) {
        margin-right: var(--spacing-2);
      }
    }
  }
}

.chips {
  & > div {
    display: block;

    & > * {
      display: inline-block;
      margin-bottom: var(--spacing-1);

      &:not(:last-child) {
        margin-right: var(--spacing-1);
      }
    }
  }
}
