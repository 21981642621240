.logo {
  background-position: center;
  background-size: cover;
  border-radius: var(--rounded);
  display: block;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 40px;
}

.root {
  text-align: center;
}
