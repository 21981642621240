.root {
  border: 1px solid var(--color-slate-200);
  border-radius: var(--rounded-md);
  box-shadow: var(--drop-shadow-popover);
}

.label {
  color: var(--color-slate-500);
  padding: var(--spacing-1-half) var(--spacing-3);
}

.divider {
  border-color: var(--color-slate-200);
}
