.wrapper {
  display: flex;

  /* breakpoint-xxl: 1410px; Desktop xtra-large */
  @media (max-width: 1410px) {
    flex-direction: column;
  }

  & > .chartContainer {
    flex: 1;
  }

  & > .statsContainer {
    display: flex;
    flex: 0;
    flex-direction: column;
    justify-content: space-around;
    margin-right: var(--spacing-6);

    @media (max-width: 1410px) {
      flex-direction: row;
      margin-right: 0;
    }

    & > .stat {
      & > label,
      & > span {
        font-weight: var(--font-semibold);
        text-align: right;
        width: 100%;
      }

      & > .statNumber {
        font-size: var(--text-3xl);
        font-weight: var(--font-semibold);
        line-height: var(--leading-3xl);
        text-align: right;
      }
    }
  }

  &.manyStats {
    display: block;

    & > .statsContainer {
      flex-direction: row;

      & > .stat {
        & > label,
        & > span {
          text-align: center;
        }

        & > .statNumber {
          text-align: center;
        }
      }
    }
  }
}
