.tabledFunnel {
  border-bottom-left-radius: var(--rounded-none);
  border-bottom-right-radius: var(--rounded-none);
  margin-bottom: var(--spacing-half);
}

.table {
  background-color: var(--color-slate-200);
  border-bottom-left-radius: var(--rounded);
  border-bottom-right-radius: var(--rounded);
  padding: var(--spacing-3);

  & :global .ReactTable {
    background-color: var(--color-bg-primary);

    & .rt-noData {
      background: transparent;
      padding: 0;
      /* Needed to be adjusted so the placeholder text would be displayed in the correct location */
      top: 47px;
    }
  }
}
