.filter {
  width: var(--form-filter-width);
}

.row {
  cursor: pointer;
}

.selected {
  background-color: var(--color-primary-4);

  &:hover {
    background-color: var(--color-primary-4);
  }
}
