.root {
  border-bottom: 1px solid var(--color-decorative-secondary);
  border-left: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-none) var(--rounded-none) var(--rounded-lg)
    var(--rounded-lg);
  border-right: 1px solid var(--color-decorative-secondary);
  border-top: 0;
  max-width: 100%;
  padding: var(--spacing-3);

  & h3 {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: var(--text-lg);
    margin-bottom: var(--spacing-3);
    margin-top: var(--spacing-2);
  }
}

.content {
  border-radius: var(--rounded-none);
}
