.mergeResultContainer {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-3);
  padding: 0;
}

.mergeResult {
  margin-left: 0;
}

.dialogSection {
  margin-bottom: var(--spacing-3);
}

.fieldOptionContainer {
  display: flex;
}

.fieldOptionIcon {
  align-items: center;
  display: flex;
  margin-right: var(--spacing-2);
}

.fieldOptionKey {
  align-items: center;
  display: flex;
}
