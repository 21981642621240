.filter {
  min-width: 200px;
}

.filterCheckbox {
  align-items: center;
  display: flex;
  margin-left: var(--spacing-2);

  & input,
  & label {
    cursor: pointer;
  }
}

.filterSearch {
  /* Accomodate longer placeholder text. */
  min-width: 230px;
}

.grid {
  flex-direction: column;
  flex-wrap: nowrap;
  gap: var(--spacing-1);
}

.gridItem {
  margin-bottom: 0;
}
