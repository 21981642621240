.root {
  display: flex;
  flex-direction: column;
}

.content {
  display: grid;
  gap: var(--spacing-8);
}

.stats {
  display: flex;
}
