.button {
  border-bottom-right-radius: var(--rounded-none);
  border-right: 0;
  border-top-right-radius: var(--rounded-none);
}

.label {
  margin-bottom: auto;
}

.prefix {
  border: none;
  padding: 0;
}
