.metadata {
  margin-bottom: var(--spacing-3);

  & > * {
    display: inline-block;

    &:nth-child(2) {
      margin-left: var(--spacing-1);
    }
  }
}

.version {
  color: var(--slate-600);
}

.content {
  padding: var(--spacing-3) 0;
}

.preview {
  border: 1px solid var(--color-decorative-secondary);
  padding: var(--spacing-4);
  width: 100%;
}
