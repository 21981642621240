.container {
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  margin-bottom: var(--spacing-2-half);
  padding: var(--spacing-2-half);
  width: 100%;
}

.content {
  margin: auto;
  text-align: center;
  width: var(--content-max-width);
}

.modelLabel {
  margin-bottom: var(--spacing-3);
}

.separator {
  margin: var(--spacing-1);
}

.tooltip:hover {
  cursor: default;
}

.label {
  font-weight: var(--font-bold);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-6);
}
