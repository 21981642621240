.bannersnack {
  margin-left: calc(50% - ((200px + var(--spacing-3)) / 2));
  margin-top: var(--spacing-4);
}

.creativeButton {
  margin-top: var(--spacing-3);
  width: 125px !important;
}

.specTable {
  border: 1px solid var(--color-decorative-secondary);
  border-collapse: collapse;
  width: 100%;

  & thead {
    & tr {
      border-bottom: 1px solid var(--color-decorative-secondary);
    }
  }

  & th {
    font-style: normal;
    font-weight: var(--font-semibold);
  }

  & td,
  th {
    padding: 0.5rem;
    text-align: left;
    vertical-align: top;

    & dl {
      font-style: normal;
      list-style-type: none;
      margin: 0;
      padding: 0;

      & dt {
        display: inline;
        font-weight: var(--font-bold);
        margin-right: var(--spacing-2);
      }

      & dd {
        display: inline;
      }

      & dd::after {
        content: '';
        display: block;
      }
    }

    &:first-child {
      border-right: 1px solid var(--color-decorative-secondary);
    }
  }
}
