.current {
  color: var(--color-primary-3);

  &:hover {
    color: var(--color-primary-3);
  }
}

.filter {
  width: 200px;
}

.statusFilter {
  color: var(--color-text-body);
  cursor: pointer;
  font-weight: var(--font-semibold);
  margin-right: var(--spacing-2-half);

  &:last-child {
    margin-right: 0;
  }
}
