.button {
  float: right;
  max-width: var(--content-max-width);
  position: relative;
}

.card {
  margin-top: var(--spacing-3);
  max-width: var(--content-max-width);
}

.icon {
  margin-right: var(--spacing-1);
  size: 1.5em;
}

.draft {
  color: var(--color-warning);
}

.complete {
  color: var(--color-success);
}

.subdued {
  color: var(--slate-600);
  margin-left: var(--spacing-1);
}

.chip {
  margin-left: var(--spacing-1);
}

.tags {
  margin-bottom: var(--spacing-2);
}

.meta {
  display: flex;
  justify-content: space-between;
}
