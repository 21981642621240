.root {
  /* Maximum of 4 stats per line */
  --l-stat-width-percentage: 25%;

  color: var(--color-text-body);
  /* Subtract gap from the flex basis */
  flex: 1 0 calc(var(--l-stat-width-percentage) - var(--spacing-6));
  font-size: var(--text-sm);
  line-height: var(--leading-sm);
  padding: var(--spacing-6);

  @media screen and (max-width: 1200px) {
    --l-stat-width-percentage: 33%;
  }

  @media screen and (max-width: 950px) {
    --l-stat-width-percentage: 50%;
  }

  @media screen and (max-width: 768px) {
    --l-stat-width-percentage: 100%;
  }

  &.large {
    padding: 0 var(--spacing-4);
  }

  &.full {
    border: 1px solid var(--color-decorative-secondary);
    border-radius: var(--rounded-lg);
  }

  &.none {
    border: none;
  }

  &.left {
    border-left: 1px solid var(--color-decorative-secondary);
  }

  &.right {
    border-right: 1px solid var(--color-decorative-secondary);
  }
}

.center {
  text-align: center;
}

.primaryStatLabel {
  color: var(--color-text-body);
  font-weight: var(--font-semibold);
  margin-right: var(--spacing-2);
}

.primaryStat {
  color: var(--l-text-color);
  font-size: var(--text-3xl);
  font-weight: var(--font-semibold);
  line-height: var(--leading-3xl);
  margin-top: var(--spacing-1);

  &.large {
    font-size: var(--text-5xl);
    margin-top: var(--spacing-5);
  }

  &.loading {
    margin-top: var(--spacing-4);
  }
}

.secondaryStatWrapper {
  color: var(--color-text-body);
  margin-top: var(--spacing-1);

  &.large {
    margin-top: var(--spacing-2);
  }
}

.warning {
  color: var(--color-yellow-700);
}

.warningIcon {
  margin-left: var(--spacing-1);
}

.secondaryStatLabel {
  font-weight: var(--font-medium);
  margin-right: var(--spacing-1);

  &.colon {
    &::after {
      content: ':';
    }
  }
}

.mini.mini {
  border: none;
  border-radius: var(--rounded-none);
  border-top: 1px solid var(--color-decorative-secondary);
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-3);

  & .primaryStatLabel {
    color: var(--color-text-heading);
    font-size: var(--text-small);
    font-weight: var(--font-medium);
    line-height: var(--leading-small);
  }

  & .primaryStat {
    color: var(--color-text-body);
    font-size: var(--text-small);
    font-weight: var(--font-normal);
    line-height: var(--leading-small);
    margin-top: 0;
  }
}
