.button {
  flex: 0;
}

.button > span {
  margin-right: 0px;
}

.container {
  margin: 0px auto;
  text-align: left;
}

.container > div {
  display: inline-block;
  width: 33%;
}

.value {
  margin-left: 3px;
}

.selectWrapper {
  max-width: 300px;
}
