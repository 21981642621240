.table {
  border-collapse: separate;
  border-spacing: 0 var(--spacing-4);
  width: 44rem;

  & td {
    background-color: var(--neutral-4);
    border-bottom: 1px solid var(--color-decorative-secondary);
    border-top: 1px solid var(--color-decorative-secondary);
    overflow: visible;
    padding: var(--spacing-2) 0 var(--spacing-2) var(--spacing-3);
    vertical-align: top;

    & > * > :last-child {
      margin-bottom: 0;
    }

    &:first-child {
      border-bottom-left-radius: var(--rounded);
      border-left: 1px solid var(--color-decorative-secondary);
      border-top-left-radius: var(--rounded);
    }

    &:last-child {
      border-bottom-right-radius: var(--rounded);
      border-right: 1px solid var(--color-decorative-secondary);
      border-top-right-radius: var(--rounded);
      padding-left: 0;
      padding-top: calc(
        1rem + var(--spacing-2) + var(--spacing-2) + var(--spacing-half)
      );
      /* Square button, so we can cheat */
      width: var(--button-with-icon-size);
    }
  }
}
