.mapContainer {
  align-items: center;
  display: flex;
  justify-content: center;

  & > .map {
    border-radius: var(--rounded-md);
    height: 400px;
    width: 100%;
    z-index: 0;
  }
}
