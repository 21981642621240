.columns {
  --column-first-width: calc(50% - (30px + var(--spacing-2)));
  --column-second-width: calc(100% - var(--column-first-width));

  display: flex;
  width: 100%;

  & > :first-child {
    flex: 0 0 var(--column-first-width);
    margin-right: var(--spacing-2);
    width: var(--column-first-width);
  }

  & > :last-child {
    flex: 0 0 var(--column-second-width);
    width: var(--column-second-width);

    & > legend,
    & > p {
      margin: 0 auto;
      padding-left: 60px;
      width: 95%;
    }
  }
}

.infinite {
  overflow: initial !important;
}

.placeholder {
  height: calc(
    var(--leading-normal) * var(--text-3xl) - 0.2857142857125rem * 2
  );
}
