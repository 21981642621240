.root {
  /* Stretch to fill the height of the largest item in the row */
  height: 100%;
}

.title {
  font-size: var(--text-base);
  font-weight: var(--font-semibold);
  line-height: var(--leading-base);
  margin: var(--spacing-6) 0 0 0;
}

.description {
  margin-top: var(--spacing-2);
}

.error {
  color: var(--color-danger);
  font-size: var(--text-xs);
  line-height: var(--leading-xs);
}
