.root {
  max-height: 200px;
  overflow-y: scroll;
}

.suggestion {
  cursor: pointer;
  max-width: 600px;
  overflow-x: hidden;
  padding: var(--spacing-2) var(--spacing-2) var(--spacing-2) var(--spacing-3);
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    background-color: var(--color-primary-4);
  }
}

.loading {
  padding: var(--spacing-2-half);
}

.noResults {
  max-width: 600px;
  overflow-x: hidden;
  padding: var(--spacing-2) var(--spacing-2) var(--spacing-2) var(--spacing-3);
  text-overflow: ellipsis;
  white-space: nowrap;
}
