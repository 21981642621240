.wizard {
  & > div:last-child {
    flex-direction: column;
  }
}

.filename {
  flex: 0.4;
}

.upload {
  flex: 0;
}

.arrow {
  flex: 0;
  margin: var(--spacing-3) var(--spacing-4) 0 0;
}

.trash {
  flex: 0;
}

.ordinal {
  flex: 0;
}

.chip {
  margin-right: var(--spacing-2);
}

.valid {
  color: var(--color-success);
  margin-left: var(--spacing-1);
}

.invalid {
  color: var(--color-warning);
  margin-left: var(--spacing-1);
}
