.popover {
  margin: 15px;
}

.info {
  cursor: help;
  /* override display: block in Popover .trigger */
  display: inline !important;
  margin-left: var(--spacing-2);
}
