.content {
  height: 100%;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-6);

  /* Spread projects evenly when the browser is large enough - must be exactly 27px */
  @media (min-width: 1416px) {
    gap: 27px;
  }
}

.noProjects {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100% - 200px);
  justify-content: center;
  max-width: var(--breakpoint-xl);
  position: absolute;
  /* Subtract --spacing-3 from padding-left and --spacing-2 from padding-right from the width of the spinner to account for padding of page main */
  width: calc(100% - (var(--spacing-3) + var(--spacing-2)));

  & a,
  & button {
    margin-top: var(--spacing-4);
  }
}

.filter {
  min-width: 200px;
}

.filterName {
  min-width: 200px;
}

.superpixel {
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-none) var(--rounded-none) var(--rounded-lg)
    var(--rounded-lg);
  border-top: 0;
  margin-bottom: var(--spacing-6);
  margin-top: -18px;
  padding: var(--spacing-4);
}
