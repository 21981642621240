.root {
  margin: 0 var(--spacing-2);
  width: 100%;

  &:not(:last-child) {
    margin-bottom: var(--spacing-6);
  }
}

.tldr {
  width: calc(33.3333% - (var(--spacing-2) + var(--spacing-half)));
}
