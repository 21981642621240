.root {
  margin: 0 auto;
  max-width: var(--content-max-width);
}

.suffix {
  background: var(--color-bg-primary);
  border: none;
  margin-left: var(--spacing-2);
  padding: 0;
}

.alert {
  margin-bottom: 0;
}
