.container {
  height: 100%;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  /* 1 higher than page header */
  z-index: 501;
}

/* Gradient overlay that will fades out confetti closer to the bottom of the screen */
.gradientOverlay {
  height: 100%;
  left: 0;
  mask-image: linear-gradient(
    to bottom,
    /* Fully visible at top */ rgba(0, 0, 0, 1) 0%,
    /* Completely Opaque */ rgba(0, 0, 0, 0) 60%,
    /* Completely Opaque */ rgba(0, 0, 0, 0) 100%
  );
  opacity: 1;
  position: absolute;
  top: 0;
  width: 100%;
}
