.root {
  background: var(--color-bg-primary);
  border-left: 1px solid var(--color-decorative-secondary);
  border-top: 1px solid var(--color-decorative-secondary);
  grid-area: actionBar;
  padding: var(--spacing-4);
  position: sticky;
}

.flex {
  align-items: center;
  display: flex;
  gap: var(--spacing-2);
  justify-content: space-between;
  margin-left: auto;
}
