.root {
  &form,
  &[role='form'] {
    max-width: none;

    & > fieldset {
      max-width: var(--content-max-width);
    }
  }
}

.toolbar {
  margin-top: var(--spacing-4);
}
