.bulkActions {
  justify-content: space-between;
  width: 200px;

  & > span {
    border-left: 1px solid var(--color-decorative-secondary);
    color: var(--color-slate-400);
    float: right;
    padding-left: var(--spacing-3);
  }

  &[disabled] > span {
    color: var(--color-disabled);
  }
}

.dropdown {
  width: 200px;
}

.filter {
  min-width: 200px;
}

.menu {
  width: 100%;
}

.modal {
  max-width: 100%;
  width: 240px;
}

.tags {
  width: 100%;
}
