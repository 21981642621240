.description {
  display: block;
  font-weight: var(--font-normal);
  padding-top: var(--spacing-2);
}

.root {
  background-color: var(--color-bg-primary);
  padding: var(--spacing-2) var(--spacing-2) var(--spacing-2) var(--spacing-3);
  position: relative;
  transition:
    box-shadow 100ms ease-in,
    transform 100ms ease-in;

  &:hover {
    box-shadow: var(--drop-shadow-md);
    transform: translate(-1px, -1px);
  }

  & a {
    color: var(--color-text-body);
    text-decoration: none;
  }

  & header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-right: var(--icon-size-lg);
    width: 100%;

    & > *,
    & > a > * {
      flex: 0 0 auto;

      &:not(:last-child) {
        margin-right: var(--spacing-2);
      }
    }

    & > a {
      display: flex;
      flex: 1 1 auto;
      padding-bottom: calc((var(--icon-size-lg) - (var(--leading-normal) * var(--text-sm))) / 2);
      padding-top: calc((var(--icon-size-lg) - (var(--leading-normal) * var(--text-sm))) / 2);
    }

    & > svg,
    & > a > svg {
      padding-top: calc(((var(--leading-normal) * var(--text-sm)) - var(--text-sm)) / 2);
    }

    & > h3,
    & > a > h3 {
      flex: 1 1 auto;
      font-size: var(--text-sm);
      font-weight: var(--font-semibold);
      line-height: var(--leading-sm);
      margin: 0 var(--spacing-4) 0 0;
      word-wrap: break-word;
    }
  }

  /* Banner type skeleton */
  &.banner {
    border-bottom: 1px solid var(--color-decorative-secondary);
    padding: var(--spacing-4);

    & header {
      & > h3 {
        display: flex;
        font-size: var(--text-sm);
        font-weight: var(--font-medium);
        line-height: var(--leading-sm);
        margin: 0;
        padding-left: var(--spacing-3);
      }
    }

    &.loading {
      background-color: var(--color-bg-primary);

      & .loadingIcon {
        margin-right: var(--spacing-3);
      }
    }
  }

  &.card {
    border: 1px solid var(--color-decorative-secondary);
    border-radius: var(--rounded-lg);
    box-shadow: var(--drop-shadow);
    margin-bottom: var(--spacing-2);
    margin-top: var(--spacing-2);
    max-width: var(--content-max-width);

    &.loading {
      background-color: var(--color-bg-primary);
      border-left: 4px solid var(--color-slate-400);
    }

    & header {
      min-height: var(--icon-size-lg);
    }
  }
}
