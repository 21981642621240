.buttonWrapper {
  max-width: var(--content-max-width);
  width: 100%;
}

.link {
  padding: var(--spacing-12) var(--spacing-4);
  text-align: center;

  & > a {
    margin-top: var(--spacing-4);
  }
}
