:root {
  /* Margin left of var(--spacing-5) plus the size of the input field  */
  /* This is to take into account the the input field, select field, and last sync column widths. */
  --primary-header-width: 226px;
  --secondary-header-width: 254px;
}

.header {
  color: var(--color-text-heading);
  display: grid;
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
  grid-template-columns: var(--primary-header-width) var(--secondary-header-width) 1fr;
  margin: var(--spacing-5);

  & .primaryHeader {
    width: var(--primary-header-width);
  }

  & .actionsHeader {
    justify-self: end;
  }
}

.message {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  justify-content: center;

  & button {
    margin-top: var(--spacing-3);
  }
}

.select {
  --l-select-width: 256px;

  width: var(--l-select-width);

  & :global(.react-select__single-value--is-disabled) {
    color: var(--color-slate-500);
  }
}

.sync {
  font-size: var(--text-xs);
}
