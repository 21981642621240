.root {
  height: 100%;
  table-layout: fixed;
  width: 100%;

  & th {
    font-weight: var(--font-semibold);
    text-align: left;
  }

  & td {
    display: flex;
  }

  & td,
  & th {
    flex-basis: 33.33%;
    padding: 0;
    text-align: left;
  }

  & tr {
    display: flex;
    width: 100%;
  }

  & tr:nth-child(odd) {
    margin-bottom: var(--spacing-3);
  }

  & tr:nth-child(even):not(:last-of-type) {
    margin-bottom: var(--spacing-6);
  }
}

.key {
  font-weight: var(--font-semibold);
  line-height: var(--leading-none);
}

.icon {
  cursor: pointer;
  margin: var(--spacing-1) var(--spacing-2) 0 0;

  &:hover {
    color: var(--color-primary-3);
  }
}
