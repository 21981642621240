.root {
  display: flex;
  flex-direction: column;

  & .title {
    color: var(--color-text-heading);
    font-weight: var(--font-medium);
    margin-bottom: var(--spacing-1);
  }

  & .content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
    justify-content: center;

    & .subtitle {
      color: var(--color-slate-600);
      font-size: var(--text-xs);
      line-height: var(--leading-xs);
    }
  }
}
