/* stylelint-disable no-descending-specificity */
.root {
  background-color: var(--color-slate-200);
  border-collapse: collapse;
  border-radius: var(--rounded);
  margin-bottom: var(--spacing-6);
  table-layout: fixed;
  width: 100%;

  & caption {
    & > time {
      float: right;
    }
  }

  & th,
  td {
    width: calc(100% / var(--l-stages));
  }

  & th {
    background-color: var(--color-primary-4);
    font-weight: normal;
    padding: 0.5em 1em 0.5em 2em;
    position: relative;

    & > span {
      display: inline-block;
      margin-left: var(--spacing-2);
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: nowrap;

      &:first-child {
        max-width: calc(100% - 1em - (2 * var(--spacing-2)));
      }

      &:last-child {
        width: 1em;
      }
    }

    &:not(:last-child) {
      &::after {
        background-color: var(--color-primary-4);
        clip-path: polygon(100% 50%, 0 100%, 0 0);
        content: ' ';
        display: block;
        height: 100%;
        left: 100%;
        position: absolute;
        top: 0;
        width: 1em;
        z-index: 1;
      }
    }

    &:not(:first-child) {
      &::before {
        background-color: var(--color-bg-primary);
        clip-path: polygon(
          100% 50%,
          0.14285714285714285em 100%,
          0% 100%,
          0 0,
          0.14285714285714285em 0
        );
        content: ' ';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: calc(1em + 0.14285714285714285em);
      }
    }

    & :global(.popover-container) {
      cursor: help;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:first-child {
      border-radius: var(--rounded) var(--rounded-none) var(--rounded-none)
        var(--rounded);
      padding-left: 1em;
    }

    &:last-child {
      border-radius: var(--rounded-none) var(--rounded) var(--rounded)
        var(--rounded-none);

      & :global(.popover-container) {
        right: 1em;
      }
    }

    &.active {
      background-color: var(--color-primary-3);

      &:not(:last-child) {
        &::after {
          background-color: var(--color-primary-3);
        }
      }
    }

    &.completed {
      &::before {
        clip-path: none;
        width: 0;
      }
    }

    &.dummyLast {
      padding: 0px;
      width: 0px;
    }

    &.leadingArrow {
      border-radius: var(--rounded-none);
      padding: 0.5em 1em 0.5em 2em;

      &::before {
        background-color: var(--color-bg-primary);
        clip-path: polygon(
          100% 50%,
          0.14285714285714285em 100%,
          0% 100%,
          0 0,
          0.14285714285714285em 0
        );
        content: ' ';
        display: block;
        height: 100%;
        left: -3px;
        position: absolute;
        top: 0;
        width: calc(1em + 0.14285714285714285em);
      }
    }

    &.leads {
      &::after {
        border-right: 2px solid var(--color-bg-primary);
        clip-path: none;
        width: 0px;
      }
    }
  }

  & td {
    padding: var(--spacing-2) 1em;
    position: relative;

    &:not(:last-child) {
      &::after {
        border-right: 2px solid var(--color-bg-primary);
        content: ' ';
        display: block;
        height: 100%;
        left: 100%;
        position: absolute;
        top: 0;
        width: 0;
      }
    }

    &:first-child {
      padding-left: 1em;
    }

    &:last-child {
      padding-right: 1em;
    }

    &.dummyLast {
      padding: 0px;
      width: 0px;
    }
  }
}

.buttonWrapper {
  float: right;
  margin-left: var(--spacing-1);
}

.button {
  display: inline-block;
}

.buttonPressed {
  background-color: var(--color-slate-200);
  color: var(--color-action-hover);
}

.percent {
  display: inline-block;
  width: var(--button-with-icon-size);

  & > span {
    margin-right: calc(var(--avatar-size-sm) * -0.45);
    vertical-align: middle;
  }

  & .counter {
    display: inline-block;
    font-weight: var(--font-bold);
    margin-right: 0;
    min-width: 1em;
    text-align: center;
    text-shadow: -1px -1px 1px var(--color-text-body-inverted),
      1px -1px 1px var(--color-text-body-inverted),
      -1px 1px 1px var(--color-text-body-inverted),
      1px 1px 1px var(--color-text-body-inverted);
  }
}

.primary {
  & th {
    background-color: var(--color-secondary-1);

    &:not(:last-child) {
      &::after {
        background-color: var(--color-secondary-1);
      }
    }
  }
}
