.delimiter {
  color: var(--color-slate-500);
  display: inline-block;
  font-weight: 400;
  margin-left: var(--spacing-2);
  margin-right: var(--spacing-2);
}

.drawerActions {
  & > button,
  /* Disabled buttons */
  & > a,
  /* Buttons with a tooltip */
  & > span {
    flex-grow: 1;
  }

  & > button,
  & > a,
  & > span > a,
  & > span > button {
    /* TODO: Remove this temporary fix once #4017 is resolved */
    justify-content: center;
  }

  & > span > a,
  & > span > button {
    width: 100%;
  }
}

.lastSaved {
  display: inline-block;
}
