.buttons {
  display: flex;
  flex-direction: column;

  & > * {
    display: block;
    width: 100%;
  }

  & > :not(:last-child) {
    margin-bottom: var(--spacing-2);
  }

  & > span:first-child {
    /* TODO: Fix height in Tooltip.css instead */
    height: unset;
  }
}

.card {
  display: flex;
  flex: 1;
  margin-right: var(--spacing-2);
  margin-top: var(--spacing-6);
  min-width: 0;

  &:last-child {
    margin-right: 0;
  }

  & label {
    display: flex;
  }
}

.cardContent {
  display: flex;
}

.funds {
  flex: 1 1 auto;
}

.info {
  cursor: help;
  margin-left: var(--spacing-2);
}

.inline {
  align-items: center;
  display: flex;

  & > * + * {
    margin-left: var(--spacing-3);
  }

  & :first-child {
    flex: 1 1 auto;
  }
}

.section {
  margin-bottom: var(--spacing-2-half);
}
