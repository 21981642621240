.root {
  & :global(.ReactCollapse--content) {
    & > div {
      /* view height - nav padding - button height - popver margin - (account switcher + projects item) */
      max-height: calc(
        100vh - (2 * var(--spacing-3)) - var(--account-switcher-button-height) - var(--spacing-1) -
          118px
      );
    }
  }
}

.button.button {
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  color: var(--color-slate-900);
  line-height: var(--account-switcher-icon-size);
  /* Undo padding set to 0 by nav menu */
  padding: var(--spacing-1-half) var(--spacing-2);

  & > :last-child > svg {
    /* Adjust icon alignment due to border */
    padding-right: 4px;
  }
}

.navBarEventLogo {
  --l-logo-size: 34px;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--rounded);
  display: inline-block;
  height: var(--l-logo-size);
  line-height: var(--l-logo-size);
  margin-right: 5px;
  min-height: var(--l-logo-size);
  min-width: var(--l-logo-size);
  text-align: center;
  vertical-align: middle;
  width: var(--l-logo-size);
}

.navBarEvent {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
