.root {
  & > div > * {
    max-width: 100%;
  }
}

.value {
  display: block;
  font-size: var(--text-sm);
  line-height: var(--leading-sm);
  padding: 9px 0 9px var(--spacing-3);
}
