.root {
  margin: 0 auto;
  max-width: var(--content-max-width);
}

.checkbox {
  /* TODO: Cleanup FormElement css to not require flex-grow override. */
  flex-grow: 0 !important;
  margin-bottom: var(--spacing-4);
}

.list {
  margin-bottom: 0;
  padding-left: var(--spacing-3);
}
