/* stylelint-disable no-descending-specificity */
.root {
  display: flex;
  overflow-x: scroll;
  width: 100%;

  & > :global(.smooth-dnd-container) {
    & > :global(.smooth-dnd-draggable-wrapper) {
      display: flex;

      &:not(:first-child) {
        padding-left: var(--spacing-3);
      }

      & > section {
        cursor: move;
      }
    }
  }
}

.leg {
  --l-leg-width: 255px;

  background-color: var(--color-slate-50);
  border: 1px solid var(--color-decorative-secondary);
  height: calc(100% - (var(--spacing-3) / 2));
  /* Accommodate scrollbar when there are too many legs; padding is reduced on flight list item */
  margin-bottom: calc(var(--spacing-3) / 2);
  margin-right: var(--spacing-3);
  /* Fit 4 legs & plus button at full-screen width */
  max-width: var(--l-leg-width);
  min-height: 120px;
  min-width: var(--l-leg-width);
  padding-bottom: var(--spacing-3);

  &:last-child {
    margin-right: 0;
  }
}

.legContent {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;

  & > h3 {
    font-size: var(--text-sm);
    padding-bottom: var(--spacing-2);
  }

  & > section {
    margin-bottom: var(--spacing-3);
  }
}
