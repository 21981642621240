.wrapper {
  width: calc(100% - var(--spacing-4));

  & :global(.react-select__option):not(:global(.react-select__option--is-selected)) {
    border: 1px solid var(--color-white);
  }
}

.option {
  align-items: center;
  display: flex;
  gap: var(--spacing-2);
  justify-content: space-between;
}

.label {
  color: var(--color-text-heading);
  font-weight: var(--font-medium);
  max-width: fit-content;
  width: 100%;
}

.data {
  align-items: center;
  display: flex;
  gap: var(--spacing-2);
  margin-bottom: 0;
  width: 100%;

  & > dt {
    margin-bottom: 0;
  }

  & > dd {
    margin-bottom: 0;

    &:last-child {
      margin-left: auto;
    }
  }
}
