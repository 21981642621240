.menu {
  z-index: 1040;
}

.optionsButton {
  width: var(--button-with-icon-size);

  & > span {
    margin-right: 0;
  }

  &:not(:last-child) {
    margin-right: var(--spacing-px);
  }

  &:global(.active),
  &:active,
  &:focus,
  &:hover,
  &.clicked {
    background-color: var(--color-slate-300);
    color: var(--color-action-hover);
  }
}
