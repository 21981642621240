.copy {
  margin-top: var(--spacing-2);
}

.code {
  & div:first-of-type {
    border: var(--border-transparent);
  }

  & :global(.CodeMirror) {
    background: var(--color-slate-800);
    border-radius: var(--rounded-xl);
    color: var(--color-white);
    height: min-content;
    padding: var(--spacing-6);
  }

  & :global(.CodeMirror-line) span {
    color: white;
  }
}
