.addressWell {
  margin-bottom: var(--spacing-2);
}

.alert {
  margin-top: var(--spacing-2);
}

.emailAddress {
  margin-bottom: var(--spacing-2);
}
