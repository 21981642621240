.section {
  border-bottom: none;
  flex-direction: column;
  padding: 0 0 var(--spacing-6) 0;

  &:last-child {
    padding: 0;
  }
}

.sectionContent {
  margin-left: 0;
}

.sectionHeader {
  & h4 {
    margin: 0 0 0 var(--spacing-2);
  }
}
