.wrapper {
  background-image: none;
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  overflow: hidden;
}

.help {
  font-size: 0.8rem;
  margin-bottom: var(--spacing-1);
}
