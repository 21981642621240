/* stylelint-disable no-descending-specificity */
@media only screen and (min-width: 1170px) {
  .root {
    &:global(.vertical-timeline::before) {
      background: none;
      display: none;
      width: 0;
    }

    &:global(.vertical-timeline) {
      padding: 0;
      width: 100%;
    }

    &:global(.vertical-timeline--one-column) {
      & :global(.vertical-timeline-element) {
        & :global(.vertical-timeline-element-content-arrow) {
          border-left: inherit;
          border-right: none;
          display: none;
        }
      }
    }

    & :global(.vertical-timeline-element) {
      margin: 0;
    }
  }
}

.item {
  & :global(.vertical-timeline-element-content-arrow) {
    /* Hide arrow */
    background-color: transparent;
    border-color: transparent;
    display: none;
  }

  & :global(.vertical-timeline-element-content) {
    background-color: revert;
    border: none;
    border-radius: revert;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
}

.icon {
  display: none;
}

.skeletonRoot {
  margin-bottom: var(--spacing-8);
}

.skeletonTitle {
  display: flex;
  justify-content: space-between;
}
