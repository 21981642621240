.container {
  flex-direction: column;

  /* Prevent extra space added to validation error */
  & p {
    margin-bottom: 0;
  }
}

.button {
  margin-left: var(--spacing-3);
}
