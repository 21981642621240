.root {
  background-color: var(--color-bg-primary);
  background-image: none;
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  box-shadow: none;
  color: var(--color-text-body);
  font-size: var(--text-sm);
  line-height: var(--leading-sm);
  margin: 0;
  max-width: 100%;
  padding: var(--spacing-2) var(--spacing-3);
  transition:
    border-color ease-in-out 0.15s,
    background-color ease-in-out 0.15s,
    background-image ease-in-out 0.15s,
    background-position ease-in-out 0.15s,
    boxshadow ease-in-out 0.15s,
    padding ease-in-out 0.15s;

  &::placeholder {
    color: var(--color-slate-500);
  }
}
