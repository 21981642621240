.disabled {
  color: var(--color-disabled);
}

.help {
  align-items: flex-end;
  display: flex;

  & > * {
    flex: 0 0 auto;

    &:first-child {
      flex: 1 0 auto;
    }
  }
}
