.root {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  grid-column-gap: var(--spacing-3);
  grid-row-gap: var(--spacing-3);

  & label {
    color: var(--color-text-body);
  }

  & input[disabled] {
    color: var(--color-text-body);
    cursor: default;
  }
}

.item {
  align-items: center;
  display: flex;
  justify-content: space-between;

  & span > {
    line-height: var(--leading-xs);
  }
}

.tags {
  padding-top: var(--spacing-2);

  & div {
    margin-left: var(--spacing-1);
  }
}
