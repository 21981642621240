.header {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-6);
}

.totalInteractions {
  align-items: baseline;
  display: flex;

  & > span {
    white-space: nowrap;
  }
}

.selectWrapper {
  align-items: flex-end;
  display: flex;
}
