.root {
  /* TODO: Figure out how to make this dynamic. https://github.com/Feathr/shrike/issues/5058 */
  --l-action-bar-height: 65px;
  --l-header-height: 109px;
  --l-padding: 48px;

  align-items: center;
  background-color: var(--color-bg-primary);
  border-radius: var(--rounded-lg);
  display: grid;
  grid-template-columns: 25% 75%;
  /* Viewport size minus the height of other vertical elements (action bar, tabs, header) */
  height: calc(100vh - var(--l-action-bar-height) - var(--l-header-height) - var(--l-padding));
  overflow-x: hidden;

  &.fullscreen {
    height: 100%;
  }
}

/* Need to override existing specificity with chained class name */
.dragOverlay.dragOverlay.dragOverlay {
  & > div {
    border: 2px solid var(--color-action);
    border-radius: var(--rounded-md);

    & button {
      background: white;
      border: none;
      cursor: grabbing;
    }
  }
}
