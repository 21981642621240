.lookback {
  /* Override the pesky flex-grow on element for > *:not(.prefix):not(.suffix) */
  flex-grow: 0 !important;
  margin: 0 var(--spacing-1);
  /* Make the minimum width a size that encompasses more options */
  min-width: 165px;
}

.number {
  --l-min-width: 65px;

  margin-right: var(--spacing-1);
  min-width: var(--l-min-width);
  width: var(--l-min-width);
}

.disabled {
  color: var(--color-text-heading);
  font-weight: var(--font-semibold);
  margin-left: var(--spacing-1); /* Only add margin to the left */
}
