:global(.ReactTable) {
  /* Allow role select dropdown to be visible */
  & .role {
    overflow: visible;
  }
}

.userEmail {
  color: var(--color-text-body);
  display: block;
  font-size: var(--text-xs);
  margin-top: var(--spacing-2);
}

.selectionCheckbox {
  margin-bottom: 0;
}
