.actions {
  display: flex;
  gap: var(--spacing-2);
  justify-content: flex-end;
}

.content {
  & > :not(:last-child) {
    margin-bottom: var(--spacing-4);
  }
}

.header {
  display: flex;
  gap: var(--spacing-2);
  justify-content: space-between;

  & h4 {
    font-size: var(--text-sm);
    line-height: var(--button-size);
    margin: 0;
  }
}

.sideBySide {
  display: flex;
  gap: var(--spacing-4);
  justify-content: space-between;

  & > * {
    flex: 1 1 50%;
  }
}

.title {
  align-items: center;
  display: flex;
  gap: var(--spacing-2);
}
