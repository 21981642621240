.label,
.span {
  color: var(--color-text-heading);
  display: inline-block;
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
  line-height: var(--leading-sm);
  margin-bottom: var(--spacing-2);
  max-width: 100%;

  &.disabled {
    color: var(--color-text-disabled);
  }

  &.normal {
    font-weight: var(--font-normal);
  }

  &.medium {
    font-weight: var(--font-medium);
  }

  &.semibold {
    font-weight: var(--font-semibold);
  }

  &.margin-standard {
    margin-bottom: var(--spacing-2);
  }

  &.margin-condensed {
    margin-bottom: var(--spacing-half);
  }
}
