.error {
  color: var(--color-danger);
  display: block;
  font-size: var(--text-xs);
  margin-bottom: var(--spacing-2);
  margin-top: var(--spacing-2);
}

.links {
  & li {
    margin-bottom: var(--spacing-2);
  }

  & p {
    margin-top: 0;
  }
}

.list {
  margin-top: 0;
}

/* TODO: This style should be removed when the chip truncation is fixed */
.chipFix > span {
  max-width: none !important;
}
