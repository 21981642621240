.answersHeader {
  margin: var(--spacing-8) 0;
}

.flexed {
  flex: 1;
}

.row {
  margin-bottom: var(--spacing-3);
}

.answer {
  align-items: flex-end;
  display: flex;
  gap: var(--spacing-2);
}

.answerInput {
  flex-grow: 1;
}
