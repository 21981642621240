.button {
  float: right;
}

.collapsedPreviewContainer {
  line-height: 38px;

  & > span {
    margin-right: var(--spacing-3);
  }

  & > svg {
    margin-right: var(--spacing-3);
  }
}

.card {
  margin-bottom: var(--spacing-2);
}

.popover {
  background-color: var(--color-bg-primary);
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  max-width: 600px;
  overflow-x: hidden;
  padding: 0;
}
