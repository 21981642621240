.root {
  text-align: center;

  & h3 {
    font-size: var(--text-base);
    margin: 0;
  }

  & :global(.mantine-Group-root) {
    margin-top: var(--spacing-4);
  }
}
