.icon {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;

  & img {
    border-radius: var(--rounded);
    flex-shrink: 0;
    max-height: 250px;
    max-width: 60%;
  }
}

.root {
  min-height: 365px;
  min-width: unset;
  text-align: center;
  vertical-align: middle;
  width: 260px;
}
