.chip {
  align-items: center;
  background-color: var(--l-background-color, var(--color-slate-300));
  border-radius: var(--rounded-md);
  color: var(--color-text-body);
  display: inline-flex;
  font-size: var(--text-xs);
  font-weight: var(--font-medium);
  height: var(--chip-height-size);
  line-height: var(--text-base);
  max-width: 100%;
  padding: var(--spacing-1) var(--spacing-2);
  white-space: nowrap;

  & :global .svg-inline--fa {
    /* Trying to match the size for icon provided by Andy with a bounding box around it */
    font-size: 11px;
  }

  & .label {
    max-width: 110px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    transition: max-width 0.3s ease-in-out;
  }

  &:hover {
    & .label {
      max-width: 100%;
    }
  }
}

.chip.dark {
  color: var(--color-text-body-inverted);

  & .label {
    color: var(--color-text-body-inverted);
  }

  & a {
    color: var(--color-text-body-inverted);
  }
}

.default {
  background-color: var(--color-slate-50);
  border: 1px solid var(--color-slate-500);
  color: var(--color-text-secondary);
}

.red {
  background-color: var(--color-red-50);
  border: 1px solid var(--color-red-500);
  color: var(--color-red-700);
}

.orange {
  background-color: var(--color-orange-50);
  border: 1px solid var(--color-orange-500);
  color: var(--color-orange-700);
}

.amber {
  background-color: var(--color-amber-50);
  border: 1px solid var(--color-amber-500);
  color: var(--color-amber-700);
}

.yellow {
  background-color: var(--color-yellow-50);
  border: 1px solid var(--color-yellow-500);
  color: var(--color-yellow-700);
}

.lime {
  background-color: var(--color-lime-50);
  border: 1px solid var(--color-lime-500);
  color: var(--color-lime-700);
}

.green {
  background-color: var(--color-green-50);
  border: 1px solid var(--color-green-500);
  color: var(--color-green-700);
}

.emerald {
  background-color: var(--color-emerald-50);
  border: 1px solid var(--color-emerald-500);
  color: var(--color-emerald-700);
}

.teal {
  background-color: var(--color-teal-50);
  border: 1px solid var(--color-teal-500);
  color: var(--color-teal-700);
}

.cyan {
  background-color: var(--color-cyan-50);
  border: 1px solid var(--color-cyan-500);
  color: var(--color-cyan-700);
}

.sky {
  background-color: var(--color-sky-50);
  border: 1px solid var(--color-sky-500);
  color: var(--color-sky-700);
}

.blue {
  background-color: var(--color-blue-50);
  border: 1px solid var(--color-blue-500);
  color: var(--color-blue-700);
}

.indigo {
  background-color: var(--color-indigo-50);
  border: 1px solid var(--color-indigo-500);
  color: var(--color-indigo-700);
}

.violet {
  background-color: var(--color-violet-50);
  border: 1px solid var(--color-violet-500);
  color: var(--color-violet-700);
}

.purple {
  background-color: var(--color-purple-50);
  border: 1px solid var(--color-purple-500);
  color: var(--color-purple-700);
}

.fuchsia {
  background-color: var(--color-fuchsia-50);
  border: 1px solid var(--color-fuchsia-500);
  color: var(--color-fuchsia-700);
}

.pink {
  background-color: var(--color-pink-50);
  border: 1px solid var(--color-pink-500);
  color: var(--color-pink-700);
}

.rose {
  background-color: var(--color-rose-50);
  border: 1px solid var(--color-rose-500);
  color: var(--color-rose-700);
}

.icon {
  margin-right: var(--spacing-1);
}

.prefix {
  margin-right: var(--spacing-1);
  position: relative;
}

.suffix {
  margin-left: var(--spacing-1);
  position: relative;
}

.skeletonLabel {
  display: inline-block;
  width: 100px;
}

.tag {
  background-color: var(--color-slate-200);
  border: 1px solid var(--color-slate-500);
  border-radius: var(--rounded-md);
  color: var(--color-slate-600);
  font-size: var(--text-xs);
  font-weight: var(--font-medium);
  line-height: var(--text-sm);
  margin: var(--spacing-half);
  /* Calculation needed here to subtract border from padding */
  padding: calc(var(--spacing-half) - var(--spacing-px))
    calc(var(--spacing-1-half) - var(--spacing-px));
}
