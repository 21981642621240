.alert {
  font-weight: normal;
  justify-content: flex-start;

  & > div {
    & > :last-child {
      margin-bottom: 0;
    }
  }
}
