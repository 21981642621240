.root {
  background-color: var(--color-bg-secondary);
  border-bottom-left-radius: var(--rounded-lg);
  border-bottom-right-radius: var(--rounded-lg);
  border-top: 1px solid var(--color-decorative-secondary);
  display: flex;
  gap: var(--spacing-2);
  justify-content: flex-end;
  padding: var(--spacing-4);
  z-index: 1;
}
