.form {
  max-width: unset;
  width: 80%;
}

.select {
  width: 50%;
}

.topMargin {
  margin-top: var(--spacing-6);
}
