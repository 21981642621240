.estimates {
  max-width: fit-content;
}

.icon {
  margin-right: var(--spacing-1);
}

.label {
  margin-right: var(--spacing-3);
}

.success {
  background-color: var(--color-success);
  color: white;
}

.sync {
  background-color: var(--color-warning);
  color: white;
}
