.wrapper {
  background: var(--color-slate-200);
  border: var(--border-decorative-secondary);
  border-radius: var(--rounded);
  display: flex;
  /* From the Figma design */
  height: 192px;
  justify-content: center;
  overflow: hidden;
  /* From the Figma design */
  width: 240px;
}

.loaded {
  padding-top: var(--spacing-6);
}

.previewWrapper {
  align-items: center;
  composes: wrapper;
  flex-shrink: 0;
}

.imageWrapper {
  composes: wrapper loaded;

  & img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}

.preview {
  border: none;
  /* Scaling messes with height needs to match design, using a relative size */
  height: 21rem;
  overflow: hidden;
  pointer-events: none;
  transform: scale(0.5);
}

/* Desktop, Tablet landscape, should be --breakpoint-lg */
@media (max-width: 960px) {
  .previewWrapper {
    width: 100%;
  }
}
