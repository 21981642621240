.root {
  position: relative;
}

.input {
  border-radius: var(--rounded-md);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
  height: 36px;
  width: 100%;
}

.icon {
  cursor: pointer;
  height: auto;
  position: absolute;
  right: 10px;
  /* Perfectly centers the icon in the FormElement container's Input element. */
  top: 37px;

  & svg {
    margin-top: 0;
  }
}
