/* stylelint-disable no-descending-specificity */
/* disabled no-descending-specificity for entire file as linter expects ".select:global(.react-select--is-disabled) :global(.react-select__control)" to come before selector ".select :global(.react-select__single-value) :global(.react-select__single-value__remove):hover" */

.select {
  min-width: 150px;

  /* Should refactor the rest of the styles into reusable component or use TagsField */
  & :global(.react-select__multi-value) {
    background-color: var(--color-slate-200);
    border: 1px solid var(--color-slate-500);
    border-radius: var(--rounded-md);
    color: var(--color-slate-600);
    font-size: var(--text-xs);
    line-height: var(--text-sm);
    max-width: calc(100% - var(--spacing-1-half));
    /* Calculation needed here to subtract border from padding */
    padding: calc(var(--spacing-half) - var(--spacing-px))
      calc(var(--spacing-1-half) - var(--spacing-px));

    & :global(.react-select__multi-value__label) {
      padding: 0;
    }

    & :global(.react-select__multi-value__remove) {
      padding: 0;

      &:hover {
        background-color: var(--color-slate-300);
        border-radius: var(--rounded-sm);
        color: var(--color-slate-600);
      }
    }
  }

  &:global(.react-select--is-disabled) {
    cursor: not-allowed;
    pointer-events: auto;

    & :global(.react-select__control) {
      background-color: var(--color-bg-secondary);
      pointer-events: none;
    }

    & :global(.react-select__multi-value) {
      color: var(--color-text-disabled);
    }
  }
}
