.icon {
  background: var(--color-bg-primary);
  color: var(--color-text-heading);
  cursor: pointer;
}

.root {
  & .label {
    color: var(--color-text-heading);
  }

  & input {
    text-align: center;
  }

  /* Removes the up/down arrows in the input */
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
