.root {
  margin: 0 auto;
  max-width: var(--content-max-width);
}

.dates {
  display: flex;
  gap: var(--spacing-2);
  justify-content: space-between;
}

.inputs {
  display: flex;
  gap: var(--spacing-4);
}

.flexGapWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

.inputWrapper {
  width: 100%;
}

.input {
  text-align: left;
}
