.label {
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data {
  display: flex;
  gap: var(--spacing-2);
  justify-content: flex-end;
  margin-right: var(--spacing-2);
}
