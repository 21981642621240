.icon {
  font-size: var(--text-base);
}

/* Give class more specificity to override more generic class */
.item.item {
  margin-bottom: var(--spacing-4);
}

.list {
  & article {
    position: relative;

    & > a {
      & > header {
        & > h3 {
          flex-shrink: 1;
          margin-right: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

.noResults {
  margin: var(--spacing-2) 0 var(--spacing-4) 0;
  text-align: center;
}

.unreadBadgeDot {
  /* Align dot appropriately on bell icon */
  right: calc(var(--spacing-3) - var(--spacing-half));
  top: var(--spacing-half);
}
