.chip {
  line-height: calc(var(--text-xs) * var(--leading-normal));
}

/* TODO: This style should be removed when the chip truncation is fixed */
/* Extra specificity required to override chip style */
.chipFix.chipFix > span {
  max-width: none;
}

.subIcon {
  bottom: calc(var(--spacing-half) * -1);
  font-size: var(--text-xs) !important;
  position: absolute;
  right: calc(var(--spacing-1-half) * -1);
}
