.toolbar {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-6);
}

.selectWrapper {
  width: 160px;
}
