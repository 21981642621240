/* stylelint-disable no-descending-specificity */
.root {
  & > label {
    display: flex;
  }
}

.buttonGroup {
  display: flex;

  & > a,
  & > button {
    border-radius: var(--rounded) var(--rounded-none) var(--rounded-none)
      var(--rounded);

    &:last-child {
      border-radius: var(--rounded-none) var(--rounded) var(--rounded)
        var(--rounded-none);
    }

    &:not(:first-child) {
      border-left: 0;

      &:not(:last-child) {
        border-radius: var(--rounded-none);
      }
    }
  }

  & > span {
    & > a,
    & > button {
      border-radius: var(--rounded) var(--rounded-none) var(--rounded-none)
        var(--rounded);
    }

    &:last-child {
      & > a,
      & > button {
        border-radius: var(--rounded-none) var(--rounded) var(--rounded)
          var(--rounded-none);
      }
    }

    &:not(:first-child) {
      & > a,
      & > button {
        border-left: 0;
      }

      &:not(:last-child) {
        & > a,
        & > button {
          border-radius: var(--rounded-none);
        }
      }
    }
  }

  & :global(.popover-container) > * {
    border-left: 0;
    border-radius: var(--rounded-none) var(--rounded) var(--rounded)
      var(--rounded-none);
  }
}
