.address {
  border-top: 1px solid var(--color-decorative-secondary);
  margin-top: var(--spacing-6);
  padding-top: var(--spacing-4);
}

.addressGroup {
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  margin-top: var(--spacing-6);
  padding: var(--spacing-3);
}

.filename {
  margin-right: var(--spacing-1);

  & pre {
    font-family: inherit;
  }
}
