.root {
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-md);
  display: flex;
  padding: var(--spacing-3);

  &.themeWhite {
    background-color: var(--color-bg-primary);
  }

  &.themeSlate {
    background-color: var(--color-bg-secondary);
  }
}

.content {
  width: 100%;
  word-wrap: break-word;
}

.label {
  margin-bottom: 0;
}

.layoutHorizontal {
  align-items: center;
  gap: var(--spacing-4);
  justify-content: space-between;
}

.layoutVertical {
  flex-direction: column;
  gap: var(--spacing-2);

  /* Increase the gap if the children are well row components */
  &:has(:global(.well-row)) {
    gap: var(--spacing-4);
  }
}

.widthNarrow {
  max-width: var(--content-max-width);
}

.widthWide {
  max-width: var(--content-extra-width);
}

.widthAuto {
  max-width: none;
}

.widthFull {
  max-width: none;
  width: 100%;
}
