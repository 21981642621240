.button {
  margin-left: var(--spacing-2);
}

.hidden {
  visibility: hidden;
}

.input {
  flex: 0.69;
}

.select {
  border: none;
  padding: 0;
  /* react-select element overrides width, so we need to force it here */
  width: unset !important;
}

.suffix {
  background-color: transparent;
  border: none;
  display: flex;
  padding: 0;
}
