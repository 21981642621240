.filter {
  max-width: 100%;
  /* Accommodate longer placeholder text. */
  width: 210px;
}

.card {
  margin-top: var(--spacing-6);
  width: var(--content-max-width);

  &:first-of-type {
    margin-top: 0;
  }

  & .description {
    margin-bottom: var(--spacing-4);
  }
}
