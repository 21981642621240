.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-bottom: var(--spacing-8);
  padding-top: var(--spacing-8);

  & > h4 {
    font-size: var(--text-sm);
    margin-bottom: 0;
    margin-top: var(--spacing-2);
  }

  & > p:last-of-type {
    margin-bottom: 0;
  }
}

.icon {
  color: var(--color-slate-400);
}
