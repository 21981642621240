.toolbar {
  background: var(--color-bg-tertiary);
}

.toolbarControls {
  border-radius: var(--rounded-lg);
}

.content {
  color: var(--color-text-heading);
}
