.button {
  border: none;
  border-radius: var(--rounded-md);
  color: var(--color-text-body);
  display: flex;
  font-size: var(--text-sm);
  font-weight: var(--font-normal);
  height: auto;
  line-height: var(--leading-sm);
  min-width: var(--context-menu-min-width);
  padding: var(--spacing-2) var(--spacing-3);
  text-align: left;
  text-decoration: none;
  white-space: normal;
  width: 100%;

  &:global(.active),
  &:active,
  &:focus,
  &:not(.disabled):hover {
    background-color: var(--color-bg-tertiary);
    color: var(--color-text-heading);
  }

  & :global(.feathr-button-prefix),
  & :global(.feathr-button-suffix) {
    align-items: center;
    display: inline-flex;
    height: var(--button-icon-size);
    justify-content: center;
    width: var(--button-icon-size);
  }

  & .text {
    display: inline-block;
    margin-right: var(--spacing-2);
  }

  &.danger {
    background: var(--color-white);
    color: var(--color-red-600);
    transition:
      background-color var(--timing-300) ease-in-out,
      color var(--timing-300) ease-in-out;

    &:global(.active),
    &:not(.disabled):active,
    &:not(.disabled):focus,
    &:not(.disabled):hover {
      background-color: var(--color-red-50);
      color: var(--color-red-600);
    }
  }

  /* Overrides the default button styles when disabled */
  &:disabled:not(:global .loading),
  &.disabled:not(:global .loading) {
    &.disabled {
      /* Instead of the normal background color when disabled, we unset it */
      background-color: unset;
      cursor: not-allowed;
      opacity: 0.5;

      &:active {
        pointer-events: none;
      }
    }

    /* Suffix */
    &.buttonHasSuffix > span:last-child {
      float: right;
    }

    & :global .fa-external-link-square {
      color: var(--color-disabled);
      font-size: var(--text-sm);
    }
  }
}

/* We don't want inline in this scenario because there's no max width on the context menu
   which makes the links render horizontally if they are wrapped in tooltips. */
.tooltip {
  display: block;
}
