.root {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-2);
  margin-bottom: var(--spacing-4);
}

.flavors {
  flex: 1 0 auto;
}

.mode {
  flex: 0 0 170px;
  max-width: 100%;
}
