.predicate {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-1);
  max-width: 100%;
  padding: var(--spacing-1);

  & > :last-child {
    flex: 1;
  }
}
