.secondaryText {
  color: var(--slate-600);
  font-size: 10px;
  font-style: italic;
}

.stackedCellContent {
  display: flex;
  flex-direction: column;
}
