.ok {
  color: var(--color-success);
}

.step {
  padding-bottom: var(--spacing-2);

  & > div {
    font-style: normal;

    & p:last-child {
      margin-bottom: 0;
    }
  }

  & pre[class*='language-'] {
    padding: var(--spacing-6);
    position: relative;
  }
}

.collapse pre[class*='language-'] {
  padding: var(--spacing-6);
  position: relative;
}

.status {
  bottom: var(--spacing-6);
  font-family: var(--font-body);
  font-size: var(--text-2xl);
  font-weight: lighter;
  line-height: var(--leading-2xl);
  margin: 0;
  position: absolute;
  right: var(--spacing-6);
  text-align: right;

  & > span {
    display: inline-block;
    padding-left: 0.5ex;

    & > * {
      display: inline-block;
      vertical-align: text-top;
    }
  }
}

.warning {
  color: var(--color-warning);
}
