.progressWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  width: 100%;
}

.reportWrapper {
  margin-bottom: var(--spacing-3);
}
