.chip {
  &:hover {
    cursor: pointer;
  }
}

.danger {
  color: var(--color-danger);
}

.picture {
  width: var(--icon-size-md);
}

.pictureButton {
  margin-left: var(--spacing-1);
}

.success {
  color: var(--color-success);
}

.truncated {
  display: inline-block;
  /* Ensure there is no extra height due to inline vs block */
  line-height: var(--leading-none);
  max-width: 100%;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}
