.root:not(:last-child) {
  margin-bottom: var(--spacing-1);
}

.button {
  border: none;
  border-radius: var(--rounded-md);
  color: var(--color-text-body);
  display: block;
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
  height: auto;
  line-height: var(--leading-sm);
  /* Width from Figma design */
  min-width: 128px;
  padding: var(--spacing-1-half) var(--spacing-3);
  text-align: left;
  text-decoration: none;
  white-space: normal;
  width: 100%;

  &:global(.active),
  &:active,
  &:focus,
  &:not(.disabled):hover {
    background-color: var(--color-sky-50);
  }

  /* Overrides the default button styles when disabled */
  &:disabled:not(:global .loading),
  &.disabled:not(:global .loading) {
    &.disabled {
      /* Instead of the normal background color when disabled, we unset it */
      background-color: unset;
      cursor: not-allowed;
      filter: alpha(opacity=5);
      opacity: 0.5;
      width: 100%;

      &:active {
        pointer-events: none;
      }
    }

    & :global .svg-inline--fa {
      width: var(--nav-icon-size);
    }

    /* Suffix */
    &.buttonHasSuffix > span:last-child {
      float: right;
    }

    & :global .fa-external-link-square {
      color: var(--color-disabled);
      font-size: var(--text-sm);
    }
  }
}
