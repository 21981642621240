.fieldset {
  max-width: var(--content-max-width);

  &:not(:last-child) {
    margin-bottom: var(--spacing-3);
  }
}

.group {
  width: 100%;
}

.radios {
  margin-top: var(--spacing-2);
}

/* Remove this when we have a better solution for the group filters issue */
.tempFix {
  max-width: none;
}
