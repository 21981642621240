.card {
  background-color: var(--color-slate-200);
  margin-bottom: var(--spacing-2);
  /* Make component match the width of the table on the page */
  width: calc(var(--content-max-width) * 1.75);
}

.sendButton {
  float: right;
  margin-top: var(--spacing-3);
}
