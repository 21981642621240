.root {
  border: 1px solid var(--color-decorative-secondary);

  &:focus-within,
  &:hover {
    box-shadow: var(--drop-shadow-md);
    transform: translate(calc(var(--spacing-px) * -1), calc(var(--spacing-px) * -1));
  }

  & > div {
    align-items: flex-end;
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: var(--spacing-2);
    padding-top: var(--spacing-3);
  }
}

.link {
  color: inherit;
  max-width: 100%;
  text-decoration: none;

  &:focus,
  &:hover {
    color: inherit;
  }
}

.header {
  align-items: center;
  display: flex;
  flex: 1 0 calc(33% - 5px);
  margin-right: 5px;
  max-width: calc(33% - 5px);

  & h3 {
    flex: 1 1 auto;
    font-size: var(--text-lg);
    font-weight: var(--font-semibold);
    line-height: inherit;
    margin: 0 0 var(--spacing-2);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.chip {
  flex: 0 0 auto;
  font-size: var(--text-sm);
  margin-bottom: 0.5rem;
}

.stats {
  flex: 2 0 calc(66% - 5px);
  margin-left: 5px;
}

.legs {
  flex: 0 0 100%;
  /* Add space to separate skeleton */
  margin-top: var(--spacing-px);
}
