.root {
  display: inline-block;
  height: auto;
  margin: 0;
  padding: 0;
}

/* TODO: This style should be removed when the chip truncation is fixed */
/* Extra specificity required to override chip style */
.chipFix.chipFix > span {
  max-width: none;
}
