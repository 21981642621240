.root {
  background: var(--color-bg-primary);
  border: none;
  border-radius: var(--rounded-lg);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 0;

  & :global(.ReactCollapse--collapse) {
    transition: height 500ms;
  }
}

.title {
  font-size: var(--text-lg);
  margin: 0;
  padding: var(--spacing-2) var(--spacing-3);
}
