.navCard {
  align-items: center;
  border: solid 1px var(--color-decorative-secondary);
  border-radius: var(--rounded);
  box-sizing: border-box;
  color: var(--color-text-body);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: var(--spacing-2-half);
  min-height: 25px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: border 200ms ease-in-out;

  &:global(.active) {
    border: solid 1px var(--color-action-hover);
  }

  &::after {
    border-radius: var(--rounded);
    box-shadow: var(--drop-shadow);
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity var(--timing-300) ease-in-out;
    width: 100%;
  }

  &:hover {
    text-decoration: none;

    &::after {
      opacity: 1;
    }
  }
}

.icon {
  align-items: center;
  color: var(--color-text-body);
  display: flex;
  filter: brightness(1.7);
  flex: 1;
  max-width: var(--icon-size-2xl);
}
