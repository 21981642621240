.root {
  align-items: center;
  display: flex;
  margin-top: var(--spacing-3);

  & div {
    flex-grow: 1;
  }

  & button {
    margin-left: var(--spacing-1);
  }
}

.deleteIcon {
  color: var(--color-danger);
  cursor: pointer;
}
