.root {
  align-items: stretch;
  display: grid;
  gap: var(--spacing-2);
  /* step size + border + 2px extra space */
  grid-template-columns: calc(var(--avatar-size-6) + 4px) 1fr;

  & .step {
    /* InlineEdit border adds 1px to top and bottom, so adjust for it */
    margin-top: var(--spacing-px);
  }

  /* Override min height from Step.css */
  & .step:not(:last-child) {
    min-height: var(--spacing-4);
  }

  &:not(:last-child) {
    & .content {
      margin-bottom: var(--spacing-4);
    }
  }

  /* Since Step assumes that Steps are its siblings, we need to override hiding vertical line after last step  */
  &:last-child {
    & .step::after {
      content: unset;
    }
  }
}
