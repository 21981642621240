.root {
  align-content: center;
  display: flex;
  gap: var(--spacing-2);
}

.label {
  margin-bottom: var(--spacing-1);
}

.description {
  color: var(--color-slate-500);
  margin-bottom: 0;
}
