.root {
  &:not(.isFullWidth) form,
  &:not(.isFullWidth) [role='form'] {
    max-width: var(--breakpoint-lg);
    width: 100%;

    & > fieldset {
      max-width: var(--content-max-width);
      width: 100%;
    }
  }
}

.steps {
  grid-area: steps;
}

.toolbar {
  grid-area: toolbar;
}

.main {
  grid-area: main;

  & .spinner {
    flex: 1 1 auto;
  }
}

.horizontal {
  display: grid;
  grid-template-areas:
    'toolbar'
    'steps'
    'main';
  grid-template-columns: minmax(0, 1fr);

  & .steps {
    margin-bottom: var(--spacing-6);
  }

  & form:first-child,
  & [role='form']:first-child {
    & > :first-child {
      margin-top: 0;
    }
  }

  & .toolbar + .steps {
    margin-top: var(--spacing-4);
  }
}

.vertical {
  display: grid;
  grid-template-areas:
    'toolbar toolbar'
    'steps main';
  grid-template-columns: 200px minmax(0, 1fr);

  & .toolbar {
    margin-bottom: var(--spacing-3);
  }
}
