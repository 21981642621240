.root {
  border-radius: var(--rounded);
  height: 66px;
  max-width: var(--thumbnail-size-lg) !important;
  text-align: right;

  & img {
    max-height: 100% !important;
    max-width: 100% !important;
  }
}

.dimensions {
  display: block;
}

.chip {
  margin-top: var(--spacing-2);
}
