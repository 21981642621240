.root {
  column-gap: var(--spacing-3);
  display: grid;
  grid-template-areas:
    'avatar name'
    'avatar email';
  grid-template-columns: var(--avatar-size-12) 1fr;
  grid-template-rows: var(--leading-2xl) auto;
  margin-top: var(--spacing-3);
}

.avatar {
  grid-area: avatar;
  padding-top: var(--spacing-half);
}

.email {
  color: var(--color-slate-500);
  grid-area: email;
}

.name {
  color: var(--color-text-heading);
  display: flex;
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
  grid-area: name;
  line-height: var(--leading-2xl);
}

.reachable {
  color: var(--color-green-600);
  margin-left: var(--spacing-1);
}
