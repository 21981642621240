.description {
  color: var(--slate-600);
  font-size: var(--text-xs);
  overflow-wrap: break-word;

  &.disabled {
    color: var(--color-slate-300);
  }
}

.select {
  padding-bottom: var(--spacing-3);
}
