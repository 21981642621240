.container {
  margin: 0 auto;
  max-width: var(--content-max-width);
}

.containerWide {
  margin: 0 auto;
  max-width: var(--content-extra-width);
}

/* Wizard */
.editor {
  /* Wizard:main */
  & > div:last-child {
    border: none;
  }
}
