.root {
  display: flex;
  flex-flow: wrap;
  gap: var(--spacing-4);

  /* TODO: stats should be equal width */

  & :global(.card-content-header) {
    width: 100%;
  }

  /* Remove top border added by CardContent */
  & + .root {
    border-top: none;
    padding-top: 0;
  }
}
