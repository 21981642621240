.root {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--spacing-2) * -1);

  /* Tabs. */
  & > ul {
    margin: 0 var(--spacing-2) var(--spacing-3);
    width: 100%;
  }
}

.alert {
  margin: var(--spacing-4) auto;
  width: calc(100% - 2 * var(--spacing-2));
}

.card {
  margin: var(--spacing-2);
  min-height: 180px;
  width: calc(33.3333% - (var(--spacing-2) * 2));
}

.cardClicked {
  cursor: wait;
}

.partnerSelect {
  margin: 0 var(--spacing-2);
  width: 100%;

  &:not(:last-child) {
    margin-bottom: var(--spacing-2);
  }
}

.title {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-3);

  & > span {
    flex: 1;
    font-size: 20px;
    font-weight: var(--font-bold);
    margin-left: var(--spacing-2);
  }
}

.tldr {
  width: calc(33.3333% - (var(--spacing-2) + var(--spacing-half)));
}
