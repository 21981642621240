.content {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: var(--spacing-6);
  overflow: hidden;
  padding: var(--spacing-4);
}

.details {
  display: grid;
  /* Matches Figma design of 72px */
  grid-column-gap: calc(var(--spacing-9) * 2);
  grid-row-gap: var(--spacing-6);
  grid-template-columns: 1fr 1fr;
}

.detail {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  justify-content: center;
}

.label {
  margin-bottom: unset;
}

.buttonWrapper {
  display: flex;
  gap: var(--spacing-3);
}

/* Desktop, Tablet landscape, should be --breakpoint-lg */
@media (max-width: 960px) {
  .content {
    flex-direction: column;
  }

  .details {
    grid-template-columns: 1fr;
    width: 100%;
  }
}
