.container {
  position: relative;
}

.cover {
  bottom: 0px;
  left: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
}

.input {
  --l-input-max-width: 100px;

  background-color: var(--color-bg-primary);
  background-image: none;
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  box-shadow: none;
  color: var(--color-text-body);
  font-size: var(--text-sm);
  margin: 0;
  max-width: var(--l-input-max-width);
  padding: var(--spacing-2) var(--spacing-3);
  transition: border-color ease-in-out 0.15s, background-color ease-in-out 0.15s,
    background-image ease-in-out 0.15s, background-position ease-in-out 0.15s,
    boxshadow ease-in-out 0.15s, padding ease-in-out 0.15s;

  &::placeholder {
    color: var(--color-slate-500);
  }
}

.popover {
  left: 60px;
  position: absolute;
  top: 60px;
  z-index: 6;
}

.prefix {
  background-color: var(--l-background-color, var(--color-bg-primary));
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-full);
  height: 11px;
  margin: var(--spacing-3) 0px;
  width: 11px;
}

.replacer {
  background-color: var(--color-bg-primary) !important;
  border-color: var(--color-decorative-secondary) !important;
  border-radius: var(--rounded-none) var(--rounded) var(--rounded)
    var(--rounded-none) !important;
  border-width: 1px 1px 1px 0 !important;
  height: 34px !important;
  padding: 5px !important;
}
