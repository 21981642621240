.formElement {
  /* Help text */
  & > div:last-child {
    margin-bottom: 0;
    margin-left: calc(var(--checkbox-size) + var(--spacing-2));
    margin-top: 0;
  }

  /* Checkbox wrapper */
  & > div:first-child {
    & > div {
      margin-bottom: 0;
    }
  }
}

/* Override checkbox styles */
.label.label {
  font-weight: var(--font-medium);
}
