.customKeywordInputWrapper {
  display: flex;
  gap: var(--spacing-2);
}

.input {
  flex: 1;
}

.inputWrapper {
  width: 50%;
}
