.disabled {
  color: var(--color-disabled);
}

.setting {
  --l-setting-background-color: var(--color-bg-primary);
  --l-setting-font-size: var(--text-sm);
  --l-setting-legend-height: calc(
    var(--l-setting-font-size) * var(--leading-normal)
  );
  --l-setting-spacing: var(--spacing-3);

  background-color: var(--l-setting-background-color);
  border: 1px solid var(--color-decorative-secondary);
  border-bottom-left-radius: var(--rounded-lg);
  border-bottom-right-radius: var(--rounded-lg);
  border-top: none;
  margin-top: calc(
    var(--l-setting-legend-height) + (var(--l-setting-spacing) * 2)
  );
  padding: var(--l-setting-spacing);
  position: relative;

  & > legend {
    background-color: var(--l-setting-background-color);
    border: 1px solid var(--color-decorative-secondary);
    border-bottom: none;
    border-top-left-radius: var(--rounded-lg);
    border-top-right-radius: var(--rounded-lg);
    box-sizing: content-box;
    font-size: var(--l-setting-font-size);
    font-weight: var(--font-bold);
    margin: 0 calc((var(--l-setting-spacing) * -1) - var(--spacing-px));
    padding: var(--l-setting-spacing) var(--l-setting-spacing);
    position: absolute;
    top: calc((var(--l-setting-legend-height) + var(--l-setting-spacing)) * -1);
    width: calc(100% - (var(--l-setting-spacing) * 2));
  }

  & fieldset {
    & legend {
      font-size: var(--text-sm);
      margin-bottom: var(--spacing-3);
    }

    & > div {
      margin-bottom: var(--spacing-4);
    }
  }
}
