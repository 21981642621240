.root {
  gap: 0;
  padding: 0;
  text-align: center;
}

.item {
  color: var(--color-slate-700);
  flex-grow: 1;
  padding: var(--spacing-3);

  &:not(:last-child) {
    border-right: 1px solid var(--color-slate-300);
  }
}

.label {
  display: block;
}
