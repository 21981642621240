.root {
  max-width: 100%;
}

.element {
  max-width: 200px;
}

.option {
  display: flex;

  & :last-child {
    flex: 1 1 auto;
  }

  & :not(:last-child):first-child {
    align-self: center;
    flex: 0 1 auto;
    margin-right: var(--spacing-3);
  }
}
