:root {
  --checkbox-size: 20px;
}

.input {
  align-items: center;
  display: flex;
  margin-bottom: var(--spacing-2);
  position: relative;

  /* Hide default checkbox */
  & input[type='checkbox'] {
    height: var(--checkbox-size);
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: var(--checkbox-size);
  }

  & .label {
    color: var(--slate-600);
    font-size: inherit;
    font-weight: inherit;
    margin-bottom: 0;
    padding-left: calc(var(--checkbox-size) + var(--spacing-2));

    &::before {
      /* Adjust the background position */
      background-position: left top;
      background-repeat: no-repeat;
      content: '';
      display: inline-block;
      height: 100%;
      left: 0;
      position: absolute;
      /* Align top of pseudo-element with the top of the container */
      top: 0;
      width: 100%;
    }

    &.hidden {
      overflow: hidden;
      white-space: nowrap;
      width: 0;

      /* Prevent focus ring that's larger than the checkbox if the label is hidden */
      &::before {
        width: var(--checkbox-size);
      }
    }

    &.placeholder {
      color: var(--color-disabled);
    }
  }

  & input[type='checkbox']:disabled,
  :not(:indeterminate) + label {
    cursor: not-allowed;
  }

  /* Unchecked state */
  & input[type='checkbox']:not(:checked, :indeterminate) + label {
    /* Empty checkbox icon */
    --l-unchecked: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIxOSIgaGVpZ2h0PSIxOSIgcng9IjMuNSIgZmlsbD0id2hpdGUiLz4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIxOSIgaGVpZ2h0PSIxOSIgcng9IjMuNSIgc3Ryb2tlPSIjQ0JENUUxIi8+Cjwvc3ZnPgo=);

    &::before {
      background-image: var(--l-unchecked);
    }
  }

  /* Disabled state */
  & input[type='checkbox']:disabled + label {
    opacity: 0.5;
  }

  /* Checked state */
  & input[type='checkbox']:checked + label {
    /* --l-checked is set in Checkbox since it can be customized */

    &::before {
      background-image: var(--l-checked);
    }
  }

  /* Focused state */
  & input[type='checkbox']:focus + label {
    /* Adding focus styles on the outer-box of the fake checkbox */
    &::before {
      outline: -webkit-focus-ring-color auto 5px !important;
      outline-offset: 2px;
    }
  }

  /* Indeterminate state */
  & input[type='checkbox']:indeterminate + label {
    /* Dash icon checkbox (mixed state) */
    --l-indeterminate: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMSIgeT0iMC41IiB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHJ4PSIzLjUiIHN0cm9rZT0iI0NCRDVFMSIvPgo8cGF0aCBkPSJNMTYuMTg1IDEwLjAwNjNDMTYuMTg1IDEwLjM4OTEgMTUuODg0MiAxMC42NjI1IDE1LjUyODcgMTAuNjYyNUg1LjQ2NjI1QzUuMDgzNDQgMTAuNjYyNSA0LjgxIDEwLjM4OTEgNC44MSAxMC4wMDYzQzQuODEgOS42NTA3OSA1LjA4MzQ0IDkuMzUwMDEgNS40NjYyNSA5LjM1MDAxSDE1LjUyODdDMTUuODg0MiA5LjM1MDAxIDE2LjE4NSA5LjY1MDc5IDE2LjE4NSAxMC4wMDYzWiIgZmlsbD0iIzBGMTcyQSIvPgo8L3N2Zz4K);

    &::before {
      background-image: var(--l-indeterminate);
      /* Redundant style is required here */
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  & input[type='checkbox']:not(:disabled) + label {
    cursor: pointer;
  }

  /* Checked and disabled state */
  & input[type='checkbox']:disabled:checked + label {
    /* Disabled and checked checkbox icon */
    --l-checked-disabled: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHJ4PSI0IiBmaWxsPSIjRTJFOEYwIi8+CjxwYXRoIGQ9Ik0xNS44MzA3IDYuNjgxNDRDMTYuMTQ2NyA2Ljk3MzEgMTYuMTQ2NyA3LjQ4MzUyIDE1LjgzMDcgNy43NzUxOUw5LjYwODUgMTMuOTk3NEM5LjMxNjgzIDE0LjMxMzQgOC44MDY0MSAxNC4zMTM0IDguNTE0NzUgMTMuOTk3NEw1LjQwMzY0IDEwLjg4NjNDNS4wODc2NiAxMC41OTQ2IDUuMDg3NjYgMTAuMDg0MiA1LjQwMzY0IDkuNzkyNTVDNS42OTUzIDkuNDc2NTggNi4yMDU3MiA5LjQ3NjU4IDYuNDk3MzkgOS43OTI1NUw5LjA3Mzc3IDEyLjM0NDZMMTQuNzM3IDYuNjgxNDRDMTUuMDI4NiA2LjM2NTQ2IDE1LjUzOTEgNi4zNjU0NiAxNS44MzA3IDYuNjgxNDRaIiBmaWxsPSIjMUUyOTNCIi8+Cjwvc3ZnPgo=);

    &::before {
      background-image: var(--l-checked-disabled);
    }
  }
}

.well {
  color: var(--color-text-heading);
  display: block;
  font-weight: var(--font-medium);

  & div {
    margin-bottom: 0;
  }
}

.required {
  font-weight: normal;
  margin-left: var(--spacing-1);
}
