.person {
  align-items: center;
  display: flex;
}

.tag {
  background-color: var(--color-slate-300);
  border-radius: var(--rounded-sm);
  color: var(--color-text-body);
  font-size: 85%;
  margin: var(--spacing-half);
  padding: 3px var(--spacing-1-half);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cell {
  align-items: center;
  display: flex;
  height: 100%;
}

.personCell {
  display: flex;
  flex-direction: column;
}

.companiesCell {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
