.media {
  align-content: center;
  display: flex;
  justify-content: center;
  max-height: var(--l-height, var(--l-size, auto));
  max-width: var(--l-width, var(--l-size, auto));
  order: var(--l-order);
  position: relative;

  & > img, & > svg {
    flex: 0 1 auto;
    margin: unset !important;
    object-fit: contain;
    overflow: hidden;
  }

  & button {
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s ease;
  }

  & .mask {
    background-color: rgba(0,0,0,0.8);
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.2s ease;
    width: 100%;
  }
  
  &:hover {
    & .mask,
    & button {
      opacity: 1;
    }
  }
}

.postscribe {
  height: var(--l-size, auto);
  /* transform scale will mess up centering, so we have to calculate ourselves
   * var(--l-width) / 2 gives the mid-point of the box the creative needs to fit into
   * var(--l-real-width) * var(--l-scale) gives the width of the creative after the computed scale factor
   * mid-point - half the scaled width of the creative gives the correct margin-left
   * */
  margin-left: calc(var(--l-width, 0) / 2 - ((var(--l-real-width, 0) * var(--l-scale, 1)) / 2));
  min-height: 20px;
  min-width: 20px;
  padding: 0;
  transform: scale(var(--l-scale, 1));
  transform-origin: top left;
  width: var(--l-size, auto);
}

.creativeError {
  height: var(--l-height, auto); 
  width: var(--l-width, auto);
}

.warning {
  color: var(--color-warning);
}

.wrapper {
  overflow: hidden;
  pointer-events: none;
}

