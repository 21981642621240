.button {
  max-width: fit-content;
}

.buttonWrapper {
  max-width: var(--content-max-width);
  width: 100%;
}

.copyButton {
  --l-button-width: 256px;

  min-width: var(--l-button-width);

  &:disabled:not(:global .loading),
  &.disabled:not(:global .loading) {
    width: var(--l-button-width);
  }
}
