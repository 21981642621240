.root {
  align-items: center;
  background-color: var(--l-icon-background-color);
  border-radius: var(--rounded-full);
  display: flex;
  height: var(--l-circle-size);
  justify-content: center;
  line-height: var(--l-circle-size);
  text-align: center;
  width: var(--l-circle-size);

  & :global .svg-inline--fa {
    color: var(--color-text-body-inverted);
    vertical-align: middle;
  }
}

.hoverable {
  &:hover {
    border: 3px solid var(--color-text-body-inverted);
  }
}

.draft {
  background-color: var(--color-slate-400);
}
