.icon {
  margin-right: var(--spacing-3);
}

.root {
  align-items: center;
  border-radius: var(--rounded);
  border-style: solid;
  border-width: 1px;
  display: flex;
  font-weight: var(--font-semibold);
  justify-content: center;
  padding: var(--spacing-3) var(--spacing-4);

  &:not(:last-child) {
    margin-bottom: var(--spacing-4);
  }
}

.info {
  background-color: var(--color-sky-50);
  border-color: var(--color-primary-3);
  composes: root;

  & .icon {
    color: var(--color-primary-3);
  }
}

.danger {
  background-color: var(--color-red-50);
  border-color: var(--color-danger);
  composes: root;

  & .icon {
    color: var(--color-danger);
  }
}

.warning {
  background-color: var(--color-orange-50);
  border-color: var(--color-secondary-3);
  composes: root;

  & .icon {
    color: var(--color-secondary-3);
  }
}

.success {
  background-color: var(--color-green-50);
  border-color: var(--color-success);
  composes: root;

  & .icon {
    color: var(--color-success);
  }
}
