.statBox {
  border-bottom: 1px solid var(--color-decorative-secondary);
  border-right: 1px solid var(--color-decorative-secondary);
  flex-direction: column;
  margin: calc(var(--spacing-px) * -1);
  padding: var(--spacing-2) var(--spacing-3);

  &.vertical {
    border-bottom: 1px solid var(--color-decorative-secondary);
    border-right: none;

    &:last-child {
      border-bottom: none;
    }
  }

  &:last-child {
    border-bottom: none;
    border-right: none;
  }

  & > .box {
    align-items: flex-end;
    background: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: var(--spacing-4);
    position: relative;
    width: 100%;

    & > label {
      display: block;
      font-size: var(--text-xs);
      font-weight: var(--font-semibold);
      left: var(--spacing-2-half);
      line-height: var(--leading-xs);
      position: absolute;
      text-align: center;
      top: var(--spacing-2-half);
      width: calc(100% - 20px);
    }

    & > .primaryStat {
      color: var(--color-text-heading);
      height: 70px;
      text-align: center;
      width: 100%;

      &.lg {
        font-size: var(--text-6xl);
        font-weight: var(--font-bold);
        line-height: var(--leading-normal);
      }

      &.md {
        font-size: var(--text-3xl);
        font-weight: var(--font-semibold);
        line-height: 6rem;
      }

      &.sm {
        font-size: var(--text-lg);
        font-weight: var(--font-semibold);
        line-height: 6rem;
      }
    }

    & > .secondaryStatWrapper {
      color: var(--slate-600);
      font-size: var(--text-sm);
      font-weight: var(--font-normal);
      margin-top: var(--spacing-2);
      /* This is so that it always pushes the primary stat up even if it is absent - keeps primary stats aligned */
      min-height: calc(var(--leading-normal) * 2em);
      text-align: center;
      width: 100%;

      & > label {
        display: block;
      }
    }

    & > .flagged {
      background: repeating-linear-gradient(
        45deg,
        gold,
        gold 0.1em,
        transparent 0,
        transparent 0.7em
      );
    }

    & > .content {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: var(--spacing-2);
      width: 100%;
    }
  }

  &.size0 {
    /* 120px  */
    flex-basis: calc(var(--spacing-6) * 5);
    flex-grow: 1;
    flex-shrink: 1;

    & > .box {
      padding: var(--spacing-3) var(--spacing-1);
    }
  }

  &.size1 {
    /* 160px; */
    flex-basis: calc(var(--spacing-4) * 10);
    flex-grow: 1;
    flex-shrink: 1;
  }

  &.size2 {
    /* 240px for usage metrics */
    flex-basis: calc(var(--spacing-6) * 10);
    flex-grow: 1;
    flex-shrink: 1;
  }

  &.size3 {
    /* 320px */
    flex-basis: calc(var(--spacing-4) * 20);
    flex-grow: 2;
    flex-shrink: 2;
  }

  &.size4 {
    /* 480px */
    flex-basis: calc(var(--spacing-6) * 20);
    flex-grow: 3;
    flex-shrink: 3;
  }
}
