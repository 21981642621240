.warning {
  color: var(--color-warning);
}

.description {
  color: var(--slate-600);
  font-size: var(--text-xs);
}

.description p {
  overflow-wrap: 'break-word';
}
