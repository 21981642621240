.thumbnailImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 120px;
  margin-bottom: var(--spacing-2);
  width: 100%;
}

.thumbnailImageOption {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid transparent;
  height: 200px;
  width: 100%;

  &:hover {
    cursor: pointer;
    outline: 1px solid var(--color-primary-3);
  }
}

.thumbnailImageSelected {
  cursor: pointer;
  outline: 5px solid var(--color-primary-3);
}

.thumbnailGrid {
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: var(--spacing-2-half);
}

.thumbnailPreview {
  display: flex;
  flex-direction: column;
}

.thumbnailWrapper {
  position: relative;

  & button {
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s ease;
  }

  & .mask {
    background-color: rgba(0, 0, 0, 0.8);
    height: calc(100% - var(--spacing-2));
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.2s ease;
    width: 100%;
  }

  &:hover {
    & .mask,
    & button {
      opacity: 1;
    }
  }
}
