.icon {
  background-color: var(--l-icon-background-color);
  border: 2px solid var(--color-text-body-inverted);
  border-radius: var(--rounded-full);
  display: inline-block;
  height: calc(var(--l-icon-size) * 1.2);
  line-height: calc((var(--l-icon-size) * 1.1) - var(--spacing-1));
  text-align: center;
  width: calc(var(--l-icon-size) * 1.2);

  & :global .svg-inline--fa {
    color: var(--color-text-body-inverted);
    vertical-align: middle;
  }
}
