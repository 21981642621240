.content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  margin-bottom: var(--spacing-1);
}

.content > .column {
  flex: 1 1 50%;
}
