.campaignCard {
  --l-margin: var(--spacing-px);
  --l-max-width: 230px;

  /* Add 1px margin left and top to prevent the border getting cut off on hover */
  margin: var(--l-margin) 0 var(--spacing-3) var(--l-margin);
  /* Subtract the margin from the max-width; */
  max-width: calc(var(--l-max-width) - var(--l-margin));
  width: 100%;

  &:not(:last-child) {
    margin-bottom: var(--spacing-3);
  }

  & h3 {
    font-size: var(--text-lg);
    line-height: var(--leading-lg);
  }
}

.icon {
  cursor: help;
}
