.root {
  display: flex;
  justify-content: center;
}

.imgPreview {
  display: inline-block;
}

.imgPreview > a {
  font-size: 0;
  text-decoration: none;
}

.imgPreview > a > img {
  cursor: pointer;
}

.vidPreview {
  cursor: pointer;
  position: relative;
}

.vidPreviewClickZone {
  /* calculated height to uncover video controls */
  height: calc(100% - 100px);
  position: absolute;
  width: 100%;
  z-index: 2;
}

.vidPreview > video {
  width: 100%;
  z-index: 1;
}
