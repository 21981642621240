.root {
  background-color: var(--color-bg-secondary);
  position: relative;
  width: 100%;

  & .editor {
    border: 1px solid var(--color-decorative-secondary);
    height: calc(100vh - 300px);
    min-height: 700px;
    vertical-align: bottom;
    width: 100%;
  }
}

.root:fullscreen {
  padding: var(--spacing-4);

  & .editor {
    border: 1px solid var(--color-decorative-secondary);
    height: 96%;
  }
}

.label {
  display: block;
  font-weight: var(--font-bold);
  width: 100%;
}

.loading {
  border: 1px solid var(--color-decorative-secondary);
  padding: var(--spacing-6);
}

.toolbar {
  border: 1px solid var(--color-decorative-secondary);
  border-bottom: none;
  display: flex;
  flex-wrap: wrap;
  padding: var(--spacing-3);

  & > * {
    display: flex;
    flex: 1 1 auto;
  }

  & > .toolbarLeft {
    align-items: center;

    & > * {
      margin-right: var(--spacing-2);
    }
  }

  & > .toolbarRight {
    justify-content: flex-end;

    & > * {
      margin-left: var(--spacing-2);
    }
  }
}

/* We do NOT want a border or height based on viewport height in the fullscreen modal */
:global(.mantine-Modal-root[data-full-screen='true']) .root {
  &.editor {
    border: none;
    /* This requires the parent to have a height set (either through height or flex) */
    height: 100%;
    min-height: unset;
  }
}

@media (min-width: 1410px) {
  .root {
    margin-left: 50%;
    transform: translateX(-50%);
    /* 100 view width - navigation bar - margins - border */
    width: calc(100vw - 240px - (var(--spacing-3) * 5) - 2px);
  }

  /* We do NOT want to resize the editor in the fullscreen modal, so override
     everything we just did ;-) */
  :global(.mantine-Modal-root[data-full-screen='true']) .root {
    margin-left: inherit;
    transform: inherit;
    width: inherit;
  }
}
