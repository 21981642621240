.card {
  background-color: var(--color-bg-primary);
  border-radius: var(--rounded-lg);
  box-shadow: var(--drop-shadow-md);
  padding: var(--spacing-4);
  position: relative;
  transition: all 0.3s ease;
}

.fBPreview {
  height: 100%;
  position: absolute;
  width: 100%;
}
