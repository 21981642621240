.horizontal {
  & > section {
    display: flex;
    justify-content: space-between;
    position: relative;

    &::before {
      background-color: var(--color-decorative-secondary);
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      top: var(--spacing-4);
      width: 100%;
    }
  }
}

.vertical {
  /* Placeholder which is needed because layout is added as style  */
}
