.root {
  min-height: 25px;
  position: relative;
  text-align: left;
  width: 100%;
}

.content {
  display: flex;
  justify-content: space-between;

  & > .labelContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > .label {
      margin-bottom: 0;
    }

    /* Only add padding if a tooltip icon is present. */
    & > .label:not(:last-child) {
      padding-right: var(--spacing-1);
    }
  }
}
