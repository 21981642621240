@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

.root {
  display: block;
  position: relative;
  text-align: center;
}

.spinner {
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-name: rotator;
  animation-timing-function: linear;
  position: relative;
  top: calc(50% - (var(--l-spinner-size, 60px) / 2));
  vertical-align: middle;
}

.path {
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-name: dash;
  animation-timing-function: ease-in-out;
  stroke: var(--color-decorative-secondary);
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }

  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}
