.description {
  color: var(--slate-600);
  font-size: var(--text-xs);
  overflow-wrap: break-word;

  &.disabled {
    color: var(--color-slate-300);
  }
}

.selectContainer {
  display: flex;
  gap: var(--spacing-4);
}

.selectContainer > * {
  flex: 1;
}
