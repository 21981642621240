.root {
  &form,
  &[role='form'] {
    max-width: none;

    & > fieldset {
      max-width: var(--content-max-width);
    }
  }
}

.horizontal {
  margin: 0 auto var(--spacing-6);
}

.toolbar {
  margin-top: var(--spacing-4);
}

.upload {
  margin-bottom: var(--spacing-3);
}

.vertical {
  float: left;
  margin: 0 var(--spacing-4) var(--spacing-6) 0;
  max-width: 200px;
  width: auto !important;

  & > img {
    max-height: none !important;
    max-width: 100% !important;
  }
}

.content {
  padding: var(--spacing-6) 0;
}

.radios {
  margin-top: var(--spacing-1);
}
