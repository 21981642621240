:global #react-root {
  background-color: var(--color-bg-primary);
  display: flex;
  flex-direction: column;
  font-family: var(--font-body);
  height: 100vh;
  overflow: hidden;
  width: 100vw;
}

:global .public-DraftStyleDefault-ltr {
  text-align: inherit;
}

.spinner {
  height: 100%;
}

@media print {
  html,
  body {
    display: block;
    height: unset;
    overflow: visible;
  }

  :global #react-root {
    display: block;
    height: unset;
    overflow: visible;
  }
}
