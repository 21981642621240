/* Adds Lato, Montserrat, and Roboto fonts which are used for Forms */
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Roboto&display=swap');

/* Removes the extra vertical space imposed by Page */
.legacyScroll {
  padding-bottom: 0px;
}

.deleteButton {
  color: var(--color-danger);
}

.leftActions {
  display: flex;
}

.lastPublished {
  margin-left: var(--spacing-1);
}

.chips {
  display: flex;
  gap: var(--spacing-2);
}
