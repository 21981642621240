.root {
  & :global(.react-select__single-value) {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: var(--spacing-2);
    justify-content: space-between;
    width: 100%;
  }

  /* Make any chips in the select option also look disabled */
  & :global(.react-select__single-value--is-disabled) div * {
    opacity: 80%;
  }
}
