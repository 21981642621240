.fieldset {
  & div {
    align-items: baseline;
    display: flex;
    flex: 1 1 0;
  }
}

.label.label {
  margin-bottom: var(--spacing-2);
}
