.root {
  & .chartGroupMember:last-of-type {
    /* Prevent extra padding on the bottom of the Card. */
    padding-bottom: 0;
  }
}

.row {
  border-top: var(--color-decorative-secondary) 1px solid;
  display: flex;
  gap: var(--spacing-4);
  padding: var(--spacing-6) 0;

  &.noSeparator {
    border-top: none;
  }
}

.chart {
  --l-height-small: 174px;
  --l-width-small: 272px;
  --l-height-medium: 174px;
  --l-width-medium: 440px;
  --l-height-large: 364px;

  width: 100%;

  &.small {
    height: var(--l-height-small);
    width: var(--l-width-small);
  }

  &.medium {
    height: var(--l-height-medium);
    width: var(--l-width-medium);
  }

  &.large {
    height: var(--l-height-large);
  }
}

.middle {
  border-left: var(--color-decorative-secondary) 1px solid;
  border-right: var(--color-decorative-secondary) 1px solid;
  padding: 0 var(--spacing-4);
}

.left {
  border-right: var(--color-decorative-secondary) 1px solid;
  /* Not a perfect solution to even out the space between charts. */
  padding-right: var(--spacing-4);
}
