.addressOne {
  margin-bottom: var(--spacing-2);
}

.inline {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-4);
  margin-top: var(--spacing-4);

  & > *:not(:first-child) {
    margin-left: var(--spacing-3);
  }
}

.city {
  /* Desired width + element padding */
  min-width: calc(10em + var(--spacing-3) * 2);
}

.cityWrapper {
  flex: 1 0 auto;
}

.state {
  /* Desired width + element padding */
  width: calc(2em + var(--spacing-3) * 2);
}

.postalCode {
  /* Desired width + element padding */
  width: calc(5em + var(--spacing-3) * 2);
}
