/* stylelint-disable no-descending-specificity */
.root {
  --l-leg-width: 256px;

  border: 1px solid var(--color-decorative-secondary);
  height: calc(100% - (var(--spacing-3) / 2));
  /* Accommodate scrollbar when there are too many legs; padding is reduced on flight list item */
  margin-bottom: calc(var(--spacing-3) / 2);
  margin-right: var(--spacing-3);
  /* Fit 4 legs & plus button at full-screen width */
  max-width: var(--l-leg-width);
  min-height: 120px;
  min-width: var(--l-leg-width);

  &:last-child {
    margin-right: 0;
  }

  & :global(.smooth-dnd-stretcher-element.vertical) {
    /* Bug with stretcher element not having same height as dragged element.
       Add some space to prevent oddities with long-title campaigns and bottom
       of leg overlap. */
    margin-bottom: calc(var(--spacing-3) * 3);
  }
}

.condensed {
  background-color: var(--color-bg-primary);
}

.content {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;

  & :global(.smooth-dnd-container) {
    flex: 1 0 auto;
  }
}

.drag {
  transform: rotate(-4deg);
}

.header {
  /* Icon Button has small icon and some padding */
  --line-height: calc(var(--icon-size-sm) + 5px + 5px);

  display: flex;

  & > :first-child {
    flex: 1 1 auto;
  }

  & input {
    font-size: var(--text-sm);
    font-weight: var(--font-semibold);
    height: var(--line-height);
    margin-bottom: var(--spacing-3);
    margin-top: var(--spacing-2);
    max-width: 100%;

    &:active,
    &:focus,
    &:hover {
      background-color: var(--color-bg-primary);
    }
  }

  & h3 {
    font-size: var(--text-sm);
    margin-bottom: var(--spacing-3);
    margin-top: var(--spacing-3);
    padding-left: var(--spacing-1);
  }
}

.actions {
  display: flex;
  /* Legacy margin value; afraid to touch it. */
  margin-bottom: 5px;
}

.button {
  margin-right: var(--spacing-1);
}

.placeholder {
  border: 2px dashed var(--color-decorative-secondary);
  border-radius: var(--rounded);
  margin-bottom: var(--spacing-3);
}
