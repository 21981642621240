.button {
  background: var(--color-bg-primary);
  border: 1px solid var(--color-decorative-secondary);
  color: var(--color-text-heading);
  display: flex;
  font-weight: var(--font-normal);
  gap: var(--spacing-2);
  overflow: hidden;
  padding: var(--spacing-2) var(--spacing-3);
  text-overflow: ellipsis;

  &:global(.active),
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    background-color: var(--color-bg-tertiary);
    cursor: copy;
  }
}

.secret {
  -webkit-text-security: disc;
}
