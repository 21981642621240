.thumbnail {
  height: 130px;

  & img {
    background-color: var(--color-bg-secondary);
    border: 1px solid var(--color-decorative-secondary);
    border-radius: var(--rounded);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: block;
    height: auto;
    margin: auto;
    /* Arbitrary value based on column width */
    max-height: 100px;
    /* Column width */
    max-width: 220px;
    transition: all 0.2s ease-in-out;
  }
}
/* Needed for specificity */
:global(.ReactTable) {
  & :global(.rt-td) {
    &.thumbnail {
      padding: var(--spacing-3) var(--spacing-2);
    }
  }
}
