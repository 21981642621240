.root {
  background:
    linear-gradient(180deg, rgba(43, 42, 50, 0) 0%, #2b2a32 100%),
    var(--l-bg-image) lightgray 50% / cover no-repeat;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  height: 100%;
  justify-content: flex-end;
  padding: 0 var(--spacing-10) var(--spacing-9) var(--spacing-10);
}

.details {
  margin-bottom: var(--spacing-4);
}

.content {
  color: var(--color-white);
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--color-white);
  font-family: 'Merriweather', serif;
  font-size: var(--text-3xl);
  font-weight: var(--font-bold);
  line-height: var(--leading-3xl);
  margin-bottom: var(--spacing-3);
  margin-top: 0;
}

.organization {
  align-items: center;
  display: flex;
  gap: var(--spacing-3);
}
