/* stylelint-disable no-descending-specificity */
.root {
  cursor: pointer;

  & :global(.react-select__menu) {
    /* Same as Popover and one above Modal */
    z-index: 1070;
  }

  & :global(.react-select__menu-list) {
    padding: var(--spacing-1-half);
  }

  & :global(.react-select__control--is-focused) {
    border-color: transparent;
    box-shadow: none;

    /* Support non-webkit browsers */
    outline: Highlight auto 5px !important;

    /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
    outline: -webkit-focus-ring-color auto 5px !important;
  }

  & :global(.react-select__group) {
    padding-bottom: 0;
    padding-top: 0;

    &:not(:last-child) {
      margin-bottom: var(--spacing-1);
    }
  }

  & :global(.react-select__group-heading) {
    color: var(--color-slate-500);
    font-size: var(--text-xs);
    font-weight: var(--font-semibold);
    line-height: var(--leading-xs);
    margin-bottom: var(--spacing-1);
    padding: var(--spacing-2);
  }

  & :global(.react-select__option) {
    border: 1px solid transparent;
    border-radius: var(--rounded-sm);
    cursor: pointer;
    padding: var(--spacing-2);

    &:not(:last-child) {
      margin-bottom: var(--spacing-1);
    }
  }

  & :global(.react-select__option--is-disabled) {
    background-color: var(--color-bg-disabled);
    color: var(--color-text-disabled);
    cursor: not-allowed;
  }

  & :global(.react-select__option--is-focused) {
    background-color: var(--color-slate-100);

    &:active {
      background-color: var(--color-slate-200);
    }
  }

  & :global(.react-select__option--is-selected) {
    background-color: var(--color-sky-100);
    border: 1px solid var(--color-sky-700);
    color: var(--color-sky-700);

    /* ensures that the color of all child elements of a selected option are legible */
    & * {
      color: var(--color-sky-700);
    }

    &:active {
      background-color: var(--color-sky-100);
    }
  }

  /* Should refactor lines 15-39 styles into reusable component or use TagsField */
  & :global(.react-select__multi-value) {
    background-color: var(--color-slate-200);
    border: 1px solid var(--color-slate-500);
    border-radius: var(--rounded-md);
    color: var(--color-slate-600);
    font-size: var(--text-xs);
    line-height: var(--text-sm);
    /* Calculation needed here to subtract border from padding */
    padding: calc(var(--spacing-half) - var(--spacing-px))
      calc(var(--spacing-1-half) - var(--spacing-px));
  }

  & :global(.react-select__multi-value__label) {
    padding: 0;
    text-wrap: auto;
  }

  & :global(.react-select__multi-value__remove) {
    padding: 0;

    &:hover {
      background-color: var(--color-slate-300);
      border-radius: var(--rounded-sm);
      color: var(--color-slate-600);
    }
  }

  & :global(.react-select__control) {
    border-color: var(--color-decorative-secondary);
    cursor: pointer;

    & :global(.react-select__indicator-separator) {
      background-color: var(--color-decorative-secondary);
      margin-bottom: 0;
      margin-top: 0;
    }

    & :global(.react-select__dropdown-indicator) {
      color: var(--color-slate-500);
      font-size: var(--text-lg);
      padding: 0 var(--spacing-3);
    }

    &:hover {
      border-color: var(--color-decorative-secondary);
    }
  }

  &:global(.react-select--error) {
    & :global(.react-select__control) {
      border-color: var(--color-danger);
    }

    & :global(.react-select__placeholder),
    & :global(.react-select__dropdown-indicator) {
      color: var(--color-danger);
    }

    & :global(.react-select__indicator-separator) {
      background-color: var(--color-danger);
    }
  }

  &:global(.react-select--is-disabled) {
    cursor: not-allowed;

    /* Placeholder container */
    & :global(.react-select__placeholder) {
      color: var(--color-text-disabled);
    }

    /* Select container */
    & :global(.css-1fhf3k1-control) {
      background-color: var(--color-bg-disabled);
    }

    /* Multi value select label class */
    & :global(.css-12jo7m5) {
      color: var(--color-text-disabled);
    }

    /* Multi value container */
    & :global(.react-select__multi-value) {
      border-color: var(--color-decorative-secondary);
      color: var(--color-text-disabled);
    }

    & :global(.react-select__control) {
      cursor: not-allowed;
    }

    & :global(.react-select__dropdown-indicator) {
      color: var(--color-disabled);

      &:hover {
        color: var(--color-disabled);
      }
    }
  }
}

.disabled {
  cursor: not-allowed;
}

.readOnly {
  color: var(--color-slate-500);
  font-weight: var(--font-normal);
}
