.select {
  & :global(.react-select__single-value),
  & :global(.react-select__multi-value) {
    max-width: calc(100% - var(--spacing-1-half));
  }

  &:global(.react-select--is-disabled) {
    cursor: not-allowed;
    pointer-events: auto;

    & :global(.react-select__control) {
      background-color: var(--color-bg-secondary);
      pointer-events: none;
    }

    & :global(.react-select__single-value),
    & :global(.react-select__multi-value) {
      color: var(--color-text-disabled);
    }
  }
}

.comparisonSelect {
  /* Make the minimum width a size that encompasses more options */
  min-width: 170px;
}

.dateTimeTypeSelect {
  /* Make the minimum width a size that encompasses more options */
  min-width: 200px;
}

.comparison {
  white-space: nowrap;
}
