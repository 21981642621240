:root {
  --popover-arrow-size: 6px;
}

.arrow {
  position: absolute;

  &::after {
    color: transparent;
    content: '';
    display: block;
    position: absolute;
  }

  &[data-placement^='bottom'] {
    border-bottom: var(--popover-arrow-size) solid
      var(--color-decorative-secondary);
    border-left: var(--popover-arrow-size) solid transparent;
    border-right: var(--popover-arrow-size) solid transparent;
    top: calc((var(--popover-arrow-size) * -1) - var(--spacing-px));

    &::after {
      border-bottom: var(--popover-arrow-size) solid var(--color-bg-primary);
      border-left-style: solid;
      border-left-width: var(--popover-arrow-size);
      border-right: var(--popover-arrow-size) solid transparent;
      border-top-style: solid;
      border-top-width: var(--popover-arrow-size);
      left: calc(var(--popover-arrow-size) * -1);
      top: calc((var(--popover-arrow-size) * -1) + var(--spacing-px));
    }
  }

  &[data-placement^='left'] {
    border-bottom: var(--popover-arrow-size) solid transparent;
    border-left: var(--popover-arrow-size) solid
      var(--color-decorative-secondary);
    border-top: var(--popover-arrow-size) solid transparent;
    right: calc((var(--popover-arrow-size) * -1) - var(--spacing-px));

    &::after {
      border-bottom: var(--popover-arrow-size) solid transparent;
      border-left: var(--popover-arrow-size) solid var(--color-bg-primary);
      border-right-style: solid;
      border-right-width: var(--popover-arrow-size);
      border-top-style: solid;
      border-top-width: var(--popover-arrow-size);
      bottom: calc(var(--popover-arrow-size) * -1);
      right: calc((var(--popover-arrow-size) * -1) + var(--spacing-px));
    }
  }

  &[data-placement^='right'] {
    border-bottom: var(--popover-arrow-size) solid transparent;
    border-right: var(--popover-arrow-size) solid
      var(--color-decorative-secondary);
    border-top: var(--popover-arrow-size) solid transparent;
    left: calc((var(--popover-arrow-size) * -1) - var(--spacing-px));

    &::after {
      border-bottom-style: solid;
      border-bottom-width: var(--popover-arrow-size);
      border-left-style: solid;
      border-left-width: var(--popover-arrow-size);
      border-right: var(--popover-arrow-size) solid var(--color-bg-primary);
      border-top: var(--popover-arrow-size) solid transparent;
      bottom: calc(var(--popover-arrow-size) * -1);
      left: calc((var(--popover-arrow-size) * -1) + var(--spacing-px));
    }
  }

  &[data-placement^='top'] {
    border-left: var(--popover-arrow-size) solid transparent;
    border-right: var(--popover-arrow-size) solid transparent;
    border-top: var(--popover-arrow-size) solid
      var(--color-decorative-secondary);
    bottom: calc((var(--popover-arrow-size) * -1) - var(--spacing-px));

    &::after {
      border-bottom-style: solid;
      border-bottom-width: var(--popover-arrow-size);
      border-left-style: solid;
      border-left-width: var(--popover-arrow-size);
      border-right: var(--popover-arrow-size) solid transparent;
      border-top: var(--popover-arrow-size) solid var(--color-bg-primary);
      bottom: calc((var(--popover-arrow-size) * -1) + var(--spacing-px));
      left: calc(var(--popover-arrow-size) * -1);
    }
  }
}

.popper {
  background-color: var(--color-bg-primary);
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-lg);
  box-shadow: var(--drop-shadow-md);
  /* Popper is run in a portal, so loses all #react-root style */
  font-family: var(--font-body);
  max-width: 300px;
  padding: var(--spacing-3);
  z-index: 1070;

  &[data-placement^='bottom'] {
    margin-top: var(--spacing-1);
  }

  &[data-placement^='left'] {
    margin-right: var(--spacing-1);
  }

  &[data-placement^='right'] {
    margin-left: var(--spacing-1);
  }

  &[data-placement^='top'] {
    margin-bottom: var(--spacing-1);
  }

  &.hasArrow {
    &[data-placement^='bottom'] {
      margin-top: var(--spacing-3);
    }

    &[data-placement^='left'] {
      margin-right: var(--spacing-3);
    }

    &[data-placement^='right'] {
      margin-left: var(--spacing-3);
    }

    &[data-placement^='top'] {
      margin-bottom: var(--spacing-3);
    }
  }
}

span.trigger {
  display: block;
}
