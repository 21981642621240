.root {
  align-items: center;
  display: flex;
  gap: var(--spacing-2);

  &:not(:last-of-type) {
    margin-bottom: var(--spacing-4);
  }

  & div {
    flex-grow: 1;
  }
}
