.description {
  overflow-wrap: break-word;
}

.path {
  color: var(--slate-600);
  font-size: var(--text-xs);
}

.single {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
