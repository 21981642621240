.accountManager {
  background-color: transparent;
  border: none;
  padding: 0;
}

.favorite {
  color: var(--color-text-body);
  cursor: pointer;
  /* Center favorite image within cell */
  display: inline;
  padding: 0;

  & path {
    fill: none;
    stroke: var(--color-text-body);
    stroke-width: 40;
  }

  &:hover,
  & .favoriteSelected {
    & path {
      fill: currentColor;
      stroke: unset;
    }
  }
}

.filter {
  min-width: 200px;
}

.filterCheckboxWrapper {
  align-items: center;
  background-color: var(--color-bg-primary);
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  composes: filter;
  display: flex;
  padding: var(--spacing-2) var(--spacing-3);
}

.filterCheckbox {
  margin-bottom: 0;
}

.logo {
  border-radius: var(--rounded-sm);
  max-height: var(--avatar-size-lg);
  max-width: var(--avatar-size-lg);
}

:global(.rt-td) {
  &.modules {
    overflow-x: auto;
    white-space: normal;

    & > * {
      display: inline-block;
      margin-bottom: var(--spacing-1);
    }

    & > :not(:last-child) {
      margin-right: var(--spacing-2);
    }
  }
}
