.root {
  --l-width: 200px;

  & .mediaCreditBalance {
    min-width: var(--l-width);
  }
}

.marginBuster {
  margin-bottom: 0;
}

.bigNumber {
  color: var(--color-text-heading);
  font-size: var(--text-2xl);
  font-weight: var(--font-semibold);
  line-height: var(--leading-2xl);
}
