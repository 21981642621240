.root {
  background-color: var(--color-bg-primary);
  margin-bottom: var(--spacing-6);

  & :global(.ReactCollapse--collapse) {
    transition: height 500ms;
  }

  & :global(.ReactCollapse--content) {
    --l-border-radius: var(--rounded-xl);

    background: var(--color-bg-tertiary);
    border: 1px solid var(--color-decorative-secondary);
    border-radius: var(--rounded-none) var(--rounded-none) var(--l-border-radius)
      var(--l-border-radius);
    margin-top: calc(var(--l-border-radius) * -1);
    width: 100%;
    z-index: -1;

    &::before,
    &::after {
      clear: both;
      content: '';
      display: table;
    }

    /* Collapse component contents */
    & > * {
      padding: calc(var(--spacing-4) + var(--spacing-3)) var(--spacing-4) var(--spacing-4);
    }
  }
}

.pathWrapper {
  background-image: var(--l-background-image);
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-lg);
  box-shadow: var(--drop-shadow);
  max-width: 1150px;
  min-height: 215px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;

  &::before {
    background: linear-gradient(0.25turn, rgba(255, 255, 255, 1) 15%, rgba(0, 0, 0, 0) 75%);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.path {
  padding: var(--spacing-4);
  position: relative;

  & h3 {
    margin: 0;
  }
}

.actionChoose {
  bottom: calc(var(--button-size) + var(--spacing-2));
  left: var(--spacing-4);
  position: absolute;
}

.actionDetails {
  bottom: 0;
  left: var(--spacing-4);
  padding: 0;
  position: absolute;

  &.active,
  &:active,
  &:focus,
  &:hover {
    background-color: inherit;
  }
}
