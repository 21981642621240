.uploadSection {
  display: -webkit-inline-box;
}

.addButton {
  size: 50px;
}

.summaryItem {
  display: inline-block;
}

.formRoot {
  max-width: var(--breakpoint-lg);
}

.fieldset {
  max-width: var(--content-max-width);
}

.arrow {
  margin-right: 25px;
  margin-top: var(--spacing-2-half);
  max-width: 2%;
}

.deleteButton {
  max-width: 42px;
}

.columnSelect {
  margin-right: 0px;
}

.dataType {
  width: 13%;
}

.chip {
  margin-left: var(--spacing-1);
}
