.noImports {
  align-items: center;
  display: flex;
  flex-direction: column;
  /* Subtract 200px to account for the padding-bottom of the page content. */
  height: calc(100% - 200px);
  justify-content: center;
  max-width: var(--breakpoint-xl);
  position: absolute;
  /* Subtract --spacing-3 from padding-left and --spacing-2 from padding-right from the width of the spinner to account for padding of page main */
  width: calc(100% - (var(--spacing-3) + var(--spacing-2)));

  & button {
    margin-top: var(--spacing-4);
  }
}
