.root {
  & :global(.mantine-Drawer-content) {
    display: flex;
    flex-direction: column;
  }

  & :global(.mantine-Drawer-header) {
    border-bottom: 1px solid var(--color-slate-200);
    flex: 0 0 auto;
    flex-flow: wrap;
    padding: var(--spacing-6);
  }

  & :global(.mantine-Drawer-title) {
    color: var(--color-text-heading);
    font-weight: var(--font-semibold);
    line-height: var(--leading-base);
  }

  & :global(.mantine-Drawer-body) {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  & :nth-last-child(1 of :global(.drawer-content)) {
    flex: 1 0 auto;
  }

  & :global(.drawer-actions):last-of-type {
    bottom: 0;
    position: sticky;
  }
}

.description {
  flex: 0 0 100%;
}

.headerSuffix {
  flex: 0 0 100%;
}
