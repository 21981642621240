.card {
  margin-top: var(--spacing-3);
  width: var(--content-extra-width);

  & ul {
    list-style: disc;

    & li {
      margin-bottom: var(--spacing-2);
    }

    & li:first-of-type {
      margin-top: var(--spacing-2);
    }
  }
}
