.root {
  width: var(--modal-width);
}

.body {
  margin-bottom: var(--spacing-4);
}

.heading {
  color: var(--color-text-heading);
  display: block;
  font-size: var(--text-sm);
  font-weight: var(--font-semibold);
  line-height: var(--leading-sm);
  margin: 0 0 var(--spacing-4) 0;
}

.list {
  list-style-type: disc;
  margin: var(--spacing-4) 0;
  padding-left: var(--spacing-4);
}

.noResults {
  margin: var(--spacing-4);
}
