.cellWrapper {
  align-items: center;
  display: flex;
  height: 100%;
}

.secondaryText {
  color: var(--slate-600);
  font-size: 10px;
  font-style: italic;
}

.stackedCellContent {
  display: flex;
  flex-direction: column;
}

.person {
  align-items: center;
  display: flex;
}

.preWrapper {
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  height: 36px;
  max-width: 250px;
  overflow: hidden;

  & > pre {
    border: none;
    font-size: 85%;
    height: 56px;
    line-height: 26px;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 5px;
    white-space: nowrap;
  }
}

.header {
  margin-left: 5px;
}

.map {
  height: 56px;
  width: 112px;
}
