progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--color-slate-200);
  /* Removes the default border in Firefox. */
  border: none;
  border-radius: var(--rounded-md);
  color: var(--l-theme-accent-color);
  /* The height of the progress bar. Matches the Figma design. */
  height: 8px;
}

/* Mozilla Firefox */
progress::-moz-progress-bar {
  background-color: var(--l-theme-accent-color);
  border-radius: var(--rounded-md);
}

/* Google Chrome */
.bar::-webkit-progress-bar {
  background-color: var(--color-slate-200);
  border-radius: var(--rounded-md);
}

.bar::-webkit-progress-value {
  background-color: var(--l-theme-accent-color);
  border-radius: var(--rounded-md);
  transition: width 1s ease;
}
