.wrapper {
  background-color: var(--color-bg-primary);
}

.content {
  padding: 0;

  /* Cancel out the padding top applied only to the first child from parent Card content */
  &:first-child {
    padding-top: 0;
  }

  & > h3 {
    align-items: center;
    box-sizing: content-box;
    color: var(--color-text-body-inverted);
    display: flex;
    font-size: var(--text-sm);
    height: 34px;
    padding: var(--spacing-1) var(--spacing-1) var(--spacing-1) var(--spacing-2);

    & > * {
      flex: 0 1 auto;
    }

    & .title {
      flex: 1 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & > svg:first-child {
      margin-right: 1ex;
    }
  }

  & > section {
    border-bottom: 0;
    margin: 0 calc(var(--spacing-px) * -1) calc(var(--spacing-px) * -1);
  }
}

.view {
  border-radius: var(--rounded);
  height: 34px;
  line-height: var(--leading-2xl);
  margin-bottom: calc((var(--spacing-2) * -1));
  margin-right: 3px;
  margin-top: calc((var(--spacing-3) * -1) + 3px);
  padding: 0 calc(var(--button-spacing-horizontal) - var(--spacing-1));
}

.alert {
  padding-left: var(--spacing-2);
}
