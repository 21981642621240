.root {
  align-items: center;
  display: flex;
  gap: var(--spacing-4);
  justify-content: space-between;
  padding: var(--spacing-4);

  &.fullWidth {
    justify-content: space-between;
  }

  /* Remove the double border if a header precedes actions */
  & + :global(.card-actions) {
    border-top: none;
  }

  /* Hide all card content and action siblings if collapse is clicked */
  &.collapsed ~ :global(.card-content),
  &.collapsed ~ :global(.card-actions) {
    border-bottom: none;
    display: none;
  }

  /* Only add bottom border to header if it's not the last (only) item */
  &:not(:last-of-type) {
    /* Only add this border if we're not collapsed */
    &:not(.collapsed) {
      border-bottom: 1px solid var(--color-decorative-secondary);
    }
  }
}

.collapseButton {
  margin-right: var(--spacing-1);
}

.container {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.titleWrapper {
  align-items: center;
  display: flex;
  gap: var(--spacing-2);
}

.title {
  color: var(--color-text-heading);
  font-size: var(--text-base);
  font-weight: var(--font-semibold);
  line-height: var(--leading-base);
  margin: 0;
  overflow-wrap: anywhere;
  padding: 0;
  white-space: normal;
}

.description {
  margin-top: var(--spacing-1);
}

.relaxed {
  padding: var(--spacing-6);
}

.titleAndDescription {
  /* Ensures CardActions is pushed to the end of the container. */
  flex: 1 1 auto;
  max-width: var(--content-max-width-v2);
  width: 100%;
}

.default {
  padding: var(--spacing-4);
}

.compact {
  padding: var(--spacing-2) var(--spacing-4);
}

.children {
  align-items: center;
  display: flex;
  gap: var(--spacing-3);
}
