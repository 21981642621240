/* stylelint-disable no-descending-specificity */
.root {
  --l-col-padding: var(--spacing-3);

  background-color: var(--color-bg-primary);

  &:global(.-hasheadergroups) {
    & :global(.rt-noData) {
      transform: translate(-50%, var(--spacing-8)) !important;
    }
  }

  & :global(.rt-tr) {
    align-items: center;
  }

  & :global(.rt-th) {
    & > * {
      position: relative;

      & svg {
        /* Font size / -2 */
        margin-top: calc(var(--text-sm) / -2);
        position: absolute;
        right: var(--spacing-half);
        top: 50%;
      }

      /* Align tooltip icon left so it doesn't overlap sort icon */
      & svg[data-icon='circle-info'] {
        left: var(--spacing-half);
      }
    }

    & > :global(.rt-resizer) {
      position: absolute;
    }

    & .sortIconDown,
    & .sortIconUp {
      display: none;
    }

    &:global(.-sort-asc) {
      & .sortIcon {
        display: none;
      }

      & .sortIconDown {
        display: block;
      }
    }

    &:global(.-sort-desc) {
      & .sortIcon {
        display: none;
      }

      & .sortIconUp {
        display: block;
      }
    }

    &.sort {
      & > * {
        padding-right: calc(var(--icon-size-sm) + var(--spacing-2));
      }
    }

    &.tooltip {
      & > * {
        padding-left: calc(var(--icon-size-sm) + var(--spacing-2));
      }
    }
  }

  &:global(.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover) {
    background-color: var(--color-bg-secondary);
  }

  &:global(.ReactTable) {
    border-color: var(--color-decorative-secondary);
    border-radius: var(--rounded-md);

    & :global(.rt-noData) {
      z-index: unset;
    }

    & :global(.rt-table) {
      /* Without this border radius the top and left corner radius gets cut off  */
      border-radius: var(--rounded-md);
    }

    & :global(.-loading) {
      /* Without this border radius the top and left corner radius gets cut off  */
      border-radius: var(--rounded-md);
      z-index: unset;

      &:global(.-active) {
        z-index: unset;
      }
    }

    & :global(.rt-thead) {
      & :global(.rt-th) {
        border-right-color: var(--color-decorative-secondary);
        color: var(--color-text-heading);
        line-height: inherit;
        padding: var(--spacing-4) var(--l-col-padding);
      }

      & :global(.rt-td.-sort-asc),
      & :global(.rt-th.-sort-asc) {
        box-shadow: inset 0 3px 0 0 var(--color-action);
      }

      & :global(.rt-td.-sort-desc),
      & :global(.rt-th.-sort-desc) {
        box-shadow: inset 0 -3px 0 0 var(--color-action);
      }
    }

    & :global(.rt-thead.-header) {
      background-color: var(--color-bg-tertiary);
      border-bottom: 1px solid var(--color-decorative-secondary);
      box-shadow: var(--drop-shadow-none);
    }

    & :global(.rt-tbody) {
      & :global(.rt-td) {
        border-right-color: transparent;
        padding: var(--l-col-padding);
      }

      /* Never show underlines for buttons and links in tables */
      & a,
      & button {
        text-decoration: none;
      }

      & :global(.rt-tr-group) {
        border-color: var(--color-decorative-secondary);
      }
    }

    & :global(.rt-tfoot) {
      border-top: 1px solid var(--color-decorative-secondary);
      box-shadow: var(--drop-shadow-none);

      & :global(.rt-td) {
        border-right-color: transparent;
        padding: var(--l-col-padding);
      }
    }

    & :global(.-pagination) {
      border-top: 1px solid var(--color-decorative-secondary);
      box-shadow: var(--drop-shadow-none);

      & :global(.-next),
      & :global(.-previous) {
        align-items: center;
        display: flex;
      }

      & :global(.-next) {
        justify-content: flex-end;
        padding-right: var(--spacing-2);
      }

      & :global(.-previous) {
        justify-content: flex-start;
        padding-left: var(--spacing-2);
      }
    }
  }
}

.cell {
  display: block;
}

.cellCenter {
  composes: cell;
  text-align: center;
}

.cellOptions {
  width: 66px;
}

.cellRight {
  composes: cell;
  text-align: right;
}

.cellLeft {
  composes: cell;
  text-align: left;
}

.spacingTop {
  margin-top: var(--spacing-1);
}

.thumbnail {
  height: 130px;
  padding: var(--spacing-3) var(--spacing-2) !important;

  & img {
    background-color: var(--color-bg-primary);
    border: 1px solid var(--color-decorative-secondary);
    border-radius: var(--rounded);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: inline-block;
    height: auto;
    margin: auto;
    max-width: 100%;
    transition: all 0.2s ease-in-out;
  }
}

.checkbox {
  margin-bottom: 0;
  /* Center the checkbox horizontally in the column */
  margin-left: -1.5px;
}
