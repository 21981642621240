.input {
  margin-bottom: var(--spacing-6);
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);

  & .section {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
    margin-bottom: var(--spacing-2);

    & .optionGroup {
      color: var(--color-slate-500);
      font-size: var(--text-xs);
      font-weight: var(--font-semibold);
      margin-bottom: var(--spacing-half);
    }

    & .optionGroup:first-of-type {
      margin-top: calc(var(--spacing-1) * -1);
    }
  }
}

.actions {
  display: flex;
  gap: var(--spacing-2);
}
