.usage {
  background-color: var(--color-bg-primary);
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  flex: 1;
  margin-bottom: var(--spacing-4);
  padding: var(--spacing-4);
}

.usageHeading {
  font-weight: var(--font-bold);
}

.usageCategory {
  display: block;
  font-weight: var(--font-semibold);
}

.usageCurrent {
  color: var(--color-text-heading);
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
  line-height: var(--leading-2xl);
}
