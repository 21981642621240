.button {
  justify-content: center;
}

.container {
  align-items: center;
  display: flex;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
}

.content > span {
  color: var(--color-text-body);
  font-size: 10px;
  font-style: italic;
}

.person {
  align-items: center;
  display: flex;
}

.timeline {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: var(--text-base);
  height: 100%;
  justify-content: center;
}

.timeline > span {
  margin-right: 5px;
}
