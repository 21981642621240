.root {
  align-items: center;
  display: flex;
  gap: var(--spacing-1);

  & > * + * {
    margin-left: var(--spacing-1);
  }

  & > :first-child {
    flex: 1;
  }

  & > button {
    padding-left: var(--spacing-1);
    padding-right: var(--spacing-1);

    &:global(.active),
    &:active,
    &:focus,
    &:hover {
      color: var(--color-primary-3);
    }
  }
}

.predicateGroup {
  background-color: var(--color-bg-secondary);
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-lg);
  padding: var(--spacing-1);
}

.predicateWrapper {
  align-items: center;
  display: flex;
}

.predicate {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  padding: var(--spacing-1);

  & > :nth-child(3) {
    flex: 1;
  }

  /* Allow additional date predicates to flex onto the next row */
  & > :nth-child(4) {
    flex-basis: 100%;
    margin-top: var(--spacing-1);
  }
}

.singleClose {
  margin-left: var(--spacing-1);
}

/* Desktop large, should be --breakpoint-xl */
@media (max-width: 1170px) {
  .actions {
    display: flex;
    justify-content: flex-end;
  }

  .predicateGroup {
    width: 100%;
  }
}

/* Desktop, Tablet landscape, should be --breakpoint-lg  */
@media (max-width: 960px) {
  /* Stack the input fields on top of each other to account for shrinking space */
  .predicate {
    flex-direction: column;
    gap: var(--spacing-2);
    width: 100%;
  }
}
