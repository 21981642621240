.config {
  align-content: center;
  width: var(--content-extra-width);

  & > * {
    margin-bottom: var(--spacing-3);
  }
}

.container {
  margin: var(--spacing-2-half);
}

.header {
  margin-top: var(--spacing-1);
}
