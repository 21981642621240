.segment {
  margin-bottom: var(--spacing-3);
}

.empty {
  text-align: center;
}

.endpointType {
  margin-bottom: 0;
}
