.content {
  align-items: flex-start;
  display: flex;
}

.invoices {
  flex: 1 1 auto;
}

.projects {
  margin-right: var(--spacing-3);
  max-width: 20rem;
}

.icon {
  margin-left: 5px;

  &:hover {
    cursor: pointer;
  }
}
