.root {
  border-top-left-radius: var(--rounded-lg);
  border-top-right-radius: var(--rounded-lg);
  padding: var(--spacing-6);

  &.addVerticalGap {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-6);
  }
}

.content {
  align-items: center;
  display: flex;
  gap: var(--spacing-2);
  justify-content: space-between;
}

.description {
  margin-top: var(--spacing-1);
}
