/* v1 */

.alert {
  font-size: var(--text-xs);
  margin-top: var(--spacing-4);
}

.link {
  color: var(--color-action);
  padding: var(--spacing-1);
}

.root {
  max-width: var(--breakpoint-md);
}

.row {
  display: flex;

  & > :last-child {
    margin-left: var(--spacing-2);
  }
}

/* v2 */

.accessButton {
  width: 100% !important;
}

.headerIcon {
  margin-left: var(--spacing-1);
}

/* TODO: Refactor facebook integration page to not require limiting width on the page level */
.page {
  max-width: var(--content-extra-width);
}

.picture {
  margin-left: var(--spacing-1);
}

.tosWarning {
  color: var(--color-warning);
  margin: var(--spacing-1) 0 0 var(--spacing-3);

  &:hover {
    cursor: pointer;
  }
}

/* shared */

.alertToken {
  font-size: var(--text-sm);
}

.card {
  margin: var(--spacing-4) 0 var(--spacing-6) 0;

  & .connection {
    display: flex;
    font-size: var(--text-sm);

    & .session {
      margin-top: var(--spacing-1);
      padding-left: var(--spacing-1);

      & > * {
        margin-bottom: calc(var(--spacing-1) * -0.75);
      }
    }

    & label {
      flex: 1;
      font-size: var(--text-sm);
    }

    & .actions {
      display: flex;
      flex: 1;
      justify-content: flex-end;

      & > .actionsLeft {
        margin-right: var(--spacing-1);
      }
    }
  }

  & .integration {
    margin-top: var(--spacing-6);

    & label {
      font-size: var(--text-sm);
    }

    & .chip {
      justify-content: center;
      min-width: 120px;

      & > * {
        font-size: var(--text-sm);
        font-weight: var(--font-semibold);
        max-width: fit-content;
      }
    }
  }

  & .integrationInfo {
    display: flex;
  }
}
