.radioGroup {
  & > div {
    align-items: flex-start;
    display: flex !important;

    & input[type='radio'] {
      /* 3px aligns radio buttons with the text */
      margin: 3px var(--spacing-3) 0 0;
    }

    &:not(:last-child) {
      & label {
        margin-bottom: var(--spacing-4);
      }
    }
  }
}

/* Pushing solicit code select over to align with radio labels */
.solicitCodeSelect {
  margin-left: var(--spacing-6);
  margin-top: var(--spacing-2);
}
