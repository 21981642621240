.actions {
  & > button,
  & > a {
    /* TODO: Remove this temporary fix once #4017 is resolved */
    justify-content: center;
  }

  /* Buttons with a tooltip */
  & > span > a,
  & > span > button {
    /* TODO: Remove this temporary fix once #4017 is resolved */
    justify-content: center;
    width: 100%;
  }
}
