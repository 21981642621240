.map {
  border-radius: var(--rounded-md);
  height: 250px;
  width: 100%;
  z-index: 0;
}

.marker {
  background-color: var(--color-primary-3);
  background-size: cover;
  border: 2px solid var(--color-text-body-inverted);
  border-radius: var(--rounded-full);
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  height: 14px;
  width: 14px;
}
