.root {
  align-items: center;
  background-color: var(--color-violet-50);
  border: 1px solid var(--color-violet-200);
  border-radius: var(--rounded);
  display: flex;
  gap: var(--spacing-3);
  justify-content: space-between;
  padding: var(--spacing-4) var(--spacing-6);
}

.flex {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
}

.stack {
  display: flex;
  gap: var(--spacing-3);
}

.title {
  color: var(--color-violet-800);
  font-size: var(--text-base);
  font-weight: var(--font-semibold);
  line-height: var(--leading-base);
}

.description {
  color: var(--color-violet-700);
  font-size: var(--text-sm);
  font-weight: var(--font-normal);
  margin-bottom: 0;
  max-width: var(--content-max-width);
}

.icon {
  align-self: flex-start;
  color: var(--color-violet-700);
}

.circle {
  --l-circle-size: 60px;

  border: 2px solid var(--color-violet-700);
  border-radius: var(--rounded-full);
  display: flex;
  height: var(--l-circle-size);
  justify-content: center;
  overflow: hidden;
  padding: var(--spacing-2);
  width: var(--l-circle-size);

  & .icon {
    align-self: center;
  }
}

.vertical {
  & .stack {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  & .flex {
    align-items: center;
  }

  & .description {
    text-align: center;
  }
}
