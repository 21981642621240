.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  margin-top: none;
}

.link.link.link {
  border-bottom: none;
  text-decoration: none;

  &::after {
    content: none;
  }

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
}
