.root {
  flex-direction: column;
  margin-top: var(--spacing-2);
  width: 100%;
}

.label {
  font-weight: var(--font-semibold);
}

.statistics {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
