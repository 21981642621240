.item {
  margin-bottom: var(--spacing-6);
}

.noFlightsContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > a {
    margin-top: var(--spacing-2);
  }
}
