.grid {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-6);
  margin-left: 0;

  /* Spread projects evenly when the browser is large enough - must be exactly 27px */
  @media (min-width: 1416px) {
    gap: 27px;
  }
}
