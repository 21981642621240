.root {
  margin-bottom: var(--spacing-2);
}

.flavor {
  align-items: center;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.flavorDescription {
  color: var(--color-text-heading);
  flex: 1 0 100%;
  font-size: var(--text-lg);
  font-weight: var(--font-semibold);
  margin-top: var(--spacing-2);
}

.map {
  height: 100px;
  width: 100%;
  z-index: 0;
}

.link {
  margin: 0;
  padding: 0;
}

.time {
  color: var(--color-slate-700);
  font-size: var(--text-xs);
  line-height: var(--leading-xs);
}

.chipFix.chipFix > span {
  max-width: none;
}
