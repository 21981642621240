.root {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;

  &.hasBullets {
    list-style: disc;
    margin-left: var(--spacing-5);

    & li {
      min-height: unset;
    }
  }

  &.hasDividers {
    & li {
      border-bottom: 1px solid var(--color-decorative-secondary);

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      padding-bottom: var(--spacing-4);
      padding-top: var(--spacing-4);
    }
  }
}
