.root.root.root {
  padding-bottom: var(--spacing-4);

  &:global(.m_5df29311) {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
  }
}

.embed {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  margin-top: var(--spacing-4);
}

.link {
  text-decoration: none;
}
