.root {
  & > :nth-child(n + 2) {
    margin-left: calc(200px + var(--spacing-3));
  }
}

.steps {
  float: left;
  width: 200px;
}

.image {
  /* Setting width here so that the button on the image picker isn't wider than the image */
  width: 150px;
}
