.root {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin-bottom: 0;
  text-align: left;

  & > div[role='group'] {
    margin-bottom: 0;
    order: 1;
    width: 100%;
  }

  & button {
    margin-left: var(--spacing-2);
  }
}

.description {
  margin-top: var(--spacing-2);
}
