.hidden {
  display: none;
}

.lastSaved {
  display: flex;
  gap: var(--spacing-1);

  /* Reset the margin on the bottom of the label */
  & span {
    margin-bottom: 0;
  }
}

.root {
  background-color: var(--color-bg-primary);
  position: relative;

  & .editor {
    border: 1px solid var(--color-decorative-secondary);
    height: calc(100vh - 300px);
    min-height: 700px;
    width: 100%;
  }
}

@media (min-width: 1410px) {
  .root {
    left: calc(-50vw + 705px);
    width: calc(100vw - 280px);
  }
}

.root:fullscreen {
  background-color: var(--color-bg-primary);
  padding: var(--spacing-4);

  & .editor {
    border: 1px solid var(--color-decorative-secondary);
    height: 96%;
  }
}

.dynamicContentContainer {
  display: flex;
}

.countdownPreview {
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-lg);
  width: min-content;
}

.formPreview {
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-lg);
  flex: 1;
  padding: var(--spacing-4);
  width: 400px;
}

.formConfigForm {
  flex: 1;
  margin-right: var(--spacing-4);
}

.typeSelect {
  width: 300px;
}

.dialogForm {
  margin: auto;
  width: 100%;
}

.dialogHeader {
  background-color: var(--color-bg-primary);

  & > h3 {
    align-items: center;
    display: flex;
    font-size: 24px;
    font-weight: var(--font-bold);
    margin: 0;
  }
}

.divider {
  border-left: 1px solid var(--color-decorative-secondary);
  height: var(--l-height);
}

.formFieldCard {
  &:not(:last-child) {
    margin-bottom: var(--spacing-2);
  }
}

.toolbarButton {
  margin-top: 26px;
}

.leftToolbar {
  display: flex;

  & > :nth-child(n + 2) {
    margin-left: var(--spacing-2);
  }
}

.toolbars {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-3);
}

.editorHidden {
  border: none !important;
  height: 0 !important;
  position: absolute !important;
  width: 0 !important;
}

.preview {
  border: 1px solid var(--color-decorative-secondary);
  padding: var(--spacing-4);
  width: 100%;
}
