.content {
  flex-grow: 0;
  margin-bottom: 0;
  margin-right: var(--spacing-2);
  margin-top: var(--spacing-2);
  max-width: 100%;
  padding: var(--spacing-5) var(--spacing-2) var(--spacing-5) var(--spacing-5);

  &::-webkit-scrollbar {
    flex-grow: 0;
    overflow-x: hidden;
    position: static;
    width: var(--scrollbar-width);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-slate-400);
    border-radius: var(--rounded-lg);
    flex-grow: 0;
    overflow-x: hidden;
    position: static;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

.title {
  color: var(--color-text-heading);
  font-size: var(--text-base);
  font-weight: var(--font-semibold);
}

.header {
  align-items: center;
  display: flex;
  gap: var(--spacing-3);
  margin-bottom: var(--spacing-6);

  & button {
    padding-left: var(--spacing-2);
    padding-right: var(--spacing-2);

    & span {
      margin-right: 0;
    }
  }
}
