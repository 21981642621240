.toast {
  background: var(--color-bg-primary);
  border: 2px solid;
  border-radius: var(--rounded);
  color: var(--color-text-body);
  font-family: var(--font-body);
  padding: var(--spacing-2-half);

  & button {
    color: var(--color-text-body);
  }
}

.toastError {
  border-color: var(--color-danger);
}

.progressBarError {
  background: var(--color-danger);
}

.progressBarSuccess {
  background: var(--color-success);
}

.toastSuccess {
  border-color: var(--color-success);
}

.progressBarInfo {
  background: var(--color-info);
}

.toastInfo {
  border-color: var(--color-info);
}

.toastWarning {
  border-color: var(--color-warning);
}

.progressBarWarning {
  background: var(--color-warning);
}
