.root {
  margin: 0 auto;
  max-width: var(--content-max-width);
}

.keywordSection {
  margin-top: var(--spacing-3);
}

.inputWrapper {
  width: 50%;
}

.input {
  flex: 1;
}

.customKeywordInputWrapper {
  display: flex;
  gap: var(--spacing-2);
}
