.root {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;

  & svg {
    margin-right: var(--spacing-1);
  }

  & .instagramIndicator:before {
    content: '|';
    display: inline-block;
    margin-left: var(--spacing-3);
    margin-right: var(--spacing-3);
  }
}
