.root {
  /* 8px from gap + 8px here for a 16px margin */
  margin-top: var(--spacing-2);

  & .alert {
    margin-bottom: 0;
    margin-top: var(--spacing-4);
  }

  & .tooltip {
    width: 100%;
  }
}
