.bulkActions {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--spacing-3);
}

.filters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--spacing-4);

  & > * {
    display: flex;
    flex: 1 1 auto;
  }

  & > .filtersLeft {
    & > * {
      margin-right: var(--spacing-2);
    }
  }

  & > .filtersRight {
    justify-content: flex-end;

    & > * {
      margin-left: var(--spacing-2);
    }
  }
}

.label {
  display: block;
  font-weight: var(--font-bold);
  width: 100%;
}

.selected {
  width: 100%;
}
