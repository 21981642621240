.root {
  display: flex;
  overflow-x: scroll;
  width: 100%;

  & > :global(.smooth-dnd-container) {
    & > :global(.smooth-dnd-draggable-wrapper) {
      display: flex;

      &:not(:first-child) {
        padding-left: var(--spacing-3);
      }

      & > section {
        cursor: move;
      }
    }
  }
}

.button {
  margin-left: var(--spacing-3);
  margin-right: var(--spacing-3);
}

.noDataContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  & > p {
    max-width: var(--content-max-width);
    text-align: center;
  }
}
