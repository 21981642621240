.root {
  background-color: var(--l-background-color, var(--color-bg-primary));
  border-radius: var(--rounded-lg);
  display: flex;
  flex-direction: column;
  position: relative;
}

.bordered {
  border: 1px solid var(--color-decorative-secondary);
}

.disabled {
  & > * {
    opacity: 0.5;
  }
}

.linked {
  cursor: pointer;
  transition: box-shadow 100ms ease-in, transform 100ms ease-in;

  &:focus-within,
  &:hover {
    box-shadow: var(--drop-shadow-md);
    transform: translate(-1px, -1px);
  }
}

.raised {
  box-shadow: var(--drop-shadow);
}

.secondary {
  background-color: var(--color-bg-secondary);
}

.actions {
  align-items: center;
  border-radius: var(--rounded-none) var(--rounded-none) var(--rounded-lg)
    var(--rounded-lg);
  border-top: 1px solid var(--color-decorative-secondary);
  display: flex;
  height: var(--button-with-icon-size);
  justify-content: flex-end;
  margin-bottom: 0;
  padding: 0;

  & > li {
    flex: 1 0 auto;
    max-width: 86px;
    position: relative;
    text-align: center;
    width: 33%;

    &::before {
      --l-height: calc(var(--leading-normal) * var(--text-sm));

      border-right: 1px solid var(--color-decorative-secondary);
      content: '';
      height: var(--l-height);
      left: 0;
      margin-top: calc(var(--l-height) / -2);
      position: absolute;
      top: 50%;
    }

    & > * {
      display: block;
      width: 100%;
    }
  }

  &.actionsCount3 > li:first-child::before {
    content: unset;
  }
}

.content {
  flex: 0 1 auto;
  padding: var(--spacing-2) var(--spacing-3) var(--spacing-3);

  & > h3 {
    font-size: var(--text-lg);
    line-height: var(--leading-lg);
    margin-bottom: 0;
    margin-top: 0;

    & > a {
      color: inherit;
      outline: none;
      text-decoration: none;

      &::before {
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }

      &:global(.active),
      &:active,
      &:focus,
      &:hover {
        color: inherit;
      }

      &:focus {
        &::before {
          outline: 5px auto -webkit-focus-ring-color;
          outline-offset: -2px;
        }
      }
    }
  }

  &:first-child {
    padding-top: var(--spacing-3);
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.truncated {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cover {
  align-items: center;
  display: flex;
  justify-content: center;

  &[style*='--aspect-ratio'] {
    position: relative;

    & > img {
      height: auto;
    }

    & > :first-child {
      max-height: 100%;
      max-width: 100%;
    }

    &::before {
      content: '';
      display: block;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
  }
}

.nested {
  border-radius: var(--rounded);

  & .actions {
    border-radius: var(--rounded-none) var(--rounded-none) var(--rounded)
      var(--rounded);
  }
}
