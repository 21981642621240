.root {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & > div + div {
    margin-left: var(--spacing-1);
  }
}
