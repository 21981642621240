/* stylelint-disable no-descending-specificity */

.root {
  --l-carousel-height: 60px;

  color: var(--color-text-body);
  display: flex;
  font-weight: var(--font-medium);
  height: var(--l-carousel-height);
  padding-right: 0;

  /* Container for the carousel viewport and controls */
  & :global(.mantine-Carousel-root) {
    align-items: center;
    display: flex;
    padding: var(--spacing-2) 0;
    width: 100%;

    & :global(.mantine-Carousel-viewport) {
      cursor: grab;

      &:active {
        cursor: grabbing;
        transition: unset;
      }
    }

    & :global(.mantine-Carousel-container) {
      transition: transform 0.5s ease-in-out;

      &:active {
        cursor: grabbing;
        transition: unset;
      }
    }

    & :global(.mantine-Carousel-viewport:active) {
      & :global(.mantine-Carousel-container) {
        transform: unset;
        transition: unset;
      }
    }
  }

  /* Left and right control buttons */
  & :global(.mantine-Carousel-controls) {
    align-items: baseline;
    gap: var(--spacing-2);
    margin-left: var(--spacing-3);
    padding: 0;
    position: unset;
    right: unset;
  }

  /* Individual control buttons */
  & :global(.mantine-Carousel-control) {
    --l-control-size: 38px;

    border: var(--border-decorative-secondary);
    border-radius: var(--rounded-md);
    box-shadow: none;
    height: var(--l-control-size);
    width: var(--l-control-size);

    &[data-inactive] {
      background: var(--color-bg-tertiary);
      border: var(--border-transparent);
      color: var(--color-text-disabled);
      cursor: not-allowed;
    }
  }

  /* Container for carousel items */
  & :global(.mantine-Carousel-slide) {
    min-width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

/* The inner content of the carousel slide */
.item {
  background: transparent;
  border: var(--border-transparent);
  color: var(--color-text-body);
  cursor: pointer;
  overflow: hidden;
  padding: var(--spacing-2) var(--spacing-3);
  text-overflow: ellipsis;
  width: 100%;

  &:hover {
    background: var(--color-bg-tertiary);
    border-radius: var(--rounded-md);
    color: var(--color-text-heading);
  }

  &.active {
    background: var(--color-sky-50);
    border: var(--border-active);
    border-radius: var(--rounded-md);
    color: var(--color-action);
  }
}

.prefixWrapper {
  align-items: center;
  display: flex;
  padding: var(--spacing-2) var(--spacing-3);
  padding-left: 0;
}

/* Styles for prefix set separately because it should not be treated as a carousel item */
.prefix {
  background: transparent;
  border: var(--border-transparent);
  color: var(--color-text-body);
  cursor: pointer;
  margin-right: var(--spacing-3);
  padding: var(--spacing-2) var(--spacing-3);
  text-wrap: nowrap;

  &:hover {
    background: var(--color-bg-tertiary);
    border-radius: var(--rounded-md);
    color: var(--color-text-heading);
  }

  &.active {
    background: var(--color-sky-50);
    border: var(--border-active);
    border-radius: var(--rounded-md);
    color: var(--color-action);
  }
}

/* Divides the prefix and Carousel root container */
.divider {
  --l-divider-height: 36px;

  border-right: var(--border-decorative-secondary);
  float: right;
  height: var(--l-divider-height);
  width: 1px;
}
