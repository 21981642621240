.skeleton {
  --l-skeleton-color: var(--color-bg-tertiary);
  --l-skeleton-to-color: var(--color-slate-200);

  animation: skeleton-glow 1.4s ease infinite;
  background: linear-gradient(
    90deg,
    var(--l-skeleton-color) 25%,
    var(--l-skeleton-to-color) 37%,
    var(--l-skeleton-color) 63%
  );
  background-clip: padding-box !important;
  background-size: 400% 100%;
  border-color: var(--l-skeleton-to-color) !important;
  border-radius: var(--rounded-sm);
  box-shadow: none !important;
  color: transparent !important;
  cursor: default;
  pointer-events: none;
  user-select: none;

  & *,
  & .skeleton:after,
  & .skeleton:before {
    visibility: hidden !important;
  }
}

@keyframes skeleton-glow {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}
