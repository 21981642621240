.formRoot {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: none;

  /* Override the default form styles before they get replaced in the future */
  & > * {
    margin-bottom: 0;
  }
}

.fieldset {
  margin-top: var(--spacing-4);
  max-width: var(--content-max-width);
}

.help {
  color: var(--slate-600);
  display: block;
  font-size: var(--text-xs);
  margin-bottom: var(--spacing-2);
  margin-top: var(--spacing-2);
}

.alert {
  margin-bottom: 0;
}

.error {
  color: var(--color-danger);
  composes: help;
}
