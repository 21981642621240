.container {
  display: flex;
}

.comparison {
  white-space: nowrap;
}

.iconContainer {
  align-items: center;
  display: flex;
  margin-right: var(--spacing-4);
}

.nameContainer {
  align-items: center;
  display: flex;
}

.numberInput {
  min-width: 90px;
  width: 100%;
}

.select {
  /* Make the minimum width a size that encompasses more options */
  min-width: 200px;

  & :global(.react-select__single-value),
  & :global(.react-select__multi-value) {
    max-width: calc(100% - var(--spacing-1-half));
  }

  &:global(.react-select--is-disabled) {
    cursor: not-allowed;
    pointer-events: auto;

    & :global(.react-select__control) {
      background-color: var(--color-bg-secondary);
      pointer-events: none;
    }

    & :global(.react-select__single-value),
    & :global(.react-select__multi-value) {
      color: var(--color-text-disabled);
    }
  }
}

.spinner {
  margin-top: var(--spacing-3);
}

.time {
  margin-left: var(--spacing-1);
}
