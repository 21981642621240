.root {
  display: flex;

  &.alignCenter {
    justify-content: center;
  }

  &.alignLeft {
    justify-content: flex-start;
  }

  &.alignRight {
    justify-content: flex-end;
  }

  & > ul {
    list-style: none;
    margin: 0;
    padding: 0;

    & > li {
      display: inline-block;
      vertical-align: middle;
    }

    & > li + li {
      margin-left: var(--spacing-3);
    }
  }
}
