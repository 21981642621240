.row {
  display: flex;
  gap: var(--spacing-4);
  justify-content: space-between;
}

.rowLabel {
  margin: 0;
}

.left {
  display: flex;
  gap: var(--spacing-1);
}

.right {
  text-align: right;

  & p {
    margin-bottom: var(--spacing-1);
  }

  /* Never have extra margin on the last <p> tag */
  & p:last-child {
    margin-bottom: 0;
  }
}

.tooltipIcon {
  color: var(--color-slate-600);
}
