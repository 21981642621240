.root {
  margin: 0 0 var(--spacing-2) 0;
  max-height: calc(100vh - (2 * var(--spacing-3)) - var(--button-size) - 22px);
  max-width: var(--content-max-width);
  overflow-x: hidden;
  padding: 0;

  & > li {
    margin-right: calc(var(--badge-dot-size-lg) / 2 + 2px);
  }
}
