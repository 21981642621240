.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
  height: 100vh;
  justify-content: center;

  & .header {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
  }
}

h2 {
  font-size: var(--text-2xl);
  margin: 0px;
}
