.alert {
  margin-top: var(--spacing-2);
}

.radios {
  margin-top: var(--spacing-1);
}

.creativeInfo {
  display: flex;
}

.creativeInfo > dl {
  margin-left: var(--spacing-2);
}

.creativeCard {
  margin-top: var(--spacing-3);

  &:first-child {
    margin-top: 0;
  }
}

.statuses {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.submitReview {
  flex-direction: column;
  margin-top: var(--spacing-2);
  width: max-content;
}

.thumbnailPreview {
  display: flex;
  flex-direction: column;
}
