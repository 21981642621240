.details {
  color: var(--color-slate-900);
  display: flex;
  flex-direction: column;
  font-size: var(--text-sm);
  font-weight: var(--font-normal);
  line-height: var(--leading-sm);
  
  & .expiry {
    color: var(--color-slate-600);
    font-size: var(--text-xs);
    font-weight: var(--font-normal);
    line-height: var(--leading-xs);
  }
}

.icon {
  height: 24px;
  width: 36px;
}
