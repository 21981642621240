.card {
  &:not(:last-child) {
    margin-bottom: var(--spacing-6);
  }

  & > div {
    margin-bottom: var(--spacing-2);
  }
}

.failed {
  color: var(--color-danger);
  margin-top: var(--spacing-2);

  & > svg {
    color: var(--color-danger);
    margin-right: var(--spacing-2);
  }
}

.inProgress {
  margin-top: var(--spacing-2);

  & > svg {
    color: var(--color-info);
    margin-right: var(--spacing-2);
  }
}

.emailHelpText {
  display: flex;
  justify-content: space-between;
}
