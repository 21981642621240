.root {
  align-items: center;
  display: flex;

  & > :last-child {
    margin-bottom: 0;
  }

  & > :first-child {
    margin-right: var(--spacing-3);
  }
}
