.root {
  & select {
    border: 1px solid var(--color-decorative-secondary);
    border-radius: var(--rounded);
    min-height: 136px;
    /* Prevent odd space below element */
    vertical-align: bottom;
    width: 100%;

    & option {
      overflow-x: hidden;
      padding: var(--spacing-2);
      text-overflow: ellipsis;
    }
  }
}

.clear {
  color: var(--color-decorative-secondary) !important;
  cursor: pointer;

  &:hover {
    color: var(--color-text-body) !important;
  }
}

.disabled {
  color: var(--color-disabled);
}

.loading {
  font-size: var(--text-2xl);
  line-height: var(--leading-2xl);
  padding: 0 var(--spacing-6) 0 var(--spacing-2);
  position: absolute;
  right: 0;
  top: var(--spacing-6);
}

.nameFilter {
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  margin-bottom: var(--spacing-1);
  padding: var(--spacing-2);
  width: 100%;
}

.selectWrapper {
  position: relative;
}

.tagFilter {
  margin-bottom: var(--spacing-1);
}
