.root {
  flex: 1 0 auto;
  text-align: right;
}

.label {
  font-size: var(--text-xs);
  text-align: right;
}

.stat {
  text-align: right;
}

.smallStat {
  composes: stat;
  font-size: var(--text-sm);
  font-weight: var(--font-normal);
  line-height: var(--leading-sm);
}

.mediumStat {
  composes: stat;
  font-size: var(--text-2xl);
  font-weight: var(--font-semibold);
  line-height: var(--leading-2xl);
}

.largeStat {
  composes: stat;
  font-size: var(--text-3xl);
  font-weight: var(--font-semibold);
  line-height: var(--leading-3xl);
}
