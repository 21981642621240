.instagramAdPreview {
  border: 1px solid var(--color-instagram-ad-preview-border);
  width: 400px;
}

.header {
  width: 100%;
}

.top {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: var(--spacing-2);
}

.topLeft {
  display: flex;
}

.logo {
  border: 0.5px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-full);
  height: 30px;
  margin-right: var(--spacing-3);
}

.topRight {
  color: var(--color-black);
}

.name {
  color: var(--color-text-heading);
  font-family: var(--font-instagram);
  font-size: var(--text-xs);
  font-weight: var(--font-medium);
  margin-bottom: 0;
}

.sponsored {
  color: var(--color-text-body);
  font-family: var(--font-instagram);
  font-size: var(--text-xs);
}

.creativeImage {
  width: 100%;
}

.callToAction {
  color: var(--color-black);
  display: flex;
  justify-content: space-between;
}

.callToActionText {
  color: var(--color-text-heading));
  font-family: var(--font-instagram);
  font-size: var(--text-xs);
  font-weight: var(--font-bold);
}

.bottom {
  margin: var(--spacing-2);
}

.allButtons {
  display: flex;
  justify-content: space-between;
}

.threeButtons {
  & > * {
    margin-right: var(--spacing-3);
  }
}

.primaryText {
  color: var(--color-text-body);
  display: flex;
  font-family: var(--font-instagram);
  font-size: var(--text-xs);
  margin-top: var(--spacing-2);
}
