code[class*='language-'],
pre[class*='language-'] {
  background: none;
  color: #f8f8f2;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  text-align: left;
  text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  white-space: pre;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
}
/* Code blocks */
pre[class*='language-'] {
  border-radius: 0.3em;
  margin: 0.5em 0;
  overflow: auto;
  padding: 1em;
}

:not(pre) > code[class*='language-'],
pre[class*='language-'] {
  background: #272822;
}
/* Inline code */
:not(pre) > code[class*='language-'] {
  border-radius: 0.3em;
  padding: 0.1em;
  white-space: normal;
}

:global {
  & .token.comment,
  & .token.prolog,
  & .token.doctype,
  & .token.cdata {
    color: slategray;
  }

  & .token.punctuation {
    color: #f8f8f2;
  }

  & .namespace {
    opacity: 0.7;
  }

  & .token.property,
  & .token.tag,
  & .token.constant,
  & .token.symbol,
  & .token.deleted {
    color: #f92672;
  }

  & .token.boolean,
  & .token.number {
    color: #ae81ff;
  }

  & .token.selector,
  & .token.attr-name,
  & .token.string,
  & .token.char,
  & .token.builtin,
  & .token.inserted {
    color: #a6e22e;
  }

  & .token.operator,
  & .token.entity,
  & .token.url,
  & .language-css .token.string,
  & .style .token.string,
  & .token.variable {
    color: #f8f8f2;
  }

  & .token.atrule,
  & .token.attr-value,
  & .token.function {
    color: #e6db74;
  }

  & .token.keyword {
    color: #66d9ef;
  }

  & .token.regex,
  & .token.important {
    color: #fd971f;
  }

  & .token.important,
  & .token.bold {
    font-weight: var(--font-bold);
  }

  & .token.italic {
    font-style: italic;
  }

  & .token.entity {
    cursor: help;
  }
}
