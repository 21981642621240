.root {
  display: inline-block;
  /* Fix misaligned selected tab with the bottom border  */
  margin-bottom: calc(var(--spacing-px) * -1);

  &:not(:first-child) {
    margin-left: var(--spacing-6);
  }
}

.tab {
  border-bottom: 2px solid transparent;
  border-radius: var(--rounded) var(--rounded) var(--rounded-none) var(--rounded-none);
  color: var(--color-text-body);
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
  /* Override the base height for a button, allowing proper positioning of the border */
  /* 12 px top spacing  + 12px bottom spacing + 20px line height */
  height: calc(var(--spacing-6) + var(--leading-sm));
  padding: 0;
  text-decoration: none;

  &:focus,
  &:hover {
    border-bottom: 2px solid var(--color-slate-400);
    color: var(--color-slate-950);
  }

  &:global(.active),
  &:active {
    border-bottom: 2px solid var(--color-action);
    color: var(--color-slate-950);
  }
}
