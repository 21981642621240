.modal {
  align-items: center;
  bottom: 0;
  display: flex;
  font-family: var(--font-body);
  justify-content: center;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  /* The modal container is just above the backdrop. */
  z-index: 1050;
}

.modalContent {
  background-color: var(--color-bg-primary);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: var(--rounded-md);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.modalDialog {
  /* All visible modal elements should be above the modal container. */
  z-index: 1060;
}

.backdrop {
  background-color: var(--color-modal-backdrop);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  /* The backdrop covers the entire app. */
  z-index: 1040;
}

.dialog {
  position: relative;
  /* All visible modal elements should be above the modal container. */
  z-index: 1060;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin: 15px;

  & > :not(:last-child) {
    margin-right: var(--spacing-2);
  }
}

.body {
  margin: 15px;
}

.header {
  border-top-left-radius: var(--rounded-md);
  border-top-right-radius: var(--rounded-md);
  margin: var(--spacing-4) 0;

  & > h3 {
    align-items: center;
    display: flex;
    font-weight: var(--font-bold);
    margin: 0;
  }
}

.outerModalDialog {
  margin: var(--spacing-2-half);
  max-height: 95%;
  position: relative;
  width: auto;
  /* All visible modal elements should be above the modal container. */
  z-index: 1060;
}

.description {
  margin: 0 15px 15px 15px;

  & > * {
    max-width: var(--content-max-width);
  }
}

@media (min-width: 768px) {
  .outerModalDialog {
    margin: 30px auto;
    width: 600px;
  }

  .modalSm {
    width: 500px;
  }
}

@media (min-width: 992px) {
  .modalLg {
    width: 900px;
  }

  .modalXl {
    width: 1080px;
  }
}
