.root {
  --l-input-width: 192px;

  width: var(--l-input-width);
}

.picker {
  & input {
    color: var(--color-text-body);
    /* Input container must account for left preview section */
    padding-left: calc(var(--spacing-5) + var(--spacing-6));
  }
}

.preview {
  background: var(--color-bg-tertiary);
  border-right: var(--border-decorative-secondary);

  & :global(.mantine-ColorInput-colorPreview) > span {
    --l-preview-size: 11px;

    border: var(--border-decorative-secondary);
    box-shadow: none;
    height: var(--l-preview-size);
    margin-left: var(--spacing-1);
    margin-top: var(--spacing-1);
    width: var(--l-preview-size);
  }
}

.icon {
  color: var(--color-slate-600);
}
