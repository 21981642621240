.container {
  margin-bottom: var(--spacing-2);

  & > span {
    margin-right: var(--spacing-3);
  }

  & > svg {
    margin-right: var(--spacing-3);
  }
}
