.root {
  background: var(--color-bg-secondary);
  border: 1px solid var(--color-slate-200);
  border-radius: var(--rounded);
  padding: var(--spacing-8);

  & .container {
    background: var(--color-bg-primary);
    border: 1px solid var(--color-slate-200);
    border-radius: var(--rounded-sm) var(--rounded-sm) 0 0;
    height: 350px;
    position: relative;
    width: 100%;

    & .error {
      align-items: center;
      display: flex;
      height: inherit;
      justify-content: center;
    }

    & .iframe {
      position: relative;
      z-index: 1;

      &.hidden {
        visibility: hidden;
      }
    }

    & .overlay {
      bottom: 0;
      cursor: not-allowed;
      left: 0;
      position: absolute;
      /* Leave 15px gap on the right for scrollbar interaction */
      right: 15px;
      top: 0;
      z-index: 2;
    }

    & .spinner {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 3;
    }
  }
}
