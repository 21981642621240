.tooltip {
  background: var(--color-tooltip-background);
  border-radius: var(--rounded-md);
  color: var(--color-tooltip-text);
  max-width: var(--tooltip-width);
  padding: var(--spacing-2);
  width: fit-content;
}

.triggerElement {
  /* Allow tooltip to respect its offset, preventing it from being rendered 
     right against the content that triggers it */
  display: inline-block;
  height: 100%;
}
