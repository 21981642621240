.root {
  width: 100%;
}

.frame {
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-lg);
}

.mobile {
  width: 375px;
}

.page {
  width: 100%;
}

.select {
  width: 240px;
}
