.input {
  width: 100%;
}

.button {
  margin-top: var(--spacing-4);
}

.cardWrapper {
  margin-top: var(--spacing-4);
}

.cardWrapper:first-child {
  margin-top: 0px;
}
