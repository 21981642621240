.root {
  height: 34px;
}

/* TODO: Just use a feather-only logo image instead! */
.small {
  clip-path: inset(0 10px 0 0);
  object-fit: cover;
  object-position: -14% 0;
  width: 34px;
}
