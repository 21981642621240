.root {
  display: flex;
  gap: var(--spacing-6);
  justify-content: space-between;
}

.label {
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data {
  display: flex;
  gap: var(--spacing-2);
}

.createGroupLabel {
  align-items: center;
  display: flex;
  gap: var(--spacing-1);
  margin-bottom: 0;
  padding: var(--spacing-2) 0 var(--spacing-2) 0;
}
