:root {
  color: var(--color-text-body);
  font-family: var(--font-body);
  font-size: var(--text-base);
}

.content {
  background-color: var(--color-bg-secondary);
  display: flex;
  flex-direction: column;
}

.header {
  background: var(--color-bg-primary);
  border-bottom: 1px solid var(--color-decorative-secondary);
  display: flex;
  flex-direction: row;
  justify-content: left;
  min-height: auto;
  padding: var(--spacing-6);
}

.title {
  color: var(--color-text-heading);
  font-weight: var(--font-semibold);
  line-height: var(--leading-base);
}

.description {
  font-size: var(--text-sm);
  font-style: normal;
  font-weight: var(--font-normal);
  line-height: var(--leading-sm);
}

.body {
  display: flex;
  /* flex-grow is needed to ensure actions is glued to the bottom */
  flex: 1 0 0;
  flex-direction: column;
}

.actionBar {
  bottom: 0;
  position: -webkit-sticky;
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  position: sticky;
}

.tooltip {
  /* This normally gets added to the close button by Mantine,
     but gets lost when we wrap it in a tooltip */
  margin-left: auto;
}

.themeDefault {
  overflow-y: scroll;
  padding: var(--spacing-6);
}

.themeFlush {
  padding: 0;
}

.themeCentered {
  align-items: center;
  justify-content: center;
}
