.root.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);

  & span {
    margin-bottom: 0;
    padding: 0;
  }
}

.link {
  text-decoration: none;
}
