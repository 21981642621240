.root {
  background-color: var(--color-white);
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  font-family: var(--font-google);
  padding: var(--spacing-3);
}

.header {
  align-items: center;
  display: flex;
  gap: var(--spacing-2);
  margin-bottom: var(--spacing-1);
}

.name {
  color: var(--color-google-text-heading);
  display: block;
}

.favicon {
  --l-favicon-background-size: 28px;

  align-items: center;
  background: var(--color-slate-200);
  border-radius: var(--rounded-full);
  display: flex;
  height: var(--l-favicon-background-size);
  justify-content: center;
  width: var(--l-favicon-background-size);

  & img {
    --l-favicon-size: 16px;

    height: var(--l-favicon-size);
    width: var(--l-favicon-size);
  }
}

.website {
  color: var(--color-google-text-body);
  display: block;
  font-size: var(--text-xs);
  line-height: var(--leading-xs);
}

.sponsored {
  color: var(--color-google-text-heading);
  display: block;
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
  line-height: var(--leading-sm);
  margin-bottom: var(--spacing-1);
}

.headline {
  /* Match the size of Google's headline line height */
  --l-google-line-height: 26px;

  color: var(--color-google-blue);
  display: block;
  font-size: var(--text-xl);
  font-weight: var(--font-normal);
  line-height: var(--l-google-line-height);
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.description {
  color: var(--color-google-text-body);
  font-size: var(--text-sm);
  line-height: var(--leading-sm);
  margin: var(--spacing-half) 0 0;
}

.button {
  /* Match the size of Google's call button */
  --l-button-size: 36px;

  align-items: center;
  border: 1px solid var(--color-google-decorative);
  border-radius: var(--rounded-3xl);
  color: var(--color-google-blue);
  display: inline-flex;
  gap: var(--spacing-2);
  height: var(--l-button-size);
  margin-top: var(--spacing-2);
}

.buttonIcon {
  /* Match the size of Google's call button icon */
  --l-icon-size: 18px;

  fill: var(--color-google-blue);
  height: var(--l-icon-size);
  line-height: var(--l-icon-size);
  margin-left: var(--spacing-3);
  width: var(--l-icon-size);
}

.buttonText {
  font-size: var(--text-sm);
  line-height: var(--leading-sm);
  margin-right: var(--spacing-4);
}
