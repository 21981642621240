.root {
  display: flex;
  margin-bottom: 0;
}

.large {
  & > *:not(:last-child) {
    margin-right: var(--spacing-3);
  }

  & > *:not(:first-child) {
    margin-left: var(--spacing-3);
  }
}

.medium {
  & > *:not(:last-child) {
    /* To align with legs on Flights listing page we use half the value used there. */
    margin-right: var(--spacing-1);
  }

  & > *:not(:first-child) {
    /* To align with legs on Flights listing page we use half the value used there. */
    margin-left: var(--spacing-1);
  }
}
