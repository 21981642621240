.labelWrapper {
  margin-top: var(--spacing-8);
  max-width: var(--content-max-width);
}

.preview {
  border: none;
  height: 450px;
  pointer-events: none;
  width: 100%;
}

.logoUrl {
  margin-left: var(--spacing-3);
  max-height: var(--thumbnail-size-2xs);
}

.faviconUrl {
  margin-left: var(--spacing-3);
  max-height: var(--thumbnail-size-2xs);
  max-width: var(--thumbnail-size-2xs);
}

.imagePicker {
  display: flex;
  flex-direction: row-reverse;
  gap: var(--spacing-3);
  justify-content: flex-end;
}
