.root {
  border: none;
  min-inline-size: auto;

  &:not(:last-child) {
    margin-bottom: var(--spacing-4);
  }

  & > .legend,
  & > legend {
    border-bottom: none;
    color: var(--color-text-heading);
    font-weight: var(--font-semibold);
    margin-bottom: var(--spacing-2);
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  & > :not(:last-child) {
    margin-bottom: var(--spacing-2);
  }
}

.column {
  display: flex;
  flex-wrap: wrap;

  & > * {
    flex: 1 1 auto;
  }

  & > .legend {
    flex: 1 0 100%;
  }

  & > div {
    margin-right: var(--spacing-6);
  }

  & > :last-child {
    margin-right: 0;
  }
}

.help {
  color: var(--color-text-body);
  display: block;
  font-size: var(--text-sm);
  margin-bottom: var(--spacing-2);
}

.error {
  color: var(--color-danger);
  composes: help;
}
