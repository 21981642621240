.container {
  --l-popover-max-width: 300px;
  --l-popover-top-padding: 80px;

  /* Allow popover to be viewport height minus some top padding */
  max-height: calc(100vh - var(--l-popover-top-padding));
  max-width: var(--l-popover-max-width);
  overflow-x: hidden;
  overflow-y: scroll;
}
