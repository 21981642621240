.triggerActivity {
  /* Additional specificity needed to override other styles. */
  &.triggerActivity > p {
    margin-bottom: var(--spacing-3);
    margin-top: 0;
  }

  & > :first-child {
    padding-top: 0;
  }

  & > span {
    padding-bottom: 0;
    padding-left: 0;
    word-break: break-all;
  }
}

.triggerList {
  margin-bottom: 0;
  margin-top: 0;
}
