.root {
  align-items: center;
  display: flex;
  gap: var(--spacing-4);
  margin-bottom: var(--spacing-2);

  & :first-child {
    flex: 1 0 auto;
  }

  & :not(:first-child) {
    flex: 0 0 auto;
  }

  & h3 {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.isOpen {
  margin-bottom: var(--spacing-4);
}

.isComplete {
  color: var(--color-green-600);
}

.actions {
  align-self: flex-start;
  display: flex;
  gap: var(--spacing-4);
  /* This is a one-off padding to align the contents with the height of the inline edit input */
  padding: 3px 0;
}
