.funnelStage {
  border-bottom: 0px solid transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  box-sizing: content-box;
  height: 0px;
  margin-bottom: var(--spacing-px);
  transition: border-top-color 0.3s;
  z-index: 1;
}

.funnelStage1 {
  border-top-color: var(--color-primary-3);
}

.funnelStage1:hover {
  border-top-color: var(--color-primary-4);
}

.funnelStage2 {
  border-top-color: var(--color-secondary-1);
}

.funnelStage2:hover {
  border-top-color: var(--color-secondary-2);
}

.funnelStage3 {
  border-top-color: var(--color-secondary-3);
}

.funnelStage3:hover {
  border-top-color: var(--color-secondary-4);
}
