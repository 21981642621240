.field {
  margin-bottom: var(--spacing-2);
}

.narrow {
  display: block;

  & > :first-child {
    max-width: unset;
  }
}

.navBarEventLogo {
  --l-logo-size: 34px;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--rounded);
  display: inline-block;
  height: var(--l-logo-size);
  line-height: var(--l-logo-size);
  margin-right: 5px;
  min-height: var(--l-logo-size);
  min-width: var(--l-logo-size);
  text-align: center;
  vertical-align: middle;
  width: var(--l-logo-size);
}

.navBarEvent {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.description {
  color: var(--slate-600);
  display: block;
  font-size: var(--text-xs);
  margin-top: var(--spacing-2);
}

.singleValue {
  display: flex;
}

.singleValueLabel {
  margin-right: 5px;
}

.singleValueData {
  display: flex;
  margin-bottom: 0;
}

.singleValueData > dt {
  margin-bottom: 0;
  margin-right: 5px;
}

.singleValueData > dd {
  margin-bottom: 0;
  margin-right: var(--spacing-2-half);
}

.radios {
  margin-top: 5px;
}

.saveButton {
  justify-content: flex-end;
}

.help {
  color: var(--slate-600);
  display: block;
  font-size: var(--text-xs);
  margin-bottom: var(--spacing-2);
  margin-top: var(--spacing-2);
}

.error {
  color: var(--color-danger);
  composes: help;
}

.warning {
  color: var(--color-warning);
  margin-right: var(--spacing-2);
}

.alert {
  margin-bottom: 0;
}
