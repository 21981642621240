@import '../style/skeleton.css';

.line {
  --l-line-padding: 0.125rem;

  composes: skeleton;
  display: inline-block;
  height: calc(var(--leading-normal) * var(--text-sm) - var(--l-line-padding) * 2);
  padding: var(--l-line-padding) 0;
  vertical-align: middle;
  width: 100%;
}

/* .line comes before .root so provided width works correctly. */
.root {
  display: inline-block;
  height: var(--l-height, calc(var(--leading-normal) * var(--text-sm) - var(--l-line-padding) * 2));
  max-width: 100%;
  overflow: hidden;
  position: relative;
  width: var(--l-width, 100%);
}

.hasPrefix {
  padding-left: calc(var(--avatar-size-lg) + var(--spacing-3));
}

.hasSuffix {
  padding-right: calc(var(--avatar-size-lg) + var(--spacing-3));
}

.paragraph {
  composes: line;

  &:not(:first-child):last-child {
    max-width: 75%;
  }
}

.singleLine {
  composes: line;
  height: var(--l-height, calc(var(--leading-normal) * var(--text-sm) - var(--l-line-padding) * 2));
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.prefix {
  border-radius: var(--rounded-full);
  composes: skeleton;
  grid-area: prefix;
  height: var(--avatar-size-lg);
  left: 0;
  position: absolute;
  top: 0;
  width: var(--avatar-size-lg);
}

.suffix {
  border-radius: var(--rounded-full);
  composes: skeleton;
  grid-area: suffix;
  height: var(--avatar-size-lg);
  position: absolute;
  right: 0;
  top: 0;
  width: var(--avatar-size-lg);
}

.title {
  composes: line;
  height: 1.25rem; /* 23px */
  max-width: 100%;
  min-width: 140px;
  width: calc(var(--l-width, 100%) * 0.75);
}
