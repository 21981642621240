.address {
  border-top: 1px solid var(--color-decorative-secondary);
  margin-top: var(--spacing-6);
  padding-top: var(--spacing-4);
}

.addressGroup {
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  margin-top: var(--spacing-6);
  padding: var(--spacing-2);
}

.alert {
  margin-top: var(--spacing-2);
}

.checkbox {
  margin-bottom: var(--spacing-2);
}
