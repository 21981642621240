.chips {
  display: flex;
  flex-direction: column;
}

.leadsChip {
  margin-bottom: var(--spacing-2);
}

.logo {
  background-position: center;
  background-size: cover;
  border-radius: var(--rounded);
  display: block;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 40px;
}

.statusText {
  border-radius: var(--rounded);
  color: var(--color-text-body-inverted);
  font-weight: var(--font-semibold);
  padding: 5px;
  text-align: center;
}

.tag {
  /* Should refactor lines 31-44 styles into reusable component or use TagsField */
  background-color: var(--color-slate-200);
  border: 1px solid var(--color-slate-500);
  border-radius: var(--rounded-md);
  color: var(--color-slate-600);
  font-size: var(--text-xs);
  font-weight: var(--font-medium);
  line-height: var(--text-sm);
  margin: var(--spacing-half);
  /* Calculation needed here to subtract border from padding */
  padding: calc(var(--spacing-half) - var(--spacing-px))
    calc(var(--spacing-1-half) - var(--spacing-px));
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}
