.card {
  margin-top: var(--spacing-6);
  width: var(--content-max-width);

  &:first-of-type {
    margin-top: 0;
  }

  & .description {
    margin-bottom: var(--spacing-4);
  }
}

.delay {
  border-right: none;
  /* 1.5rem x 4 = 6rem. This is the minimum width of the delay input field to fit the largest number allowed */
  min-width: calc(var(--spacing-6) * 4);
}

.delayWrapper {
  /* Sets the width of the input field to 306px so it doesn't span the whole width of the parent component */
  width: calc(var(--form-filter-width) * 1.53);

  & > div > input {
    width: var(--spacing-3);
  }

  & > div > span {
    flex: 0 0 auto;
  }
}
