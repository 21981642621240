.header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-1);
  max-width: var(--content-max-width);
  padding-bottom: var(--spacing-4);

  & > button,
  & > a {
    margin-left: var(--spacing-1);
  }

  & .mode {
    margin-right: var(--spacing-1);
    width: 100px;
  }
}

.actions {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: var(--spacing-12);
  padding: var(--spacing-4);
}

.triggerButton {
  width: fit-content;
}
