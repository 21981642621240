.link {
  align-items: center;
  color: var(--color-action);
  cursor: pointer;
  display: flex;

  &:hover {
    color: var(--color-action-hover);
  }
}

.chipWrapper {
  & > * {
    margin-right: var(--spacing-1);
  }
}
