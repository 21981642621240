.titleWithDetails {
  margin-top: var(--spacing-4);
}

.title {
  margin-top: 0;
}

.label {
  padding-left: var(--spacing-1);
}

.carousel {
  padding: 0 var(--spacing-6) 0 var(--spacing-3);
}
