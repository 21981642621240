.person {
  align-items: center;
  display: flex;

  /* Person name and id */
  & :nth-child(2) {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    & a {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    /* Person id */
    & :nth-child(2) {
      display: block;
      font-size: var(--text-xs);
      line-height: var(--leading-xs);
      text-overflow: ellipsis;
    }
  }
}
