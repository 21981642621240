/* stylelint-disable no-descending-specificity */
.root {
  & > .headerWithSuffix {
    display: flex;

    & > :first-child {
      flex: 1 1 auto;
    }

    /* Suffix / Expander */
    & > :last-child {
      cursor: pointer;
      display: inline-flex;
      flex: 0 0 auto;
      padding: var(--spacing-2) var(--spacing-2-half);
    }
  }

  & > a,
  & > .headerWithSuffix > a,
  & > button,
  & > .headerWithSuffix > button {
    color: var(--color-text-body);
  }

  & > a,
  & > .headerWithSuffix > a {
    display: block;
    text-decoration: none;
  }

  & > button,
  & > .headerWithSuffix > button {
    background: none;
    border: none;
    cursor: pointer;
    font: inherit;

    &:hover,
    &:focus {
      color: inherit;
    }
  }

  & > :first-child {
    border-radius: var(--rounded-md);

    &:hover {
      background-color: var(--color-bg-tertiary);
      color: var(--color-slate-900);
    }

    &:global(.active),
    &:active {
      background-color: var(--color-sky-50);
    }

    &:global(.active),
    &:global(.active) > a,
    &:active,
    &:active > a {
      color: var(--color-action);
    }
  }
}
