.icon {
  margin-right: var(--spacing-1);
  size: 1.5em;
}

.campaigns {
  margin-top: var(--spacing-1);
}

.ready {
  color: var(--color-success);
}

.failed {
  color: var(--color-danger);
}

.subdued {
  color: var(--slate-600);
  margin-left: var(--spacing-1);
}

.button {
  float: right;
  max-width: var(--content-max-width);
  position: relative;
}

.card {
  margin-top: var(--spacing-3);
  max-width: var(--content-max-width);
}

.partners {
  margin-bottom: var(--spacing-2);
}

.meta {
  display: flex;
  justify-content: space-between;
}
