.controls {
  align-self: flex-end;
  flex: 0 0 auto;
  padding: 0 var(--spacing-4);
  width: var(--button-with-icon-size);

  & button {
    margin-bottom: var(--spacing-1);
    width: var(--button-with-icon-size);

    &:nth-child(2) {
      margin-bottom: var(--spacing-3);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & :global(.svg-inline--fa) {
    font-size: var(--text-xs);
  }
}

.listbox {
  flex: 0 0 auto !important;
  width: calc(50% - (var(--button-with-icon-size) / 2 + var(--spacing-4)));

  & select {
    height: calc(
      var(--button-with-icon-size) * 4 + var(--spacing-1) * 2 + var(--spacing-3)
    );
  }
}
