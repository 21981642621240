/* nth-child: let the help text take full width */
.select > div:not(:nth-child(2)) {
  max-width: 100%;
  width: 300px;
}

.numberInputWrapper {
  --l-input-width: 100px;

  max-width: var(--l-input-width);
}
