.root {
  display: grid;
  gap: var(--spacing-6);
  grid-template-areas: 'left right';
  grid-template-columns: 1fr 1fr;
}

.phoneWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);

  & .phoneCountryCode {
    /* Set width to 90px to match the width necessary for it to display correctly */
    --l-width: 90px;

    width: var(--l-width);
  }

  & input {
    border-bottom-left-radius: var(--rounded);
    border-top-left-radius: var(--rounded);
  }

  & .phonePrefix {
    background: var(--color-bg-primary);
    border: none;
    padding-left: 0;
  }

  & :global(.react-select__control) {
    border-bottom-left-radius: var(--rounded);
    border-top-left-radius: var(--rounded);
  }
}

.left {
  grid-area: left;
}

.right {
  grid-area: right;
}

.adPreviewDescription {
  margin-top: var(--spacing-4);
}
