.details {
  margin-right: 0;

  & > button {
    font-size: var(--text-xs);
  }
}

.emailStatusContainer {
  display: flex;
}

.refresh {
  font-size: var(--text-xs);
  margin-left: var(--spacing-2);
  margin-top: var(--spacing-1);
}

.statusText > span {
  padding-left: 0;
}
