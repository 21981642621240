.btnGroupRight {
  border-radius: var(--rounded-none) var(--rounded) var(--rounded)
    var(--rounded-none);
  display: inline-block;
  padding: 0 var(--spacing-1-half);
}

.btnGroupLeft {
  border-radius: var(--rounded) var(--rounded-none) var(--rounded-none)
    var(--rounded);
  border-right: 0;
  display: inline-block;
}
