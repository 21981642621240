.delay {
  border-right: none;
}

.delaySuffix {
  border: none;
  padding: 0;

  & :global(.react-select__value-container) {
    padding: 0 var(--spacing-2);
    width: 76px;

    & > :last-child {
      margin: 0;
      padding: 0 var(--spacing-2);
    }
  }
}

.delayWrapper {
  width: 306px;

  & > div > input {
    width: 10.5ex;
  }

  & > div > span {
    flex: 0 0 auto;
  }
}

.sendToRest {
  margin-top: var(--spacing-6);

  & > legend {
    font-size: var(--text-xs);
    font-weight: var(--font-bold);
  }
}
