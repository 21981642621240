.container {
  align-items: center;
  display: flex;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
}
