.card {
  /* Minimum width to prevent truncating table cell values */
  width: calc(var(--content-max-width) * 1.75);
}

.downloadButton {
  float: right;
}

.fieldset {
  /* Need to override root styles to close wide spacing on the bottom of the fieldset. */
  margin-bottom: 0 !important;

  & > div:first-child {
    max-width: var(--content-max-width);
  }
}
