.collapse {
  & :global(.ReactCollapse--content) {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-3);
  }
}

.content {
  width: 100%;
}
