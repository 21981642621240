.number {
  text-align: left;
}

:global(.react-datepicker-wrapper) {
  width: 100%;
}

:global(.react-datepicker__input-container.react-datepicker__input-container) {
  width: 100%;

  & input {
    width: 100%;
  }
}
