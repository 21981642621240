.root {
  display: grid;
  gap: var(--spacing-6);
  grid-template-areas: 'left right';
  grid-template-columns: 1fr 1fr;
  margin-top: var(--spacing-6);
}

.left {
  grid-area: left;
}

.right {
  grid-area: right;
}

.chipFix.chipFix > span {
  max-width: none;
}
