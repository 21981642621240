.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);

  & > div {
    flex-direction: column;

    &.center {
      align-items: center;
    }

    &.left {
      align-items: flex-start;
    }
  }

  /* .element in FormElement.css has a rule that sets flex-grow to 1 for any child item. Until, that's removed, this rule un-sets it. */
  & > *:not(.prefix):not(.suffix) {
    flex-grow: unset;
  }
}

.image {
  max-height: 150px;
  max-width: 100%;
}
