.content {
  & :global(.formDescription) {
    margin-bottom: var(--spacing-4);
    margin-top: var(--spacing-2);
  }

  & > :first-child:not(:global(.formDescription)) {
    margin-bottom: var(--spacing-4);
  }
}
