.root {
  width: 100%;
}

.title {
  color: var(--color-text-heading);
  font-size: var(--text-base);
  font-weight: var(--font-medium);
  line-height: var(--leading-base);
  margin: 0;
}

.description {
  margin-bottom: var(--spacing-4);
  max-width: var(--content-max-width-v2);
}
