.root {
  align-items: center;
  display: flex;
  padding-left: 0;
  padding-right: 0;

  & :not(:global(.icon)):global(.svg-inline--fa) {
    /* Prevent jumping when switching icons */
    width: 0.625em;
  }
}
