.cardContent {
  & > h3 {
    margin-bottom: var(--spacing-2);
  }
}

.field {
  margin-bottom: var(--spacing-3);
}

.form {
  max-width: var(--breakpoint-lg);
}
