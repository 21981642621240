.root {
  align-items: flex-start;
  background-color: var(--color-white);
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded-xl);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  padding: var(--spacing-6);
}

.isRecommended {
  border: 2px solid var(--color-sky-700);
}

/* Add margin to consecutive cards */
.root + .root {
  margin-top: var(--spacing-6);
}

.budgetContainer {
  align-items: center;
  display: flex;
  gap: var(--spacing-3);
}

.budget {
  color: var(--color-text-heading);
  font-size: var(--text-5xl);
  font-weight: var(--font-bold);
  line-height: var(--leading-none);
}

.budgetDescription {
  display: block;
  font-size: var(--text-xs);
  line-height: var(--leading-sm);
}

.description {
  font-size: var(--text-sm);
  line-height: var(--leading-sm);
  margin: 0;
}

/* TODO: This style should be removed when the chip truncation is fixed */
.chipFix > span {
  max-width: none !important;
}
