.root {
  & :global(.ReactCollapse--collapse) {
    transition: height 500ms;
  }

  & :global(.ReactCollapse--content) {
    max-height: 300px;
    overflow: 'scroll';
    overflow-x: hidden;
  }
}

.defaultImage {
  background-color: var(--l-background-color, var(--color-bg-primary));
  border-radius: var(--rounded-lg);
  box-shadow: var(--drop-shadow);
  display: flex;
  flex-direction: column;
  margin: var(--spacing-1) var(--spacing-2);
  max-width: 795px;
  position: relative;

  &:hover {
    box-shadow: var(--drop-shadow-md);
    cursor: pointer;
  }
}

.flightImage {
  background-color: var(--l-background-color, var(--color-bg-primary));
  border-radius: var(--rounded-lg);
  box-shadow: var(--drop-shadow);
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-2);
  max-width: 830px;
  position: relative;
}
