.danger {
  color: var(--color-danger);
}

.warning {
  color: var(--color-warning);
}

.success {
  color: var(--color-success);
}

.disabled {
  color: var(--color-disabled);
}

.normal {
  color: var(--color-text-body);
}
