.input {
  /* Width that will accommodate the full placeholder text */
  width: 320px;
}

.refresh {
  align-items: center;
  display: flex;

  & > span {
    margin-left: var(--spacing-4);
  }
}

.refreshButton {
  margin-right: var(--spacing-2);
}
