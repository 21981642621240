.root {
  /* Anonymous user icon-- blank user on a gray background */
  --l-anon-icon: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImNpcmNsZS11c2VyIj4KPHBhdGggaWQ9InZlY3RvciIgZD0iTTM3LjQwNjIgMzYuMDE4OEMzNS4zMzQ0IDMyLjQxODggMzEuNDQzOCAzMCAyNyAzMEgyMUMxNi41NTYyIDMwIDEyLjY2NTYgMzIuNDE4OCAxMC41OTM4IDM2LjAxODhDMTMuODkzOCAzOS42OTM4IDE4LjY3NSA0MiAyNCA0MkMyOS4zMjUgNDIgMzQuMTA2MyAzOS42ODQ0IDM3LjQwNjIgMzYuMDE4OFpNMCAyNEMwIDE3LjYzNDggMi41Mjg1NiAxMS41MzAzIDcuMDI5NDQgNy4wMjk0NEMxMS41MzAzIDIuNTI4NTYgMTcuNjM0OCAwIDI0IDBDMzAuMzY1MiAwIDM2LjQ2OTcgMi41Mjg1NiA0MC45NzA2IDcuMDI5NDRDNDUuNDcxNCAxMS41MzAzIDQ4IDE3LjYzNDggNDggMjRDNDggMzAuMzY1MiA0NS40NzE0IDM2LjQ2OTcgNDAuOTcwNiA0MC45NzA2QzM2LjQ2OTcgNDUuNDcxNCAzMC4zNjUyIDQ4IDI0IDQ4QzE3LjYzNDggNDggMTEuNTMwMyA0NS40NzE0IDcuMDI5NDQgNDAuOTcwNkMyLjUyODU2IDM2LjQ2OTcgMCAzMC4zNjUyIDAgMjRaTTI0IDI1LjVDMjUuNzkwMiAyNS41IDI3LjUwNzEgMjQuNzg4OCAyOC43NzMgMjMuNTIzQzMwLjAzODggMjIuMjU3MSAzMC43NSAyMC41NDAyIDMwLjc1IDE4Ljc1QzMwLjc1IDE2Ljk1OTggMzAuMDM4OCAxNS4yNDI5IDI4Ljc3MyAxMy45NzdDMjcuNTA3MSAxMi43MTEyIDI1Ljc5MDIgMTIgMjQgMTJDMjIuMjA5OCAxMiAyMC40OTI5IDEyLjcxMTIgMTkuMjI3IDEzLjk3N0MxNy45NjEyIDE1LjI0MjkgMTcuMjUgMTYuOTU5OCAxNy4yNSAxOC43NUMxNy4yNSAyMC41NDAyIDE3Ljk2MTIgMjIuMjU3MSAxOS4yMjcgMjMuNTIzQzIwLjQ5MjkgMjQuNzg4OCAyMi4yMDk4IDI1LjUgMjQgMjUuNVoiIGZpbGw9IiM2NDc0OEIiLz4KPC9nPgo8L3N2Zz4K);

  /* --l-bg-color is set in AvatarV2.tsx */
  background-color: var(--l-bg-color);
  border-radius: var(--rounded-full);
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-weight: var(--font-semibold);
  justify-content: center;
  text-align: center;

  &.anonymous {
    background-image: var(--l-anon-icon);
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.xs {
    font-size: var(--text-xs);
    height: var(--avatar-size-5);
    width: var(--avatar-size-5);
  }

  &.small {
    font-size: var(--text-xs);
    height: var(--avatar-size-6);
    width: var(--avatar-size-6);
  }

  &.medium {
    font-size: var(--text-sm);
    height: var(--avatar-size-8);
    width: var(--avatar-size-8);
  }

  &.large {
    font-size: var(--text-base);
    height: var(--avatar-size-12);
    width: var(--avatar-size-12);
  }
}

.person {
  margin-right: var(--spacing-1-half);
}
