.root {
  align-items: center;
  border-style: dashed;
  gap: var(--spacing-3);
  padding: var(--spacing-12) var(--spacing-4);
  text-align: center;
}

.label {
  font-weight: var(--font-semibold);
}
