/**
 * Hiding DOM elements.
 *
 * Use the `hidden` attribute to completely hide an element.
 * Use the `aria-hidden` attribute to hide an element from the accessibility
 *   tree.
 * Use the `.visuallyhidden` class to hide an element from the screen.
 * Use `visibility: inherit;` instead of `visibility: visible;` to avoid
 *   accidentally showing content.
 * Do not attach any CSS styles to the `aria-hidden` attribute.
 * Take care of keyboard focusable content that is partially hidden by
 *   adding `tabindex="-1"`.
 *
 * @see https://allyjs.io/tutorials/hiding-elements.html
 */

/**
 * Hide elements from all users using the HTML5 `hidden` attribute.
 *
 * Used for elements which should not be displayed to any user.
 *
 * @see https://allyjs.io/tutorials/hiding-elements.html#the-html5-hidden-attribute
 */
[hidden] {
  display: none;
}

/**
 * Hide elements visually, but keep them available for screen readers.
 *
 * Used for information required for screen reader users to understand and use
 * the site where visual display is undesirable. Information provided in this
 * manner should be kept concise, to avoid unnecessary burden on the user.
 * "!important" is used to prevent unintentional overrides.
 *
 * To make sure sighted keyboard users do not end up focusing elements they
 * can't see, and screen reader users not focusing element's that don't exist
 * for them, we need to make sure that partially hidden content is not
 * accessible to keyboard navigation using the Tab and Shift Tab keys. To
 * accomplish this, we can add tabindex="-1" to the elements we want to hide
 * from the keyboard.
 *
 * @see https://allyjs.io/tutorials/hiding-elements.html#2017-edition-of-visuallyhidden
 */
:global .visuallyhidden:not(:focus):not(:active) {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}
