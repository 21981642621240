.container {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.select {
  width: 20%;
}
