.root {
  min-height: 250px;
  min-width: unset;
  text-align: center;
  width: 260px;
}

.icon {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;

  & img {
    border-radius: var(--rounded);
    flex-shrink: 0;
    max-height: 250px;
    max-width: 90%;
  }
}

.subtext {
  color: var(--color-slate-400);
}

.tags {
  /* Prevent extra vertical space */
  font-size: 0;
  margin-bottom: calc(var(--spacing-2) * -1);
  margin-top: var(--spacing-1);
  min-height: 26px;

  & > * + * {
    margin-left: var(--spacing-1);
  }
}
