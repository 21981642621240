.serviceCardContent {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-2);
  justify-content: space-between;
}

.serviceValue {
  display: inline-block;
  flex: 1 0 0;

  & span {
    padding-left: 0;
  }
}
