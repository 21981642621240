:root {
  --badge-count-size: calc(var(--leading-normal) * var(--text-sm));
  --badge-dot-size: 8px;
  --badge-dot-size-lg: 12px;
}

.root {
  display: inline-block;
  font-feature-settings: 'tnum';
  font-size: var(--text-sm);
  font-variant: tabular-nums;
  line-height: var(--leading-none);
  margin: 0;
  padding: 0;
  position: relative;

  & > * {
    line-height: var(--leading-normal);
  }
}

.tooltip {
  cursor: help;
}

.count,
.custom,
.dot {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}

.count,
.dot {
  background-color: var(--color-badge);
  border-radius: calc(var(--badge-count-size) / 2);
  box-shadow: 0 0 0 1px var(--color-text-body-inverted);
}

.count {
  color: var(--color-text-body-inverted);
  font-size: var(--text-xs);
  font-weight: normal;
  height: var(--badge-count-size);
  /* For some reason our font is taller than expected at small sizes, so adjust */
  line-height: calc(var(--badge-count-size) + 0.167em);
  min-width: var(--badge-count-size);
  padding: 0 var(--badge-dot-size);
  text-align: center;
  white-space: nowrap;
}

.dot {
  height: var(--badge-dot-size);
  width: var(--badge-dot-size);

  &.large {
    height: var(--badge-dot-size-lg);
    width: var(--badge-dot-size-lg);
  }
}

.read {
  background-color: var(--color-slate-400);
  composes: dot;
}

.unread {
  composes: dot;
}

.notWrapper {
  line-height: inherit;
  vertical-align: middle;

  &:not(:last-child) {
    margin-right: var(--spacing-2);
  }

  & .count,
  & .custom,
  & .dot {
    display: inline-block;
    position: relative;
    transform: none;
  }

  & .dot {
    vertical-align: middle;

    &.large {
      line-height: var(--badge-dot-size-lg);
    }
  }
}
