.root {
  margin: 0 auto;
  max-width: var(--content-max-width);
}

.infinite {
  /* Important is necessary to override an inline style added by the library  */
  /* Without it, the right side of breadcrumbs gets cut off upon animating  */
  overflow: initial !important;
}
