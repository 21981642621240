.root {
  border-bottom: 1px solid var(--color-decorative-secondary);
  display: flex;
  /* Adds padding of ~40px to top of Section */
  padding-bottom: calc(var(--spacing-4) * 2.5);
  /* Adds padding of ~16px to top of Section */
  padding-top: var(--spacing-4);

  &:last-child {
    border: none;
  }
}

.header {
  flex: 1;
}

.content {
  flex: 3;
  /* Adds ~100px as left margin to add space between description and content when layout is horizontal */
  margin-left: calc(var(--spacing-4) * 6.25);
}

.description {
  display: block;
  max-width: var(--content-extra-width);
}

.titleBar {
  align-items: center;
  display: flex;
  justify-content: space-between;

  & .titleWrapper {
    display: flex;
    flex-direction: column;
  }

  & .title {
    margin: 0;

    & input {
      font-size: var(--text-3xl);
      font-weight: var(--font-bold);
      line-height: var(--leading-3xl);
    }
  }

  & .description {
    margin-top: var(--spacing-2);
  }

  & .actions {
    display: flex;
    gap: var(--spacing-3);

    & > * {
      margin: 0;
    }
  }
}

.vertical {
  flex-direction: column;

  & .content {
    margin-left: 0;
    margin-top: var(--spacing-3);
  }
}

.group {
  max-width: var(--breakpoint-xl);
  padding: 0;
  width: 100%;

  & > :first-child {
    padding-top: var(--spacing-4);
  }
}
