.root {
  &form,
  &[role='form'] {
    max-width: none;

    & > fieldset {
      max-width: var(--content-max-width);
    }
  }
}

.radios {
  margin-top: var(--spacing-1);
}

.toolbar {
  margin-top: var(--spacing-4);
}

.upload {
  margin-bottom: var(--spacing-3);
}
