.card {
  --l-input-width: 160px;
  --l-select-width: 256px;

  margin-top: var(--spacing-3);
  max-width: unset;

  & .input {
    width: var(--l-input-width);

    /* Since we are not treating this like a normal input, we override the default styles to match the design. */
    &:disabled {
      color: var(--color-slate-500);
      cursor: default;
    }
  }

  & .select {
    width: var(--l-select-width);

    & :global(.react-select__single-value--is-disabled) {
      color: var(--color-slate-500);
    }
  }
}

/* Overrides the default spacing between cards to match design. */
.card + .card {
  margin-top: var(--spacing-3);
}

.content {
  display: flex;
  justify-content: space-between;
  max-width: unset;

  & .actions {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: var(--spacing-2);
    justify-content: flex-start;
  }

  & .controls {
    align-items: center;
    display: flex;

    & .sync {
      font-size: var(--text-xs);
    }

    & button {
      margin-left: var(--spacing-3);
    }
  }
}
