.root {
  background-color: var(--color-slate-200);
  max-width: 100% !important;
  overflow: hidden;
  text-align: center;
  width: 160px !important;

  & > div:first-child {
    background-color: var(--color-slate-700);

    & > div {
      height: 100% !important;
      width: 100% !important;

      & > img {
        height: 100%;
        object-fit: contain;
        width: 100%;
      }
    }
  }

  & h3 {
    font-size: var(--text-sm);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
