.description {
  max-width: var(--content-max-width-v2);
}

.tabs {
  margin-bottom: var(--spacing-8);
  margin-top: var(--spacing-2);
}

.header {
  color: var(--color-text-heading);
  display: grid;
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
  grid-template-columns: var(--primary-header-width) var(--secondary-header-width) 1fr;
  margin: var(--spacing-5);

  & .primaryHeader {
    width: var(--primary-header-width);
  }

  & .actionsHeader {
    justify-self: end;
  }
}
