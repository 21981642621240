.icon.icon {
  align-content: center;
  color: var(--color-text-body);
  height: var(--icon-size);
  width: var(--icon-size);

  & svg {
    font-size: var(--text-sm);
  }
}

.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);

  /* Override Form giving extra margin bottom to last Form element. */
  & div:last-of-type {
    margin-bottom: 0;
  }
}
