.content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: var(--spacing-4);
}

.licenseElement {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
}
