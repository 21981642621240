.descriptionElements {
  display: flex;
  gap: var(--spacing-6);

  & span {
    align-items: center;
    display: flex;
    gap: var(--spacing-1-half);
  }
}

/* Only add margin top to chip if it follows description elements */
.descriptionElements + .chip {
  margin-top: var(--spacing-4);
}

/* Prevent PersonAvatarV2 default margin from messing up the spacing in the header */
.avatar {
  margin-right: 0;
}

@media (max-width: 880px) {
  .descriptionElements {
    flex-direction: column;
    gap: var(--spacing-1);
  }
}
