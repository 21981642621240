.root {
  min-width: 206px;
}

.chipFix {
  align-self: flex-start;

  & > span {
    max-width: none !important;
  }
}

.bigNumber {
  color: var(--color-text-heading);
  font-size: var(--text-2xl);
  font-weight: var(--font-semibold);
  line-height: var(--leading-2xl);
}
